<template>
  <div :class="{
      'box box--header': true,
      'box--header-report': type === 'report',
      'box--header-blog': type === 'blog'
    }">
    <div class="box__inner">
      <div class="header">
        <div class="header__left">
          <router-link class="header__logo" :to="clickOnLogo">
            <img src="@/assets/img/logo.png" alt="logo"/>
          </router-link>
          <div class="header__categories" v-if="type === 'blog'">
            <a v-for="category of categories" :key="category.id" @click="goToCategory(category.attributes.name)" class="header__category">{{ category.attributes.name }}</a>
          </div>
<!--          <div v-if="search !== false" class="header__search">-->
<!--            <SearchForm/>-->
<!--          </div>-->
        </div>

        <div class="header__center">
          <div
          :class="{
            'header__title': true,
            'header__title-blog': type === 'blog'
          }"
          @click="$router.push(type === 'blog' ? '/theflyreport' : '/dashboard')">{{title}}</div>
        </div>

        <div class="header__right">
          <div class="header__theme-toggle" v-if="type === 'blog'">
            <Toggle />
          </div>

          <div class="header__mobile-menu open" @click="toggleBlogMobileMenu" v-if="type === 'blog' && !blogMobileMenuOpen">
            <svg width="31" height="17" viewBox="0 0 31 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="0.5" x2="31" y2="0.5" stroke="white"/>
              <line y1="8.5" x2="20" y2="8.5" stroke="white"/>
              <line y1="16.5" x2="31" y2="16.5" stroke="white"/>
            </svg>
          </div>
          <div class="header__mobile-menu close" @click="toggleBlogMobileMenu" v-if="type === 'blog' && blogMobileMenuOpen">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.8761 17.2418H17.8778L9.59143 9.00055L17.8343 0.712135C17.997 0.548661 17.9964 0.284325 17.8329 0.121686C17.6694 -0.040954 17.4051 -0.0403279 17.2424 0.123147L9.00119 8.41031L0.7136 0.167394C0.550126 0.00475414 0.28579 0.00538029 0.123151 0.168855C-0.0394892 0.332329 -0.0388631 0.596665 0.124612 0.759304L8.41094 9.00055L0.168859 17.2881C0.00621899 17.4516 0.00684513 17.7159 0.17032 17.8786C0.333794 18.0412 0.59813 18.0406 0.76077 17.8771L9.00119 9.59079L17.2871 17.8337C17.4506 17.9963 17.7149 17.9957 17.8776 17.8322C18.0402 17.6688 18.0395 17.4044 17.8761 17.2418Z" fill="white"/>
            </svg>
          </div>

          <div v-if="$store.state.auth.login" class="header__notify">
            <div
              class="header__notify-item js-notify"
              :class="[{'open' : notify}, {'empty' : notyfyList}]">
              <div class="header__notify-icon js-notify-icon" @click="openNotify">
                <SvgIcon name="notify"></SvgIcon>
                <!-- <span class="header__notify-count js-notify-count">6</span> -->
              </div>

              <div class="header__notify-menu js-notify-menu" @mouseleave="closeMenu">
                <div class="header__notify-menu-list">
                  <vue-scroll :ops="ops">
                    <!-- <div class="header__notify-menu-list-item">New aircraft registered successfully!</div>
                    <div class="header__notify-menu-list-item">Another notificationTextFromPage example</div>
                    <div class="header__notify-menu-list-item">Short notificationTextFromPage</div>
                    <div class="header__notify-menu-list-item">New aircraft registered successfully!</div>
                    <div class="header__notify-menu-list-item">Another notificationTextFromPage example</div>
                    <div class="header__notify-menu-list-item">Short notificationTextFromPage</div> -->
                  </vue-scroll>
                </div>
                <div class="header__notify-menu-footer">
                  <div class="header__notify-menu-remove js-notify-remove">
                    <span class="icon">
                      <SvgIcon name="rubbish-bin"></SvgIcon>
                    </span>
                    <span class="text" @click="() => {
                    closeMenu();
                    this.notyfyList = true;
                    }">Сlear all</span>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="header__notify-item js-mail" :class="[{'open' : mail}, {'empty' : mailList}]">
              <div class="header__notify-icon js-mail-icon" @click="openMail">
                <SvgIcon name="mail"></SvgIcon>
                <!-- <span class="header__notify-count">2</span> -->
              </div>
              <div class="header__notify-menu js-mail-menu" @mouseleave="closeMenu">
                <div class="header__notify-menu-list">
                  <vue-scroll :ops="ops">
                    <!-- <div class="header__notify-menu-list-item">
                      <div class="header__notify-menu-list-item-header"><span
                        class="header__notify-menu-list-item-name">Amanda Shtern</span><span
                        class="header__notify-menu-list-item-date">yesterday, 8:00 AM</span></div>
                      <div class="header__notify-menu-list-item-content">Text message...</div>
                    </div>
                    <div class="header__notify-menu-list-item">
                      <div class="header__notify-menu-list-item-header">
                        <span class="header__notify-menu-list-item-name">John Rem</span>
                        <span class="header__notify-menu-list-item-date">today, 4:00 PM</span>
                      </div>
                      <div class="header__notify-menu-list-item-content">This demo text. Mei eu probo causae
                        oblique...
                      </div>
                    </div> -->
                  </vue-scroll>
                </div>
                <div class="header__notify-menu-footer">
                  <router-link class="header__notify-menu-link" to="messagesFlyerDashboard">See all messages
                  </router-link>
                  <div class="header__notify-menu-remove js-mail-remove">
                  <span class="icon">
                    <SvgIcon name="rubbish-bin"></SvgIcon>
                  </span>
                    <span class="text" @click="() => {
                  closeMenu();
                  this.mailList = true;
                  }">Сlear all</span></div>
                </div>
              </div>
            </div>

            <!-- <div class="header__notify-item">
              <div class="header__notify-icon" @click="$modal.show('OptInToFlyGreen')">
                <SvgIcon name="flyGreenIcon"></SvgIcon>
              </div>
            </div> -->
          </div>

          <div v-if="$store.state.auth.login" class="header__login js-login" :class="{'open' : mobileHeaderMenuOpen}" @mouseleave="() => {
            this.$root.animations.slideUp(this.$refs.menuList, 190)
          }">
<!--            <div v-if="data" class="header__login-avatar" :style="{backgroundImage: `url(${avatar})`}"></div>-->

            <div class="header__login-avatar">
              <SvgIcon name="user-empty"></SvgIcon>
              <span class="header__login-avatar-flyGreen">
                <svg width="57" height="57" viewBox="0 0 57 57" fill="none">
                  <circle cx="28.5" cy="28.5" r="27" stroke="url(#paint0_linear)" stroke-width="3"></circle>
                  <linearGradient id="paint0_linear" x1="28.5" y1="3" x2="28.5" y2="54"
                                  gradient-units="userSpaceOnUse">
                    <stop stop-color="#41FB96"></stop>
                    <stop offset="1" stop-color="#12A053"></stop>
                  </linearGradient>
                </svg>
              </span>
            </div>

            <div style="display: block">
              <div class="header__login-caption js-login-caption" @click="showMobileMenu">
                <div class="header__login-username">
                  {{$store.state.auth.user.firstName}} {{$store.state.auth.user.lastName}} {{$store.getters.getNameActiveSubscriptionPlanName ? '+' : ''}}
                </div>
                <div class="header__login-icon">
                  <SvgIcon name="down-arrow"/>
                </div>
              </div>
            </div>

            <div class="header__login-menu js-login-menu">
              <div class="header__login-menu-inner">
                <div class="header__login-menu-header js-login-menu-header" @click="toggleHtml">
                  <span class="text">Dashboard</span>
                  <span class="icon">
                    <SvgIcon name="down-arrow"/>
                  </span>
                </div>

                <div
                  class="header__login-menu-list js-login-menu-list"
                  ref="menuList"
                  v-if="accountType === 1"
                >
                  <vue-scroll :ops="ops">
                    <router-link
                      v-for="link of headerLinks.flyer"
                      class="header__login-menu-list-item"
                      :to="link.link"
                      :key="link.title"
                    >
                    <span class="icon">
                      <SvgIcon :name="link.icon"/>
                    </span>
                      <span class="text">{{link.title}}<b>{{link.subtitle ? link.subtitle : ''}}</b></span>
                    </router-link>
                  </vue-scroll>
                </div>
                <div
                  class="header__login-menu-list js-login-menu-list"
                  ref="menuList"
                  v-else-if="accountType === 2"
                >
                  <vue-scroll :ops="ops">
                    <router-link
                      v-for="link of headerLinks.provider"
                      class="header__login-menu-list-item"
                      :to="link.link"
                      :key="link.title"
                    >
                    <span class="icon">
                      <SvgIcon :name="link.icon"/>
                    </span>
                      <span class="text">{{link.title}}</span>
                    </router-link>
                  </vue-scroll>
                </div>
                <div
                  class="header__login-menu-list js-login-menu-list"
                  ref="menuList"
                  v-else-if="accountType === 4"
                >
                  <vue-scroll :ops="ops">
                    <router-link
                      v-for="link of headerLinks.admin"
                      class="header__login-menu-list-item"
                      :to="link.link"
                      :key="link.title"
                    >
                    <span class="icon">
                      <SvgIcon :name="link.icon"/>
                    </span>
                      <span class="text">{{link.title}}</span>
                    </router-link>
                  </vue-scroll>
                </div>
              </div>

              <div class="header__login-menu-footer">
<!--                <div class="header__login-menu-profile">Aircraft provider</div>-->
                <span class="header__login-menu-link" @click="logOut">
                  <span class="icon">
                    <SvgIcon name="logout"/>
                  </span>
                  <span class="text">Exit</span>
                </span>
              </div>
            </div>
          </div>

          <div v-if="!$store.state.auth.login" class="header__link-wr">
            <span class="header__link js-popup" @click="$modal.show('LogInPopup')">Log In</span>
            <span class="header__link js-popup" @click="$modal.show('SignUpPopup')">Sign Up</span>
          </div>

<!--          <div class="header__language">-->
<!--            <SelectLang :select="'mini'"/>-->
<!--          </div>-->
        </div>
        <div v-if="type === 'booking'" class="header__button"></div>
        <div v-else-if="type === 'mapBlock'" class="header__button" @click="$modal.show('FlightRequestPopup')">
          <span class="mapBlock__flight-info js-popup">
            <SvgIcon name="controls"/>
          </span>
        </div>
        <div v-else-if="type === 'dashboard'" class="header__button">
          <div class="dashboardMenuButton js-dashboard-menu-button" @click="mobileMenuOpen = !mobileMenuOpen">
            <span class="icon icon--open">
              <SvgIcon name="menu"/>
            </span>
            <span class="icon icon--close">
              <SvgIcon name="close"/>
            </span>
          </div>
        </div>
        <div v-else class="header__button"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Toggle from '@/components/common/Toggle/Toggle'
  // import SearchForm from '@/components/Forms/SearchForm'
  import './Header.styl'
  // import SelectLang from '@/components/common/SelectLang/SelectLang'
  import avatar from '../../assets/img/content/avatar.jpg'

  export default {
    props: ['data', 'title', 'subtitle', 'type', 'search'],
    name: 'Header',
    data: () => ({
      notify: false,
      notyfyList: false,
      mail: false,
      mailList: false,
      avatar: avatar,
      headerLinks: {
        flyer: [
        {
          title: 'Profile',
          icon: 'user2',
          link: '/dashboard/profile',
        }, {
          title: 'Members',
          icon: 'members',
          link: '/dashboard/members',
        }, {
          title: 'Fly',
          subtitle: 'Calendar',
          icon: 'flycal',
          link: '/dashboard/flycalendar',
        }, {
          title: 'Subscriptions',
          icon: 'airplane',
          link: '/dashboard/subscriptions',
        }, {
          title: 'Route Alerts',
          icon: 'alarm',
          link: '/dashboard/alerts',
        }, {
          title: 'Auctions (bids)',
          icon: 'auction',
          link: '/dashboard/auctions',
        }, {
          title: 'FLY Empty',
          icon: 'discuss',
          link: '/dashboard/requests',
        }, {
          title: 'Sell spaces',
          icon: 'auction',
          link: '/dashboard/seats',
        }, {
          title: 'Settings & security',
          icon: 'locked',
          link: '/dashboard/settings',
        }, {
          title: 'FlyRewards',
          icon: 'giftbox',
          link: '/dashboard/flyrewards',
        }, {
          title: 'Payment information',
          icon: 'wallet',
          link: '/dashboard/payment',
        }, {
          title: 'Confirmed Bookings',
          icon: 'ticket',
          link: '/dashboard/booking',
        }, {
          title: 'Flights',
          icon: 'distance',
          link: '/dashboard/flights',
        }, {
          title: 'Messages',
          icon: 'message',
          link: '/dashboard/messages',
        }, {
          title: 'Passenger identification',
          icon: 'card',
          link: '/dashboard/passenger-id',
        },
      ],
        provider: [
          {
            title: 'Profile',
            icon: 'user2',
            link: '/provider-dashboard/profile',
          }, {
            title: 'Settings & security',
            icon: 'locked',
            link: '/provider-dashboard/settings',
          }, {
            title: 'Personnel',
            icon: 'pilot-hat',
            link: '/provider-dashboard/personnel',
          }, {
            title: 'Fleet',
            icon: 'plane-provider',
            link: '/provider-dashboard/fleet',
          }, {
            title: 'Automated Scheduler',
            icon: 'calendar-board',
            link: '/provider-dashboard/automated-scheduler',
          }, {
            title: 'Charter availability uploads',
            icon: 'calendar-aircraft',
            link: '/provider-dashboard/charter-uploads',
          }, {
            title: 'Flight availability uploads',
            icon: 'calendar-checked',
            link: '/provider-dashboard/flight-uploads',
          }, {
            title: 'Bookings',
            icon: 'ticket',
            link: '/provider-dashboard/booking',
          }, {
            title: 'Flights',
            icon: 'distance',
            link: '/provider-dashboard/flights',
          }, {
            title: 'Payment and bank info',
            icon: 'wallet',
            link: '/provider-dashboard/payment',
          }, {
            title: 'Messages',
            icon: 'message',
            link: '/provider-dashboard/messages',
          }, {
            title: 'Auctions bids and offers',
            icon: 'auction',
            link: '/provider-dashboard/auctions',
          },
        ],
        admin: [
          {
            title: 'Profile',
            icon: 'user2',
            link: '/admin/profile',
          },
          {
            title: 'Settings & security',
            icon: 'locked',
            link: '/admin/settings',
          },
          {
            title: 'Fly empty',
            icon: 'user2',
            link: '/admin/request-empty',
          },
          {
            title: 'Network activity',
            icon: 'network',
            link: '/admin/network',
          },
          {
            title: 'Aircraft provider requests',
            icon: 'discuss',
            link: '/admin/provider-sign-ups',
          },
          {
            title: 'Manual requests',
            icon: 'calendar-aircraft',
            link: '/admin/requests',
          },
          {
            title: 'Offset purchases',
            icon: 'wallet',
            link: '/admin/offsets',
          },
          {
            title: 'Auction: current and historical',
            icon: 'auction',
            link: '/admin/auction',
          },
          {
            title: 'Route alerts',
            icon: 'ticket',
            link: '/admin/alerts',
          },
          {
            title: 'Featured Flights',
            icon: 'ticket',
            link: '/admin/featured',
          },
          {
            title: 'Reservations',
            icon: 'ticket',
            link: '/admin/reservations',
          },
          {
            title: 'Confirmed Reservations',
            icon: 'ticket',
            link: '/admin/confirmed-reservations',
          },
          {
            title: 'Bookings',
            icon: 'ticket',
            link: '/admin/bookings',
          },
          {
            title: 'Confirmed bookings',
            icon: 'ticket',
            link: '/admin/confirmed-bookings',
          },
          {
            title: 'Flycal requests',
            icon: 'ticket',
            link: '/admin/flycal-requests',
          },
          {
            title: 'FLYRewards',
            icon: 'giftbox',
            link: '/admin/flyrewards',
          },
          {
            title: 'Argus statistic',
            icon: 'message',
            link: '/admin/argus-statistic',
          },
          {
            title: 'TFR sign ups',
            icon: 'message',
            link: '/admin/tfr-sign-ups',
          },
          {
            title: 'Get all feed',
            icon: 'message',
            link: '/admin/get-all-feed',
          },
          {
            title: 'Newsletter generator',
            icon: 'message',
            link: '/admin/newsletter-generator',
          },
          {
            title: 'Remove unsubscribed',
            icon: 'message',
            link: '/admin/remove-unsubscribed',
          },
          {
            title: 'Promo codes generator',
            icon: 'message',
            link: '/admin/promo-codes-generator',
          },
          {
            title: 'Users subscriptions',
            icon: 'message',
            link: '/admin/users-subscriptions',
          },
          {
            title: 'Gifts generator',
            icon: 'message',
            link: '/admin/gifts-generator',
          },
          {
            title: 'Subscription Analytics',
            icon: 'message',
            link: '/admin/subscription-analytics',
          },
        ],
      },
      ops: {
        rail: {
          size: '5px',
        },
        bar: {
          size: '5px',
          background: 'rgba(244, 244, 244, 0.2)',
        },
      },
      mobileMenuOpen: false,
      mobileHeaderMenuOpen: false,
      body: null,
      page: null,
      content: null,
    }),
    watch: {
      mobileMenuOpen () {
        if (this.mobileMenuOpen) {
          this.page.classList.add('dashboardMenuOpen')
          this.body.style.touchAction = 'none'
          this.content.classList.add('noScroll')
        } else {
          this.page.classList.remove('dashboardMenuOpen')
          this.body.style.touchAction = 'auto'
          this.content.classList.remove('noScroll')
        }
      },
      blogMobileMenuOpen () {
        if (this.blogMobileMenuOpen) {
          this.page.classList.add('blogMenuOpen')
          this.body.style.touchAction = 'none'
        } else {
          this.page.classList.remove('blogMenuOpen')
          this.body.style.touchAction = 'auto'
        }
      },
      '$route' (to, from) {
        if (this.blogMobileMenuOpen) {
          this.$store.dispatch('toggleBlogMobileMenu')
        }
      },
    },
    computed: {
      accountType () {
        return this.$store.state.auth.user.type;
      },
      categories () {
        return this.$store.state.blog.categories.filter(({ attributes }) => attributes.showInHeader).sort((a, b) => a.attributes.order - b.attributes.order);
      },
      blogMobileMenuOpen () {
        return this.$store.state.blog.blogMobileMenuOpen;
      },
      clickOnLogo () {
        if (this.$router.currentRoute.path.includes('dashboard')) {
          return '/map-search'
        } else {
          return '/'
        }
      },
    },
    mounted () {
      this.body = document.querySelector('body')
      this.page = document.querySelector('.page')
      this.content = document.querySelector('.content')
      if (!this.$store.state.auth.login) {
        this.$store.dispatch('checkLogin');
      }
    },
    methods: {
      openNotify () {
        // this.notify = true
        // this.mail = false
      },
      openMail () {
        // this.mail = true
        // this.notify = false
      },
      closeMenu () {
        this.mail = false
        this.notify = false
      },
      toggleHtml () {
        this.$root.animations.slideToggle(this.$refs.menuList, 190)
      },
      async logOut () {
        const result = await this.$store.dispatch('logoutUser')
        if (result) {
          await this.$router.push('/')

          const mainBlock = document.querySelector('.mainBlock')
          const buttons = document.querySelector('.mainBlock__button-wr')
          const contacts = document.querySelector('.mainBlock__contacts')
          if (buttons && contacts) {
            buttons.style.display = 'flex'
            contacts.style.display = 'block'
          }
          if (mainBlock && mainBlock.classList.contains('mainBlock--login')) {
            mainBlock.classList.remove('mainBlock--login')
          }
        }
      },
      showMobileMenu () {
        const html = document.querySelector('html')
        if (html.classList.contains('d-mobile')) {
          this.mobileHeaderMenuOpen = !this.mobileHeaderMenuOpen
        }
      },
      toggleBlogMobileMenu () {
        this.$store.dispatch('toggleBlogMobileMenu')
      },
      goToCategory (name) {
        const categoryTitle = document.querySelector(`a[name=${name}]`);
        if (categoryTitle) {
          categoryTitle.scrollIntoView();
        } else {
          this.$router.push(`/theflyreport/?category=${name}`)
        }
      },
    },
    components: {
      SvgIcon,
      Toggle,
      // SearchForm
      // SelectLang
    },
  }
</script>
