<template>
  <modal class="AircraftSearchPopupWrapper" name="AircraftSearchPopup" transition="pop-out" :adaptive="true">
    <div class="aircraftDetail-popup">
      <div class="aircraftDetail-popup__container">
        <div class="aircraftDetail" style="display: flex; flex-direction: column; justify-content: space-between; color: #292931;">
          <div class="aircraftDetail__top">
            <div class="aircraftDetail__top-inner" style="text-align: center; line-height: 1; font-weight: 700; color: #292931; margin-bottom: 30px">
              <div class="aircraftDetail__caption" style="line-height: 1; text-transform: uppercase; font-family: 'RobotoBold', Helvetica, Arial, sans-serif;">Aircraft</div>
              <div class="aircraftDetail__title" style="line-height: 1.3; font-family: 'RobotoBold', Helvetica, Arial, sans-serif;">{{this.$store.state.search.aircraftData.aircraftModel}}</div>
            </div>
          </div>
          <div class="aircraftDetail__bottom" style="display: flex; justify-content: center; justify-content: space-between;">
            <div class="aircraftDetail__left" style="display: flex;">
              <AircraftDetailListPopup :details="detailsLeft" />
            </div>
            <div class="aircraftDetail__center" style="display: flex;">
              <AircraftDetailSliderPopup :type="'aircraft'" :aircraftImages="aircraftImages"/>
            </div>
            <div class="aircraftDetail__right">
              <AircraftDetailListPopup :details="detailsRight"/>
            </div>
          </div>
        </div>
      </div>
      <button class="fancybox-button fancybox-close-small" @click="$modal.hide('AircraftSearchPopup')"></button>
    </div>
  </modal>
</template>

<script>
  import AircraftDetailListPopup from '@/components/AircraftDetailPopup/AircraftDetailListPopup';
  import AircraftDetailSliderPopup from '@/components/AircraftDetailPopup/AircraftDetailSliderPopup';
  import './AircraftSearchPopup.styl';

  export default {
    name: 'AircraftSearchPopup',
    data: () => ({}),
    computed: {
      detailsLeft () {
        const data = this.$store.state.search.aircraftData
        return [
          {icon: 'aeroplane', title: ' Aircraft Type:', value: data.aircraftType},
          {icon: 'protection', title: 'Argus Safety Rating:', value: data.argusSafetyRating},
          {icon: 'placeholder2', title: 'Home Base:', value: data.homeBase},
          {icon: 'clock2', title: 'Year of Manufacture:', value: data.yearOfManufacture},
          {icon: 'clock', title: 'Last Interior Refurbish:', value: data.lastInteriorRefurbish},
          {icon: 'clock', title: 'Last Exterior Refurbish:', value: data.lastExteriorRefurbish},
          {icon: 'tv', title: 'Television(s) Onboard:', value: data.televisionOnboard},
        ]
      },
      detailsRight () {
        const data = this.$store.state.search.aircraftData
        return [
          {icon: 'moving', title: 'Max Passengers:', value: data.maxPassengers},
          {icon: 'open-folder', title: 'Insurance:', value: data.insurance},
          {icon: 'chronometer', title: 'Hours Flown:', value: data.hoursFlown},
          {icon: 'speedometer', title: 'Speed (nmph):', value: data.aircraftSpeed},
          {icon: 'mountains', title: 'Range (nm):', value: data.aircraftRange},
          {icon: 'wifi', title: 'Wi-Fi:', value: data.wifiOnboard},
          {icon: 'box', title: 'Cargo Capacity (lbs):', value: data.aircraftCargoCapacity},
        ]
      },
      aircraftImages () {
        // console.log(this.$store.state.search.aircraftData.aircraftImages)
        return this.$store.state.search.aircraftData.aircraftImages
      },
    },
    components: {
      AircraftDetailListPopup,
      AircraftDetailSliderPopup,
    },
  };
</script>
