const state = () => ({
  list: [
    {
      id: 'chat1',
      name: 'John Doe',
      mod: 'inbox',
      ava: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602199687/FlyJets/avatar_s1o4ii.jpg',
      dialog: true,
      online: true,
      date: 'December 31, 2018 - January 7, 2019',
      time: '3:30 PM',
      info: {
        subject: true,
        re: 'Flight 12345',
        departure: 'Teterboro Airport (IATA: TEB, ICAO: KTEB, FAA LID: TEB)',
        arrival: 'Opa-Locka Executive Airport (IATA: OPF, ICAO: KOPF, FAA LID: OPF)',
      },
      chat: [
        {
          ava: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602199687/FlyJets/avatar_s1o4ii.jpg',
          firstName: 'John',
          messages: [
            {
              time: '3:30 PM',
              text: 'Hi Jessica, The tail number for your upcoming flight is N123XYZ. We have confirmed take-off at 3pm - 15:00:00 EST - on Sunday, September 2, 2018',
            },
          ],
        },
        {
          ava: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784189/FlyJets/avatar_zmfk8x.jpg',
          firstName: 'You',
          messages: [
            {
              time: '3:30 PM',
              text: 'Hi Jessica, The tail number for your upcoming flight is N123XYZ. We have confirmed take-off at 3pm - 15:00:00 EST - on Sunday, September 2, 2018',
            },
            {
              time: '5:50 PM',
              text: 'tail number for your upcoming flight is N123XYZ.',
            },
          ],
        },
        {
          ava: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602199687/FlyJets/avatar_s1o4ii.jpg',
          firstName: 'John',
          messages: [
            {
              time: '6:30 PM',
              text: 'Hi Jessica, The tail number for your upcoming flight is N123XYZ. We have confirmed take-off at 3pm - 15:00:00 EST - on Sunday, September 2, 2018',
            },
          ],
        },
        {
          ava: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784189/FlyJets/avatar_zmfk8x.jpg',
          firstName: 'You',
          messages: [
            {
              time: '9:30 PM',
              text: 'on Sunday, September 2, 2018',
            },
            {
              time: '9:31 PM',
              text: 'bye!',
            },
          ],
        },
      ],
    },
    {
      id: 'chat2',
      name: 'Oxana Ivanova',
      mod: 'sent',
      ava: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784189/FlyJets/avatar_zmfk8x.jpg',
      dialog: false,
      online: true,
      date: 'December 31, 2018 - January 7, 2019',
      time: '3:30 PM',
      info: {
        subject: true,
        re: 'Flight 12345',
        departure: 'Teterboro Airport (IATA: TEB, ICAO: KTEB, FAA LID: TEB)',
        arrival: 'Opa-Locka Executive Airport (IATA: OPF, ICAO: KOPF, FAA LID: OPF)',
      },
      chat: [
        {
          ava: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602199687/FlyJets/avatar_s1o4ii.jpg',
          firstName: 'John',
          messages: [
            {
              time: '3:30 PM',
              text: 'Hi Jessica, The tail number for your upcoming flight is N123XYZ. We have confirmed take-off at 3pm - 15:00:00 EST - on Sunday, September 2, 2018',
            },
          ],
        },
        {
          ava: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784189/FlyJets/avatar_zmfk8x.jpg',
          firstName: 'You',
          messages: [
            {
              time: '3:30 PM',
              text: 'Hi Jessica, The tail number for your upcoming flight is N123XYZ. We have confirmed take-off at 3pm - 15:00:00 EST - on Sunday, September 2, 2018',
            },
            {
              time: '5:50 PM',
              text: 'tail number for your upcoming flight is N123XYZ.',
            },
          ],
        },
        {
          ava: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602199687/FlyJets/avatar_s1o4ii.jpg',
          firstName: 'John',
          messages: [
            {
              time: '6:30 PM',
              text: 'Hi Jessica, The tail number for your upcoming flight is N123XYZ. We have confirmed take-off at 3pm - 15:00:00 EST - on Sunday, September 2, 2018',
            },
          ],
        },
        {
          ava: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784189/FlyJets/avatar_zmfk8x.jpg',
          firstName: 'You',
          messages: [
            {
              time: '9:30 PM',
              text: 'on Sunday, September 2, 2018',
            },
            {
              time: '9:31 PM',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            },
          ],
        },
      ],
    },
    {
      id: 'chat3',
      name: 'John Doe',
      mod: 'inbox',
      ava: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602199687/FlyJets/avatar_s1o4ii.jpg',
      dialog: false,
      online: false,
      date: 'December 31, 2018 - January 7, 2019',
      time: '3:30 PM',
      info: {
        subject: true,
        re: 'Flight 12345',
        departure: 'Teterboro Airport (IATA: TEB, ICAO: KTEB, FAA LID: TEB)',
        arrival: 'Opa-Locka Executive Airport (IATA: OPF, ICAO: KOPF, FAA LID: OPF)',
      },
      chat: [
        {
          ava: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602199687/FlyJets/avatar_s1o4ii.jpg',
          firstName: 'John',
          messages: [
            {
              time: '3:30 PM',
              text: 'Hi Jessica, The tail number for your upcoming flight is N123XYZ. We have confirmed take-off at 3pm - 15:00:00 EST - on Sunday, September 2, 2018',
            },
          ],
        },
        {
          ava: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784189/FlyJets/avatar_zmfk8x.jpg',
          firstName: 'You',
          messages: [
            {
              time: '3:30 PM',
              text: 'Hi Jessica, The tail number for your upcoming flight is N123XYZ. We have confirmed take-off at 3pm - 15:00:00 EST - on Sunday, September 2, 2018',
            },
            {
              time: '5:50 PM',
              text: 'tail number for your upcoming flight is N123XYZ.',
            },
          ],
        },
        {
          ava: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602199687/FlyJets/avatar_s1o4ii.jpg',
          firstName: 'John',
          messages: [
            {
              time: '6:30 PM',
              text: 'Hi Jessica, The tail number for your upcoming flight is N123XYZ. We have confirmed take-off at 3pm - 15:00:00 EST - on Sunday, September 2, 2018',
            },
          ],
        },
        {
          ava: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784189/FlyJets/avatar_zmfk8x.jpg',
          firstName: 'You',
          messages: [
            {
              time: '9:30 PM',
              text: 'on Sunday, September 2, 2018',
            },
            {
              time: '9:31 PM',
              text: 'bye!',
            },
          ],
        },
      ],
    },
    {
      id: 'chat4',
      name: 'Anna Li',
      mod: 'sent',
      ava: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784189/FlyJets/avatar_zmfk8x.jpg',
      dialog: true,
      online: true,
      date: 'December 31, 2018 - January 7, 2019',
      time: '3:30 PM',
      info: {
        subject: true,
        re: 'Flight 12345',
        departure: 'Teterboro Airport (IATA: TEB, ICAO: KTEB, FAA LID: TEB)',
        arrival: 'Opa-Locka Executive Airport (IATA: OPF, ICAO: KOPF, FAA LID: OPF)',
      },
      chat: [
        {
          ava: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602199687/FlyJets/avatar_s1o4ii.jpg',
          firstName: 'John',
          messages: [
            {
              time: '3:30 PM',
              text: 'Hi Jessica, The tail number for your upcoming flight is N123XYZ. We have confirmed take-off at 3pm - 15:00:00 EST - on Sunday, September 2, 2018',
            },
          ],
        },
        {
          ava: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784189/FlyJets/avatar_zmfk8x.jpg',
          firstName: 'You',
          messages: [
            {
              time: '3:30 PM',
              text: 'Hi Jessica, The tail number for your upcoming flight is N123XYZ. We have confirmed take-off at 3pm - 15:00:00 EST - on Sunday, September 2, 2018',
            },
            {
              time: '5:50 PM',
              text: 'tail number for your upcoming flight is N123XYZ.',
            },
          ],
        },
        {
          ava: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602199687/FlyJets/avatar_s1o4ii.jpg',
          firstName: 'John',
          messages: [
            {
              time: '6:30 PM',
              text: 'Hi Jessica, The tail number for your upcoming flight is N123XYZ. We have confirmed take-off at 3pm - 15:00:00 EST - on Sunday, September 2, 2018',
            },
          ],
        },
        {
          ava: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784189/FlyJets/avatar_zmfk8x.jpg',
          firstName: 'You',
          messages: [
            {
              time: '9:30 PM',
              text: 'on Sunday, September 2, 2018',
            },
            {
              time: '9:31 PM',
              text: 'bye!',
            },
          ],
        },
      ],
    },
  ],
  currentChat: null,
})

const mutations = {
  selectChat (state, id) {
    state.currentChat = id
  },
}

const getters = {
  getChat (state) {
    return state.list.find(item => item.id === state.currentChat)
  },
}

export default {
  state,
  mutations,
  getters,
}
