<template>
  <modal class="white-popup" name="FlyCalFrontPaymentProcessedPopup" transition="pop-out" :adaptive="true">

<div class="comingSoonPopup" id="comingSoonPopup">
     <!-- <div class="comingSoonPopup__decor" style="background-image:url('/img/assets/common/comingSoon/planet.png')"></div> -->
        <div class="comingSoonPopup__inner" style="padding-top:7%;">
            <!-- <div class="comingSoonPopup__icon">
                <img src="@/assets/img/comingSoonMail.png">
            </div> -->
            <div class="comingSoonPopup__title" style="font-size:1.1em; margin-bottom:15%;">Payment Processed</div>
            <div class="comingSoonPopup__text">
                Continue adding to FLYCalendar requests or search for flights from our home page.
                <br/>
                <span style="color:green;">Happy Flying!</span>
          <div class="mainBlock__button-wr" v-if="type !== 'reset'">
            <button
              class="mainBlock__button mainBlock__button--active"
              @click="$modal.hide('FlyCalFrontPaymentProcessedPopup')"
            >
              <span class="text">Okay</span>
            </button>
          </div>
            </div>
        </div>
    </div>
  </modal>
</template>
<script>
  import './ComingSoonPopup.styl'

  export default {
    name: 'FlyCalFrontPaymentProcessedPopup',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
      type: null,
    }),
  }
</script>
