<template>
  <modal name="MainFlyGreenPopup" class="mainFlyGreenPopup" transition="pop-out" :adaptive="true">
    <div class="flygreen-main-page-popup">
      <div class="flygreen-main-page-popup__container">
        <div class="flygreen-main-page-popup__title">
          <span class="flygreen-main-page-popup__title flygreen-main-page-popup__title--color">Fly</span>green
        </div>
        <p>To purchase carbon offsets and/or sustainable aviation fuel,<br> please log in or reach out to us at<br> <a class="flygreen-main-page-popup__link" href="mailto:FLY@flyjets.com">FLY@flyjets.com</a><span class="flygreen-main-page-popup__delimiter">or</span> <a class="flygreen-main-page-popup__link" href="tel:+1 (212) 845-5137">+1 (212) 845-5137.</a></p>
        <button class="buttonGreen flygreen-main-page-popup__button" @click="closePopup">
          <span class="text">Okay</span>
        </button>
      </div>
      <div class="modal__close flygreen-main-page-popup__close" @click="closePopup">
        <SvgIcon name="close-white"/>
      </div>
    </div>
  </modal>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import './MainFlyGreenPopup.styl'

  export default {
    name: 'MainFlyGreenPopup',
    methods: {
      closePopup () {
        this.$modal.hide('MainFlyGreenPopup')
      },
    },
    components: {
      SvgIcon,
    },
  };
</script>
