import {instance} from '../index';

export const settingsAPI = {
  getNotChannel () {
    return instance.get('accounts/getnotchannel')
  },
  changePassword (data) {
    return instance.post('accounts/changepass', {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    })
  },
  // setAlerts (data) {
  //   return instance.post('accounts/setalerts', {
  //     alerts: data
  //   })
  // },
  setAlerts (data) {
    return instance.post('accounts/updatenotchannel', {
      channel: data,
    })
  },
}
