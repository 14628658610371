import { flyGreenAPI } from '@/api/flyer/flyGreen';

const state = () => ({
  carbonEmissionsGenerated: 404.7,
  purchaseValueGenerated: 10,
  purchaseValue: 0,
  percentEmissions: 100,
  carbonEmissions: 404.7,
  offsetPurchasePayload: {
    offsetPercentage: 0,
    offsetPrice: 0,
    bookingId: '',
  },
})

const mutations = {
  changePurchaseValue (state) {
    state.purchaseValue = (state.carbonEmissions / (state.carbonEmissionsGenerated / state.purchaseValueGenerated).toFixed(2)).toFixed(2)
  },
  changePercentEmissions (state, value) {
    state.carbonEmissions = value

    if (state.carbonEmissions > state.carbonEmissionsGenerated / 1.1) {
      state.percentEmissions = Math.floor(state.carbonEmissions / (state.carbonEmissionsGenerated / 100))
    } else {
      state.percentEmissions = Math.round(state.carbonEmissions / (state.carbonEmissionsGenerated / 100))
    }
  },
  changeCarbonEmissions (state, value) {
    if (+value === 100) {
      state.carbonEmissions = state.carbonEmissionsGenerated
    } else {
      state.carbonEmissions = (state.carbonEmissionsGenerated / 100 * (+value)).toFixed(1)
      if (state.carbonEmissions === 0.0 || state.carbonEmissions === '0.0') {
        state.carbonEmissions = 0
      }
    }
  },
  setOffsetPurchasePayload (state, value) {
    state.offsetPurchasePayload = { ...state.offsetPurchasePayload, ...value }
  },
}

const actions = {
  async getFlyGreenData () {
    return await flyGreenAPI.getFlyGreenData();
  },
  async submitFlyGreen ({ commit }, data) {
    return await flyGreenAPI.submitFlyGreen(data.percentEmissions, data.purchaseValue, data.bookingNumber);
  },
  storeOffsetPurchasePayload ({ commit }, data) {
    commit('setOffsetPurchasePayload', data)
  },
}

export default {
  state,
  mutations,
  actions,
}
