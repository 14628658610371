const state = () => ({
  requests: [
    {
      id: 'request1',
      photo: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1600460711/FlyJets/6_wnzg9i.jpg',
      departure: 'Teterboro Airport (IATA: TEB, ICAO: KTEB, FAA LID: TEB)',
      arrival: 'Opa-Locka Executive Airport (IATA: OPF, ICAO: KOPF, FAA LID: OPF)',
      dates: {
        start: '12-02-2019',
        end: '12-06-2019',
      },
      options: ['catering'],
      specified: 'Flyer\'s request is specified',
    }, {
      id: 'request2',
      photo: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1600460711/FlyJets/6_wnzg9i.jpg',
      departure: 'Teterboro Airport (IATA: TEB, ICAO: KTEB, FAA LID: TEB)',
      arrival: 'Opa-Locka Executive Airport (IATA: OPF, ICAO: KOPF, FAA LID: OPF)',
      dates: {
        start: '07-02-2020',
        end: '10-21-2020',
      },
      options: ['other'],
      specified: 'Please specify',
    },
  ],
  requestToRemove: null,
  passenger: {
    passportNumber: '5566 55 4 334 4',
    date: '04/04/2035',
    file: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602344442/FlyJets/passport_ontrbi.jpg',
    fileSize: '25MB',
  },
  payment: [
    // {
    //   id: 'card1',
    //   paymentType: 'Visa',
    //   cardType: 'Debit card',
    //   cardNumber: '1111 2222 3333 5555',
    //   cardHolder: 'Inna Olegova',
    //   billingAddress: '678 Broadway, 9th floor New York, NY 10003, US',
    //   expirationDate: '04/2022',
    //   cvv: 123,
    // },
    // {
    //   id: 'card2',
    //   paymentType: 'MasterCard',
    //   cardType: 'Debit card',
    //   cardNumber: '5453 1234 9373 3126',
    //   cardHolder: 'Inna Olegova',
    //   billingAddress: '678 Broadway, 9th floor New York, NY 10003, US',
    //   expirationDate: '11/2020',
    //   cvv: 321,
    // },
  ],
  paymentToRemove: null,
  routes: [
    {
      id: 0,
      from: 'San Jose international airport (San Jose, California)',
      to: 'McCarran international airport (Las Vegas, Nevada)',
      flightCount: 2,
      description: 'All Friday Departure, Sunday Returns through 2020 | Charter Aircraft and Charter Seat Options',
      cost: '1,500.00',
      watching: true,
    }, {
      id: 1,
      from: 'JFK international airport (New York)',
      to: 'Miami international airport (Miami)',
      flightCount: 1,
      description: 'All Friday Departure, Sunday Returns through 2020 | Charter Aircraft and Charter Seat Options',
      cost: '800.00',
      watching: false,
    },
  ],
  routeToRemove: null,
  auctions: [
    {
      from: 'San Jose international airport (San Jose, California)',
      to: 'McCarran international airport (Las Vegas, Nevada)',
      dateFrom: '4:30 PM, APR 24',
      dateTo: '4:30 PM, APR 24',
      cost: '30,000.00',
      oneWay: false,
    }, {
      from: 'JFK international airport (New York)',
      to: 'Miami international airport (Miami)',
      dateFrom: '4:30 PM, APR 24',
      dateTo: '6:30 PM, APR 25',
      cost: '8,000.00',
      oneWay: true,
    },
  ],
  seats: [
    {
      id: 0,
      photo: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1600460711/FlyJets/6_wnzg9i.jpg',
      from: 'Teterboro Airport (IATA: TEB, ICAO: KTEB, FAA LID: TEB)',
      to: 'Opa-Locka Executive Airport (IATA: OPF, ICAO: KOPF, FAA LID: OPF)',
      startDate: 'December 31, 2018',
      endDate: 'January 7, 2019',
      dates: {
        start: '12-02-2019',
        end: '12-06-2019',
      },
    },
  ],
})

const mutations = {
  insertRequestToRemove (state, value) {
    state.requestToRemove = value
  },
  removeItemRequest (state) {
    state.requests = state.requests.filter(item => item.id !== state.requestToRemove)
  },
  insertPaymentToRemove (state, value) {
    state.paymentToRemove = value
  },
  removeItemPayment (state) {
    state.payment = state.payment.filter(item => item.id !== state.paymentToRemove)
  },
  changeWatching (state, id) {
    const currentRoute = state.routes.find(item => item.id === id)
    state.routes = state.routes.map(item => {
      if (item.id === currentRoute.id) {
        return {
          ...item,
          watching: !item.watching,
        }
      }
      return item
    })
  },
  insertRouteToRemove (state, value) {
    state.routeToRemove = value
  },
  removeWatchig (state) {
    state.routes = state.routes.filter(item => item.id !== state.routeToRemove)
  },
}

const hideNumber = (item) => {
  const value = item.split(' ').join('')
  const secretSymbols = value.slice(0, -4).split('').map(item => '*').join('')
  const newValue = secretSymbols + value.slice(-4)
  return newValue
}

const getters = {
  getUpdatedPayment (state) {
    return state.payment.map(item => {
      return {
        ...item,
        cardNumber: hideNumber(item.cardNumber),
      }
    })
  },
}

export default {
  state,
  mutations,
  getters,
}
