import {instance} from '../index';

export const passengerIdAPI = {
  driversLicense (data) {
    return instance.post('accounts/driversLicense', {
      licenseNumber: data.licenseNumber,
      licenseDate: data.licenseDate,
      license: data.license,
    })
  },
  passport (data) {
    return instance.post('accounts/passport', {
      passportNumber: data.passportNumber,
      passportDate: data.passportDate,
      passport: data.passport,
    })
  },
  birthCertificate (data) {
    return instance.post('accounts/birthCertificate', {
      birth: data.birth,
    })
  },
}
