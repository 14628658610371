import {instance} from '../index';

export const adminRequestsAPI = {
    getApRequests (pageNumber, rowsCount) {
        return instance.get(`accounts/apsPendingApprovalPaginated?page=${pageNumber}&size=${rowsCount}`);
    },
    exportToExcel (type) {
        return instance.get('reports/' + type)
    },
    getCompanies (data) {
        return instance.get('/accounts/autocompletesearchcompanies/' + data)
    },
    acceptRequestCompany (data) {
      return instance.post('accounts/acceptSignupRequest', {
        accountId: data.id,
        accountName: data.companyName,
        accountPhone: data.mobile,
        accountEmail: data.email,
        newCompany: data.new,
        existingCompanyId: data.existingCompanyId,
      })
    },
}
