<template>
  <modal class="RequestSuccessPopupWrapper" name="RequestSuccess" transition="pop-out" :adaptive="true">
    <div class="tingle-modal" id="request-success">
      <div class="request-popup">
        <div class="request-popup__container">
          <div class="request-popup__title">Request Sent Successfully.</div>
          <p>We have received your request and will be back to you with flight options as soon as possible.</p>
          <p>To guarantee delivery of results - including a comprehensive list of charter aircraft and charter flights
            at the best available rates - in one business day or less, please click below to submit a $250 reservation
            fee, which will be used toward the ultimate cost of your booking. Unused reservation fees may be used as
            credit toward future bookings.</p>
          <button class="buttonGreen request-popup__button js-request-popup-flow" type="button" @click="showRequestPayment">Reserve</button>
        </div>
        <div class="request-popup__close js-request-popup-close" @click="$modal.hide('RequestSuccess')">
          <SvgIcon name="close-white-2"/>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
  import '@/components/common/modals/RequestModals.styl';

  export default {
    name: 'RequestSuccess',
    methods: {
      showRequestPayment () {
        this.$modal.hide('RequestSuccess')
        setTimeout(() => {
          this.$modal.show('RequestPayment')
        }, 1000);
      },
    },
    components: {
      SvgIcon,
    },
  };
</script>
