<template>
  <modal name="ForgotPasswordPopup" transition="pop-out" :adaptive="true" @closed="$v.$reset()">
    <div class="formPopup" id="forgotPasswordPopup">
      <div class="formPopup__inner">
        <div class="formPopup__left">
          <div class="formPopup__back js-popup" @click="changeToForgot">
            <span class="icon">
              <SvgIcon name="left-arrow"/>
            </span>
            <span class="text">Back to Log in</span>
          </div>
          <div class="formPopup__text" style="color: #b3b3b3">Check your email for a new temporary password, so long as your information exists in our system.</div>
<!--          <div class="formPopup__text">Check mail</div>-->
        </div>
        <div class="formPopup__right">
          <div class="formPopup__title">Forgot password</div>
          <div class="formPopup__form">
            <form
              class="form form--forgotPassword js-ajaxForm"
              :class="{'loading' : loading}"
              data-href="/"
              data-action="forgotPassword"
              id="forgotPasswordForm"
              @submit.prevent="submitHandler"
            >
              <Spinner/>
              <label class="form__label form__label--text">
                <div class="form__caption">Email me</div>
                <div class="form__field-wrapper">
                  <input
                    class="form__field"
                    type="email"
                    v-model="email"
                    autocomplete="off"
                    placeholder="Email address"
                  />
                </div>
                <span
                  v-if="$v.email.$dirty && !$v.email.required"
                  class="form__field-invalid form__field-invalid--right-side"
                >
                  This field is required
                </span>
                <span
                  v-if="$v.email.$dirty && !$v.email.email"
                  class="form__field-invalid form__field-invalid--right-side"
                >
                Enter a valid Email
              </span>
              </label>
              <div class="formPopup__text-wr">
                <div class="formPopup__text" style="color: #b3b3b3">Check your email for a new temporary password, so long as your information exists in our system.</div>
<!--                <div class="formPopup__text">Check mail</div>-->
              </div>
              <div class="form__label form__label--button">
                <button class="form__button" :disabled="loading" type="submit">
                  <span class="text">Send password</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <button type="button" class="modal__close" @click="$modal.hide('ForgotPasswordPopup')">
        <SvgIcon name="close-white"/>
      </button>
    </div>
  </modal>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Spinner from '@/components/common/Spinner/Spinner'
  import { email, required } from 'vuelidate/lib/validators'

  export default {
    name: 'ForgotPasswordPopup',
    data: () => ({
      email: '',
      loading: false,
    }),
    validations: {
      email: {
        email,
        required,
      },
    },
    methods: {
      async submitHandler () {
        if (this.$v.$invalid) {
          this.$v.$touch()
          return false
        }

        const data = {
          email: this.email,
        }

        this.loading = true

        const result = await this.$store.dispatch('forgotPass', data)

        if (result) {
          this.$modal.hide('ForgotPasswordPopup')
          this.$modal.show('InfoPopup', {
            title: 'Success',
            text: 'An email has been sent to your email address.',
          })
        }
        this.loading = false
      },
      changeToForgot () {
        this.$modal.hide('ForgotPasswordPopup')
        this.$modal.show('LogInPopup')
      },
    },
    components: {
      SvgIcon,
      Spinner,
    },
  }
</script>
