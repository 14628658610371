import {instance} from '../index';

export const flyerAPI = {
  getCurrentFlights () {
    return instance.get('flights/current')
  },
  getUpcomingFlights () {
    return instance.get('flights/upcoming')
  },
  getHistoricalFlights () {
    return instance.get('bookings/getAllUserBookings')
  },
}
