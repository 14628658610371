import {locationsAPI} from '@/api/locations';
import {historyAPI} from '@/api/history';
import {searchAPI} from '@/api/search';

const state = () => ({
  locations: {
    from: [],
    to: [],
  },
  searchHistory: {
    arrivalDate: null,
    arrivalId: null,
    bookingType: null,
    departureDate: null,
    departureId: null,
    passengers: null,
  },
  searchResults: [],
  searchResultsCharterFlights: [],
  searchResultsItem: {
    aircraftImages: [],
  },
  featuredFlights: [],
  featuredFlightToBook: {
    aircraftModel: null,
    aircraftPax: null,
    aircraftRange: null,
    aircraftArgusSafetyRating: null,
    aircraftSpeed: null,
    aircraftType: null,
    arrival: null,
    defaultImageUrl: null,
    departure: null,
    departureDate: null,
    emptyLegId: null,
    totalPrice: null,
    flightDurationHours: null,
    flightDurationMinutes: null,
    pax: null,
    wiFi: null,
    paxFromSearch: null,
  },
  searchRequest: {
    arrivalId: null,
    arrivalName: null,
    bookingType: null,
    departureDate: null,
    departureId: null,
    departureName: null,
    direction: null,
    pax: null,
    returnDate: null,
    selectedBookingTypes: null,
  },
  SearchRequestCharterFlights: {
    arrivalId: null,
    arrivalName: null,
    bookingType: null,
    departureDate: null,
    departureId: null,
    departureName: null,
    direction: null,
    pax: null,
    returnDate: null,
    selectedBookingTypes: null,
  },
  aircraftData: {
    aircraftCargoCapacity: null,
    aircraftImages: [],
    aircraftModel: null,
    aircraftRange: null,
    aircraftSpeed: null,
    aircraftType: null,
    argusSafetyRating: null,
    homeBase: null,
    hoursFlown: null,
    insurance: null,
    lastExteriorRefurbish: null,
    lastInteriorRefurbish: null,
    maxPassengers: null,
    televisionOnboard: null,
    wifiOnboard: null,
    yearOfManufacture: null,
  },
  manuallyReqId: null,
});

const mutations = {
  setLocations (state, {result, data}) {
    if (data.type === 'from') {
      state.locations.from = result.data;
    } else {
      state.locations.to = result.data;
    }
  },
  setFeaturedFlights (state, data) {
    state.featuredFlights = data;
  },
  deleteListLocations (state, data) {
    if (data.type === 'from') {
      state.locations.from = [];
    } else {
      state.locations.to = [];
    }
  },
  setSearchHistory (state, data) {
    state.searchHistory.arrivalDate = data.arrivalDate;
    state.searchHistory.arrivalId = data.arrivalId;
    state.searchHistory.bookingType = data.bookingType;
    state.searchHistory.departureDate = data.departureDate;
    state.searchHistory.departureId = data.departureId;
    state.searchHistory.passengers = data.passengers;
  },
  setSearchResults (state, data) {
    state.searchResults = data;
  },
  setSearchResultsCharterFlights (state, data) {
    state.searchResultsCharterFlights = data;
  },
  setSearchRequest (state, data) {
    state.searchRequest.arrivalId = data.arrivalId;
    state.searchRequest.arrivalName = data.arrivalName;
    state.searchRequest.bookingType = data.bookingType;
    state.searchRequest.departureDate = data.departureDate;
    state.searchRequest.departureId = data.departureId;
    state.searchRequest.departureName = data.departureName;
    state.searchRequest.direction = data.direction;
    state.searchRequest.pax = data.pax;
    state.searchRequest.returnDate = data.returnDate;
    state.searchRequest.selectedBookingTypes = data.selectedBookingTypes;
  },
  setSearchRequestCharterFlights (state, data) {
    state.SearchRequestCharterFlights.arrivalId = data.arrivalId;
    state.SearchRequestCharterFlights.arrivalName = data.arrivalName;
    state.SearchRequestCharterFlights.bookingType = data.bookingType;
    state.SearchRequestCharterFlights.departureDate = data.departureDate;
    state.SearchRequestCharterFlights.departureId = data.departureId;
    state.SearchRequestCharterFlights.departureName = data.departureName;
    state.SearchRequestCharterFlights.direction = data.direction;
    state.SearchRequestCharterFlights.pax = data.pax;
    state.SearchRequestCharterFlights.returnDate = data.returnDate;
    state.SearchRequestCharterFlights.selectedBookingTypes = data.selectedBookingTypes;
  },
  setFeaturedFlightToBook (state, data) {
    const featuredFlight = state.featuredFlights.find(item => item.emptyLegId === data.id)

    state.featuredFlightToBook.aircraftAvailabilityId = featuredFlight.aircraftAvailabilityId
    state.featuredFlightToBook.aircraftId = featuredFlight.aircraftId
    state.featuredFlightToBook.aircraftArgusSafetyRating = featuredFlight.aircraftArgusSafetyRating
    state.featuredFlightToBook.aircraftModel = featuredFlight.aircraftModel
    state.featuredFlightToBook.aircraftPax = featuredFlight.aircraftPax
    state.featuredFlightToBook.aircraftRange = featuredFlight.aircraftRange
    state.featuredFlightToBook.aircraftSpeed = featuredFlight.aircraftSpeed
    state.featuredFlightToBook.aircraftType = featuredFlight.aircraftType
    state.featuredFlightToBook.arrival = featuredFlight.arrival
    state.featuredFlightToBook.defaultImageUrl = featuredFlight.defaultImageUrl
    state.featuredFlightToBook.departure = featuredFlight.departure
    state.featuredFlightToBook.departureDate = featuredFlight.departureDate
    state.featuredFlightToBook.emptyLegId = featuredFlight.emptyLegId
    state.featuredFlightToBook.totalPrice = featuredFlight.totalPrice
    state.featuredFlightToBook.flightDurationHours = featuredFlight.flightDurationHours
    state.featuredFlightToBook.flightDurationMinutes = featuredFlight.flightDurationMinutes
    state.featuredFlightToBook.pax = featuredFlight.pax
    state.featuredFlightToBook.wiFi = featuredFlight.wiFi
    state.featuredFlightToBook.numberOfTelevision = featuredFlight.numberOfTelevision
    state.featuredFlightToBook.manufactureYear = featuredFlight.manufactureYear
    state.featuredFlightToBook.intRefurbishYear = featuredFlight.lastIntRefurbish
    state.featuredFlightToBook.extRefurbishYear = featuredFlight.lastExtRefurbish
    state.featuredFlightToBook.bookingType = featuredFlight.bookingType
    state.featuredFlightToBook.paxFromSearch = data.pax;
  },
  removeFeaturedFlightToBook (state) {
    for (const key in state.featuredFlightToBook) {
      state.featuredFlightToBook[key] = null
    }
  },
  setBookingType (state, data) {
    state.searchRequest.selectedBookingTypes = data
  },
  setManuallyReqId (state, id) {
    state.manuallyReqId = id
  },
  setAircraftPopup (state, data) {
    state.aircraftData.aircraftCargoCapacity = data[0].aircraftCargoCapacity
    state.aircraftData.aircraftImages = data[0].aircraftImages
    state.aircraftData.aircraftModel = data[0].aircraftModel
    state.aircraftData.aircraftRange = data[0].aircraftRange
    state.aircraftData.aircraftSpeed = data[0].aircraftSpeed
    state.aircraftData.aircraftType = data[0].aircraftType
    state.aircraftData.argusSafetyRating = data[0].argusSafetyRating
    state.aircraftData.homeBase = data[0].homeBase
    state.aircraftData.hoursFlown = data[0].hoursFlown
    state.aircraftData.insurance = data[0].insurance
    state.aircraftData.lastExteriorRefurbish = data[0].lastExteriorRefurbish
    state.aircraftData.lastInteriorRefurbish = data[0].lastInteriorRefurbish
    state.aircraftData.maxPassengers = data[0].maxPassengers
    state.aircraftData.televisionOnboard = data[0].televisionOnboard
    state.aircraftData.wifiOnboard = data[0].wifiOnboard
    state.aircraftData.yearOfManufacture = data[0].yearOfManufacture
  },
  setIsFlyEmpty (state, data) {
    state.searchResults.aircraftAvailabilities
      .find(a => a.aircraftAvailabilityId === data.aircraftAvailabilityId).isFlyEmpty = data.isFlyEmpty
  },
  setCurrentBidValue (state, data) {
    state.searchResults.aircraftAvailabilities
      .find(a => a.aircraftAvailabilityId === data.aircraftAvailabilityId).currentBidValue = data.currentBidValue
  },
};

const getters = {
  shortListLocationsFrom: state => count => {
    return state.locations.from.filter((elem, id) => {
      if (id <= count) {
        return elem;
      }
    });
  },
  shortListLocationsTo: state => count => {
    return state.locations.to.filter((elem, id) => {
      if (id <= count) {
        return elem;
      }
    });
  },
  getBookingType: state => {
    return state.searchRequest.selectedBookingTypes
  },
};

const actions = {
  async searchLocations ({commit, dispatch}, data) {
    try {
      const result = await locationsAPI.searchLocations(data);
      commit('setLocations', {result, data});
    } catch (e) {
      commit('deleteListLocations', data);
    }
  },
  deleteLocations ({commit}) {
    commit('deleteListLocations', {
      query: '',
      type: 'from',
    });
    commit('deleteListLocations', {
      query: '',
      type: 'to',
    });
  },
  async getFeaturedFlights ({commit}) {
    try {
      const results = await searchAPI.getFeaturedFlights();
      commit('setFeaturedFlights', results.data);
    } catch (e) {
      console.log('FeaturedFlights not found');
    }
  },
  async setSearchHistory ({commit, dispatch}, data) {
    try {
      await historyAPI.setSearchHistory(data);
      commit('setSearchHistory', data);
    } catch (e) {
      return false;
    }
  },
  async setSearchRequest ({commit, dispatch}, data) {
    try {
      const result = await searchAPI.setSearchRequest(data);
      commit('setSearchResults', result);
      commit('setSearchRequest', data);
      if (result.data.length) return true;
    } catch (e) {
      alert('search failed');
    }
  },
  async setSearchRequestCharterFlights ({commit, dispatch}, data) {
    try {
      const result = await searchAPI.setSearchRequestCharterFlights(data);
      commit('setSearchResultsCharterFlights', result);
      commit('setSearchRequestCharterFlights', data);
      if (result.data.length) return true;
    } catch (e) {
      alert('search failed');
    }
  },
  setSearchBookingType ({commit, dispatch}, data) {
    commit('setBookingType', data)
  },
  async setSearchResults ({commit}, data) {
    try {
      const requestData = {
        departureId: data.departureId,
        arrivalId: data.arrivalId,
        departureDate: data.departureDate,
        returnDate: data.returnDate,
        pax: data.pax,
        searchFor: data.searchFor,
        direction: data.direction,
      }

      if (data.priceMax) {
        requestData.priceMax = data.priceMax;
      }

      if (data.aircraftType) {
        requestData.aircraftType = data.aircraftType;
      }

      const result = await searchAPI.setSearchResults(requestData)
      // console.log(result)

      const json = result.data
      // console.log(json)

      // Bad naming conventions previously used... setting full aircraft availability/empty legs here
      commit('setSearchResults', json.charterAircrafts)
      commit('setSearchRequest', data);

      // Bad naming conventions previously used... setting spaces availability/empty legs here
      commit('setSearchResultsCharterFlights', json.charterSpaces)
      commit('setSearchRequestCharterFlights', data);

      // If charters are found return true else false
      if (
        Boolean(json.charterAircrafts.aircraftAvailabilities.length) ||
        Boolean(json.charterAircrafts.emptyLegs.length) ||
        Boolean(json.charterSpaces.aircraftAvailabilities.length) ||
        Boolean(json.charterSpaces.emptyLegs)
      ) {
        return true
      } else {
        return false
      }
    } catch (e) {
      console.log('error in setSearchResults', e)
      return 'Error'
    }
  },
  async createManualRequest ({commit, dispatch}, data) {
    const result = await searchAPI.createManualRequest(data);
    commit('setManuallyReqId', result.data);
    return true
  },
  async setAircraftSearchGallery ({commit}, data) {
    const result = await searchAPI.getAircraftSearchGallery(data);
    commit('setAircraftPopup', result.data);
    if (result) {
      return true;
    }
  },
  async sendPaymentDetails ({commit}, data) {
    const result = await searchAPI.sendPaymentDetails(data);
    if (result.data) {
      return result.data
    }
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
