const state = () => ({
  bids: [
    {
      id: 'bid1',
      photo: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1600460710/FlyJets/2_o6yxqt.jpg',
      from: 'New York city Metropolitane area, New York city, New York, United States',
      to: 'Opa-Locka executive, Miami, Florida, United States)',
      dateFrom: {
        time: '4:30 PM',
        day: 'APR 24, JFK',
      },
      dateTo: {
        time: '6:30 PM',
        day: 'APR 24, JFK',
      },
      model: 'model 3466',
      maxPax: 10,
      timeLeft: '2 h 20 min',
      currentBid: '$20,000.00',
      yourBid: '$15,000.00',
      buyItNow: '$30,000.00',
      oneWay: false,
    },
    {
      id: 'bid2',
      photo: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602196321/FlyJets/plane_oadvwk.jpg',
      from: 'San Jose international airport (San Jose, California)',
      to: 'McCarran international airport (Las Vegas, Nevada)',
      dateFrom: {
        time: '4:30 PM',
        day: 'APR 24, JFK',
      },
      dateTo: {
        time: '6:30 PM',
        day: 'APR 24, JFK',
      },
      model: 'model 3466',
      maxPax: 10,
      timeLeft: '2 h 20 min',
      currentBid: '$20,000.00',
      yourBid: '$15,000.00',
      buyItNow: '$30,000.00',
      oneWay: true,
    },
  ],
  offers: [
    {
      id: 'offer1',
      photo: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1600460710/FlyJets/2_o6yxqt.jpg',
      from: 'New York city Metropolitane area, New York city, New York, United States',
      to: 'Opa-Locka executive, Miami, Florida, United States)',
      dateFrom: {
        time: '4:30 PM',
        day: 'APR 24, JFK',
      },
      dateTo: {
        time: '6:30 PM',
        day: 'APR 24, JFK',
      },
      model: 'model 3466',
      maxPax: 10,
      timeLeft: '2 h 20 min',
      seats: '6 seat (of 10)',
      currentBid: '$20,000.00',
      currentBidDate: 'april 20,2019',
      reservePrice: '$15,000.00',
      buyItNow: '$30,000.00',
      oneWay: true,
    },
  ],
})

export default {
  state,
}
