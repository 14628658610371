import {subscriptionApi} from '@/api/admin/subscriptions';
import {usersAPI} from '@/api/accounts';

const state = () => ({
  subscriptionsList: null,
  paginatedSubscriptions: [],
  paginatedSubscriptionsCount: 0,
  paginatedSubscriptionsPageCount: 0,
})

const mutations = {
  setSubscriptionsList (state, subscriptions) {
    state.subscriptionsList = subscriptions;
  },
  setPaginatedSubscriptions (state, data) {
    const {subscriptionsCount, pageCount, subscriptions } = data
    state.paginatedSubscriptions = subscriptions;
    state.paginatedSubscriptionsCount = subscriptionsCount;
    state.paginatedSubscriptionsPageCount = pageCount;
  },
}

const actions = {
  async fetchEnrichedUserSubscriptions ({state, commit, dispatch, rootGetters}, forceFetch = false) {
    if (!state.subscriptionsList || forceFetch) {
      const response = await subscriptionApi.getSubscriptions();

      await Promise.all([
        dispatch('fetchSubscriptionsStatuses'),
        dispatch('fetchEnrichedSubscriptionsPlans'),
      ]);

      const subs = response?.data?.subscriptions;
      if (subs !== null) {
        const promises = subs.map(async (subscription) => {
          subscription.status = rootGetters.getSubscriptionsStatusById(subscription.statusId);
          subscription.plan = rootGetters.getEnrichedSubscriptionsPlanById(subscription.planId);

          subscription.account = await usersAPI
            .getAccount(subscription.accountId)
            .then(response => response.data?.account);
        });

        await Promise.all(promises);
      }

      commit('setSubscriptionsList', subs);
    }
  },
  async fetchPaginatedUsersSubscriptions ({commit}, data) {
    try {
      const {pageNumber, pageSize} = data
      const result = await subscriptionApi.getPaginatedSubscriptions(pageNumber, pageSize);

      commit('setPaginatedSubscriptions', result.data);
    } catch (e) {
      console.log('e', e);
    }
  },
}

const getters = {
  getEnrichedUserSubscriptions (state) {
    return state.subscriptionsList;
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
