import {instance} from './index';

export const usersAPI = {
  loginUser (data) {
    return instance.post('accounts/login', {
      email: data.email,
      password: data.password,
    })
  },
  checkLogin () {
    return instance.get('accounts/checklogin')
  },
  confirmAccount (email) {
    return instance.post('accounts/confirm', {
      email,
    })
  },
  logoutUser () {
    return instance.get('accounts/logout')
  },
  getAccountsType () {
    return instance.get('accounts/list/1')
  },
  getAccount (id) {
    return instance.get(`accounts/${id}`)
  },
  getAccounts () {
    return instance.get('accounts/get')
  },
  signUp (data) {
    return instance.post('accounts/create', {
      accountType: data.accountType,
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      companyName: data.companyName,
      mobile: data.mobile,
      email: data.email,
      password: data.password,
      timeZone: data.timeZone,
      isFlyCalSignUp: data.isFlyCalSignUp,
    })
  },
  forgotPass (data) {
    return instance.post('accounts/forgotpass', {
      email: data.email,
    })
  },
  resetPass (data) {
    return instance.post('accounts/resetpass', {
      email: data.email,
      password: data.password,
      token: data.token,
    })
  },
  updatePersonalInfo (data) {
    return instance.post('accounts/update', {
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      dateOfBirth: data.dateOfBirth,
      email: data.email,
      mobile: data.mobile,
      address: data.address,
      companyName: data.companyName,
      companyAddress: data.companyAddress,
      companyEmail: data.companyEmail,
      companyPhone: data.companyPhone,
    })
  },
  updatePersonalInfoProvider (data) {
    return instance.post('accounts/update', {
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      dateOfBirth: data.dateOfBirth,
      email: data.email,
      mobile: data.mobile,
      companyName: data.companyName,
      companyAddress: data.companyAddress,
      companyEmail: data.companyEmail,
      companyPhone: data.companyPhone,
    })
  },
  addFamilyMember (data) {
    return instance.post('accounts/addMember', {
      firstName: data.firstName,
      lastName: data.lastName,
      dateOfBirth: data.dateOfBirth,
      email: data.email,
      mobile: data.mobile,
      address: data.address,
    })
  },
  deleteFamilyMember (data) {
    return instance.get(`accounts/deleteMember/${data}`)
  },
  getPaymethods1 () {
    return instance.get('accounts/paymethods/1')
  },
  getPaymethods2 () {
    return instance.get('accounts/paymethods/2')
  },
  createPaymethods (data) {
    return instance.post('accounts/paymethods/creatcc', {
      cvc: data.cvv,
      expiryMonth: data.expiryMonth,
      expiryYear: data.expiryYear,
      number: data.number,
      usedFor: data.usedFor,
    })
  },
  removePaymethod (id) {
    return instance.delete(`accounts/paymethods/remove/${id}`)
  },
  createBankAcc (data) {
    return instance.post('accounts/paymethods/creatbankacc', {
      accountHolderName: data.accountHolderName,
      accountHolderType: data.accountHolderType,
      accountNumber: data.accountNumber,
      routingNumber: data.routingNumber,
      usedFor: data.usedFor,
    })
  },
  bookingAircraftDeposit (data) {
    return instance.post('accounts/paymethods/bookingAircraftDeposit', {
      name: data.name,
      cardNumber: data.cardNumber,
      expMonth: data.expMonth,
      expYear: data.expYear,
      cvc: data.cvc,
      address: data.address,
      reqNo: data.reqNo,
    })
  },
  bookingAircraftDepositAndStore (data) {
    return instance.post('accounts/paymethods/bookingAircraftDepositAndStore', {
      name: data.name,
      cardNumber: data.cardNumber,
      expMonth: data.expMonth,
      expYear: data.expYear,
      cvc: data.cvc,
      address: data.address,
      reqNo: data.reqNo,
    })
  },
  manualRequestDeposit (data) {
    return instance.post('accounts/paymethods/manualRequestDeposit', {
      name: data.name,
      cardNumber: data.cardNumber,
      expMonth: data.expMonth,
      expYear: data.expYear,
      cvc: data.cvc,
      address: data.address,
      reqGuid: data.reqGuid,
    })
  },
  manualRequestDepositAndStore (data) {
    return instance.post('accounts/paymethods/manualRequestDepositAndStore', {
      name: data.name,
      cardNumber: data.cardNumber,
      expMonth: data.expMonth,
      expYear: data.expYear,
      cvc: data.cvc,
      address: data.address,
      reqGuid: data.reqGuid,
    })
  },
  flyCalPayment (data) {
    return instance.post('accounts/paymethods/flyCalPayment', {
      name: data.name,
      cardNumber: data.cardNumber,
      expMonth: data.expMonth,
      expYear: data.expYear,
      cvc: data.cvc,
      address: data.address,
    })
  },
  flyCalPaymentAndStore (data) {
    return instance.post('accounts/paymethods/flyCalPaymentAndStore', {
      name: data.name,
      cardNumber: data.cardNumber,
      expMonth: data.expMonth,
      expYear: data.expYear,
      cvc: data.cvc,
      address: data.address,
    })
  },
  paymentBySavedCard (data) {
    return instance.post('accounts/paymethods/chargeById', data)
  },
  verifyWithOTP (data) {
    return instance.post('accounts/verifyWithOTP', {
      email: data.email,
      otpNum: data.otpNum,
    })
  },
  verify (token) {
    return instance.post('accounts/verify', {
      token: token,
    })
  },
  async createPaymentMethod (data) {
    const result = await instance.post('accounts/payment-methods', data)
    return result.data.paymentMethod
  },
}
