import {flyerAPI} from '@/api/flyer/flights';

const state = () => ({
  currentFlights: [],
  upcomingFlights: [],
  historicalFlightsFlyer: [],
  flyer: [
    {
      id: 0,
      image: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1600460711/FlyJets/6_wnzg9i.jpg',
      routes: {
        from: 'New York city Metropolitane area, New York city, New York, United States',
        to: 'Opa-Locka executive, Miami, Florida, United States',
        dateFrom: '4:30 PM APR 24, JFK',
        dateTo: '6:30 PM APR 24, JFK',
      },
      list: [{
        image: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784189/FlyJets/avatar_zmfk8x.jpg',
        name: 'Mary Jane Watson',
        email: 'marymary@gmail.com',
      }, {
        image: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784186/FlyJets/avatar-2_wf9rvk.jpg',
        name: 'Rebecca Gellar',
        email: 'rebecca55@gmail.com',
      }, {
        image: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784186/FlyJets/avatar-3_dnkplf.jpg',
        name: 'Wilgelm Ron',
        email: '111@gmail.com',
      }],
    },
    {
      id: 1,
      image: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1600460711/FlyJets/6_wnzg9i.jpg',
      routes: {
        from: 'New York city Metropolitane area, New York city, New York, United States',
        to: 'Opa-Locka executive, Miami, Florida, United States',
        dateFrom: '4:30 PM APR 24, JFK',
        dateTo: '6:30 PM APR 24, JFK',
      },
      doubleRoutes: {
        from: 'Opa-Locka executive, Miami, Florida, United States',
        to: 'New York city Metropolitane area, New York city, New York, United States',
        dateFrom: '4:30 PM APR 28, JFK',
        dateTo: '1:30 PM APR 28, JFK',
      },
      list: [{
        image: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784189/FlyJets/avatar_zmfk8x.jpg',
        name: 'Mary Jane Watson',
        email: 'marymary@gmail.com',
      }, {
        image: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784186/FlyJets/avatar-2_wf9rvk.jpg',
        name: 'Rebecca Gellar',
        email: 'rebecca55@gmail.com',
      }, {
        image: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784186/FlyJets/avatar-3_dnkplf.jpg',
        name: 'Wilgelm Ron',
        email: '111@gmail.com',
      }, {
        image: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784186/FlyJets/avatar-2_wf9rvk.jpg',
        name: 'Rebecca Gellar',
        email: 'rebecca55@gmail.com',
      }, {
        image: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784186/FlyJets/avatar-3_dnkplf.jpg',
        name: 'Wilgelm Ron',
        email: '111@gmail.com',
      }],
    },
  ],
  historicalFlights: [
    {
      confirmNo: 'FJ231234565',
      date: '02/03/2019',
      origin: 'New York city Metropolitane area, New York city, New York, United States',
      destination: 'Opa-Locka executive, Miami, Florida, United States',
      price: '1540.00',
    }, {
      confirmNo: 'FJ231234565',
      date: '02/03/2019',
      origin: 'New York city Metropolitane area, New York city, New York, United States',
      destination: 'Opa-Locka executive, Miami, Florida, United States',
      price: '1240.00',
    }, {
      confirmNo: 'FJ231234565',
      date: '02/03/2019',
      origin: 'New York city Metropolitane area, New York city, New York, United States',
      destination: 'Opa-Locka executive, Miami, Florida, United States',
      price: '1240.00',
    }, {
      confirmNo: 'FJ231234565',
      date: '02/03/2019',
      origin: 'New York city Metropolitane area, New York city, New York, United States',
      destination: 'Opa-Locka executive, Miami, Florida, United States',
      price: '1240.00',
    }, {
      confirmNo: 'FJ231234565',
      date: '02/03/2019',
      origin: 'New York city Metropolitane area, New York city, New York, United States',
      destination: 'Opa-Locka executive, Miami, Florida, United States',
      price: '1240.00',
    }, {
      confirmNo: 'FJ231234565',
      date: '02/03/2019',
      origin: 'New York city Metropolitane area, New York city, New York, United States',
      destination: 'Opa-Locka executive, Miami, Florida, United States',
      price: '1240.00',
    }, {
      confirmNo: 'FJ231234565',
      date: '02/03/2019',
      origin: 'New York city Metropolitane area, New York city, New York, United States',
      destination: 'Opa-Locka executive, Miami, Florida, United States',
      price: '1240.00',
    }, {
      confirmNo: 'FJ231234565',
      date: '02/03/2019',
      origin: 'New York city Metropolitane area, New York city, New York, United States',
      destination: 'Opa-Locka executive, Miami, Florida, United States',
      price: '1240.00',
    }, {
      confirmNo: 'FJ231234565',
      date: '02/03/2019',
      origin: 'New York city Metropolitane area, New York city, New York, United States',
      destination: 'Opa-Locka executive, Miami, Florida, United States',
      price: '1240.00',
    }, {
      confirmNo: 'FJ231234565',
      date: '02/03/2019',
      origin: 'New York city Metropolitane area, New York city, New York, United States',
      destination: 'Opa-Locka executive, Miami, Florida, United States',
      price: '1240.00',
    }, {
      confirmNo: 'FJ231234565',
      date: '02/03/2019',
      origin: 'New York city Metropolitane area, New York city, New York, United States',
      destination: 'Opa-Locka executive, Miami, Florida, United States',
      price: '1240.00',
    },
  ],
})

const mutations = {
  setCurrentFlights (state, data) {
    state.currentFlights = data
  },
  setUpcomingFlights (state, data) {
    state.upcomingFlights = data
  },
  setHistoricalFlights (state, data) {
    state.historicalFlights = data
  },
}

const actions = {
  async getCurrentFlights ({commit}) {
    try {
      const result = await flyerAPI.getCurrentFlights()
      commit('setCurrentFlights', result.data)
    } catch (e) {
      console.log('no flights found')
    }
  },
  async getUpcomingFlights ({commit}) {
    try {
      const result = await flyerAPI.getUpcomingFlights()
      commit('setUpcomingFlights', result.data)
    } catch (e) {
      console.log('no upcoming flights found')
    }
  },
  async getHistoricalFlights ({commit}) {
    try {
      const result = await flyerAPI.getHistoricalFlights()
      commit('setHistoricalFlights', result.data.requests)
    } catch (e) {
      console.log('no historical flights found')
    }
  },
}

export default {
  state,
  mutations,
  actions,
}
