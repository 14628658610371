import {instance} from '../index';

export const subscriptionApi = {
  getSubscriptions () {
    return instance.get('/subscriptions?sort=createdon_desc')
  },
  getPaginatedSubscriptions (pageNumber, rowsCount) {
    return instance.get(`/subscriptions/paginatedlist?page=${pageNumber}&size=${rowsCount}`)
  },
  getSubscriptionAnalyticsPaginatedList (pageNumber, rowsCount) {
    return instance.get(`/subscriptions/subscriptionAnalytics?page=${pageNumber}&size=${rowsCount}`)
  },
}
