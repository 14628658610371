<template>
  <div :class="{
      'box box--footer': true,
      'box--footer-report': type === 'report',
      'box--footer-blog': type === 'blog'
    }">
    <div class="box__inner">
      <div class="footer">
        <!-- <div
          v-if="typeOfDashboard === 'flyer' || typeOfDashboard === 'provider'"
          class="footer__switch-wr forMobile"
        >
          <span
            v-if="typeOfDashboard === 'provider'"
            class="footer__switch js-popup"
            @click="$modal.show('LogInPopup')"
          >
            Switch from <b>aircraft<br>provider</b> to <b>flyer</b> login
          </span>
          <span
            v-else-if="typeOfDashboard === 'flyer'"
            class="footer__switch js-popup"
            @click="$modal.show('LogInPopup')"
          >
            Switch from <b>FLYER</b> to <br><b>AIRCRAFT PROVIDER</b> login
          </span>
        </div> -->
        <div class="footer__social">
          <div class="social">
            <a class="social__item" href="https://instagram.com/flyjetsflying/" target="_blank">
              <SvgIcon name="inst"/>
            </a>
            <a class="social__item" href="https://twitter.com/flyjetsflying" target="_blank">
              <SvgIcon name="tw"/>
            </a>
            <a class="social__item" href="https://facebook.com/flyjetsflying/" target="_blank">
              <SvgIcon name="fb"/>
            </a>
          </div>
          <router-link class="footer__link" to="/">FlyJets</router-link>
        </div>

        <div class="footer__nav">
          <div class="nav">
            <div v-for="link of nav" :class="{
              'nav__item': true,
              'nav__item-fly-report': link.class === 'fly-report' && type !== 'blog'
              }"
              :key="link.text">
                <router-link v-if="link.class !== 'fly-report'" class="nav__link"
                  :to="link.href">{{link.text}}</router-link>
                <a v-if="link.class === 'fly-report'" class="nav__link nav__link-fly-report" :href="link.href">{{link.text}}</a>
            </div>
          </div>
        </div>
        <div class="footer__message">
          <!-- <div class="messageCenterButton" @click="openMenu"> -->
          <!-- <div class="messageCenterButton" @click="$modal.show('ComingSoonPopup')">
            <div class="messageCenterButton-icon">
              <SvgIcon name="chat"/>
            </div>
            <div class="messageCenterButton-text">Chat</div>
          </div> -->
        </div>
      </div>
    </div>
<!--    <ComingSoonPopup />-->
  </div>
</template>

<script>
  import './Footer.styl'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  // import ComingSoonPopup from '../common/modals/ComingSoonPopup.vue'

  export default {
    props: ['type', 'typeOfDashboard'],
    name: 'Footer',
    data: () => ({
      nav: [
        {
          href: '/map-search',
          text: 'FlyJets',
        },
        {
          href: '/about',
          text: 'About us',
        },
        {
          href: '/contact',
          text: 'Contact',
        },
        {
          href: '/disclaimer',
          text: 'Disclaimer',
        },
        {
          href: '/privacy-policy',
          text: 'Privacy policy',
        },
        {
          href: '/terms-conditions',
          text: 'Terms',
        },
        {
          href: 'https://news.flyjets.com',
          text: 'The Fly report',
          class: 'fly-report',
        },
      ],
    }),
    methods: {
      openMenu () {
        if (document.querySelector('html').classList.contains('d-mobile')) {
          document.querySelector('.content').style.cssText = 'overflow:initial !important'
        }
        this.$store.commit('toggleMenu', true)
      },
    },
    components: {
    SvgIcon,
    // ComingSoonPopup,
},
  }
</script>
