import {periodsApi} from '@/api/lookups/periods';

const state = () => ({
  periods: null,
  lastFetch: new Date().getTime(),
})

const mutations = {
  setPeriods (state, periods) {
    state.periods = periods
  },
  setLastFetch (lastFetch) {
    state.lastFetch = lastFetch
  },
}

const actions = {
  async fetchPeriods ({state, commit}, forceFetch = false) {
    const now = new Date().getTime();
    const refreshInterval = process.env.VUE_APP_TIME_TO_UPDATE_REFERENCES * 60 * 60 * 1000
    const needUpdateAfterPeriod = state.lastFetch && (now - state.lastFetch > refreshInterval)

    if (!state.periods || forceFetch || needUpdateAfterPeriod) {
      const result = await periodsApi.getPeriods()

      commit('setPeriods', result.data.periods)
      commit('setLastFetch', now)
    }
  },
}

const getters = {
  getPeriods: (state) => () => {
    return state.periods
  },
  getPeriodById: (state) => (id) => {
    if (!state.periods) return null
    return state.periods.filter(c => c.id === id)[0]
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
