import { passengerIdAPI } from '@/api/flyer/passenger-id';

const actions = {
  async addDriverLicense ({commit}, data) {
    try {
      await passengerIdAPI.driversLicense(data)
    } catch (e) {
      console.log('adding failed')
    }
  },
  async addPassport ({commit}, data) {
    try {
      await passengerIdAPI.passport(data)
    } catch (e) {
      console.log('adding failed')
    }
  },
  async addBirthCertificate ({commit}, data) {
    try {
      await passengerIdAPI.birthCertificate(data)
    } catch (e) {
      console.log('adding failed')
    }
  },
}

export default {
  actions,
}
