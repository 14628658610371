import {usersAPI} from '@/api/accounts'
import {subscriptionStatusConstants} from '@/js/constants/subscriptionStatusConstants';
import {subscriptionTypeConstants} from '@/js/constants/subscriptionTypeConstants';
import {subscriptionApi} from '@/api/flyer/subscriptions';

const state = () => ({
  user: {
    id: null,
    firstName: null,
    middleName: null,
    lastName: null,
    mobile: null,
    email: null,
    type: null,
    subscriptions: null,
  },
  login: false,
})

const mutations = {
  setUser (state, {user}) {
    state.user.id = user.data.account.id
    state.user.firstName = user.data.account.firstName
    state.user.middleName = user.data.account.middleName
    state.user.lastName = user.data.account.lastName
    state.user.mobile = user.data.account.mobile
    state.user.email = user.data.account.email
    state.user.type = user.data.account.type
    state.login = true
  },
  logOut (state) {
    state.user.id = null
    state.user.firstName = null
    state.user.middleName = null
    state.user.lastName = null
    state.user.mobile = null
    state.user.email = null
    state.user.type = null
    state.login = false
    state.user.subscriptions = null
  },
  setUserSubscriptions (state, {subscriptions}) {
    state.user.subscriptions = subscriptions
  },
}

const actions = {
  async loginUser ({commit, dispatch}, data) {
    try {
      const respons = await usersAPI.loginUser(data)// set cookie
      if (respons.status === 200) {
        dispatch('checkLogin')
      }
      return true
    } catch (e) {
      alert('Incorrect email or password')
      return false
    }
  },
  async checkLogin ({state, commit, dispatch, rootGetters}) {
    try {
      const user = await usersAPI.checkLogin()
      commit('setUser', {user})
      console.log('checkLogin done');

      try {
        await dispatch('fetchEnrichedSubscriptions')
        commit('setUserSubscriptions', {subscriptions: rootGetters.getEnrichedSubscriptions})
      } catch (e) {
        console.log(e)
      }

      return true
    } catch (e) {
      return false
    }
  },
  async confirmAccount ({commit}, email) {
    try {
      const response = await usersAPI.confirmAccount(email)
      return response
    } catch (e) {
      return false
    }
  },
  async getAccountsType () {
    try {
      await usersAPI.getAccountsType()
    } catch (e) {
      console.log('no accounts founded');
    }
  },
  async getAccounts () {
    try {
      await usersAPI.getAccounts()
    } catch (e) {
      console.log('no accounts founded');
    }
  },
  async deleteAccount () {
    try {
      await usersAPI.deleteAccount()
    } catch (e) {
      return false
    }
  },
  async logoutUser ({commit}) {
    try {
      await usersAPI.logoutUser()
      commit('logOut')
      return true
    } catch (e) {
      return false
    }
  },
  async signUp ({commit, dispatch}, data) {
    try {
      await usersAPI.signUp(data)
      return true
    } catch (e) {
      return false
    }
  },
  async flyCalSignUp ({commit}, data) {
    try {
      console.log(data)
      await usersAPI.signUp(data)
      await usersAPI.confirmAccount(data.email)
      await usersAPI.loginUser(data.email, data.password)
      return true
    } catch (e) {
      return false
    }
  },
  async forgotPass ({commit, dispatch}, data) {
    try {
      await usersAPI.forgotPass(data)
      return true
    } catch (e) {
      return false
    }
  },
  async resetPassword ({commit}, data) {
    try {
      await usersAPI.resetPass(data)
      return true
    } catch (e) {
      return false
    }
  },
  async verify ({commit}, data) {
    try {
      return await usersAPI.verify(data)
    } catch (e) {
      return false
    }
  },
  async setSubscriptionPaymentMethod ({ commit, dispatch, rootGetters }, payload) {
    await subscriptionApi.setSubscriptionsPaymentMethod(payload.subscriptionId, payload.paymentMethodId)
  },
  async setSubscriptionPaymentMethodAndFetch ({ commit, dispatch, rootGetters }, payload) {
    await subscriptionApi.setSubscriptionsPaymentMethod(payload.subscriptionId, payload.paymentMethodId)

    await dispatch('fetchEnrichedSubscriptions', true)
    commit('setUserSubscriptions', { subscriptions: rootGetters.getEnrichedSubscriptions })
  },
  async fetchSubscriptionsAndUpdateInUser ({ commit, dispatch, rootGetters }) {
    await dispatch('fetchEnrichedSubscriptions', true)
    commit('setUserSubscriptions', { subscriptions: rootGetters.getEnrichedSubscriptions })
  },
  async fetchSubscriptions ({ dispatch, rootGetters }) {
    await dispatch('fetchEnrichedSubscriptions', true)
    return rootGetters.getEnrichedSubscriptions
  },
  async activateSubscriptionAndFetch ({commit, dispatch, rootGetters}, payload) {
    await subscriptionApi.activateSubscription(payload.subscriptionId)

    await dispatch('fetchEnrichedSubscriptions', true)
    commit('setUserSubscriptions', { subscriptions: rootGetters.getEnrichedSubscriptions })
  },
  async activateSubscription ({commit, dispatch, rootGetters}, payload) {
    await subscriptionApi.activateSubscription(payload.subscriptionId)
  },
  async upgradeSubscriptionAndFetch ({commit, dispatch, rootGetters}, payload) {
    const result = await subscriptionApi.upgradeSubscription(payload.subscriptionId, payload.planId)

    await dispatch('fetchEnrichedSubscriptions', true)
    commit('setUserSubscriptions', { subscriptions: rootGetters.getEnrichedSubscriptions })

    return result.data.subscription
  },
  async upgradeSubscription ({commit, dispatch, rootGetters}, payload) {
    const result = await subscriptionApi.upgradeSubscription(payload.subscriptionId, payload.planId)

    return result.data.subscription
  },
  async createSubscriptionAndFetch ({commit, dispatch, rootGetters}, payload) {
    const result = await subscriptionApi.createSubscription(payload.planId)

    await dispatch('fetchEnrichedSubscriptions', true)
    commit('setUserSubscriptions', { subscriptions: rootGetters.getEnrichedSubscriptions })

    return result.data.subscription
  },
  async createSubscription ({commit, dispatch, rootGetters}, payload) {
    const result = await subscriptionApi.createSubscription(payload.planId)

    return result.data.subscription
  },
  async cancelSubscriptionAndFetch ({commit, dispatch, rootGetters}, subscriptionId) {
    const result = await subscriptionApi.cancelSubscription(subscriptionId)

    await dispatch('fetchEnrichedSubscriptions', true)
    commit('setUserSubscriptions', { subscriptions: rootGetters.getEnrichedSubscriptions })

    return result.data.subscription
  },
  async renewSubscriptionAndFetch ({commit, dispatch, rootGetters}, subscriptionId) {
    const result = await subscriptionApi.renewSubscription(subscriptionId)

    await dispatch('fetchEnrichedSubscriptions', true)
    commit('setUserSubscriptions', { subscriptions: rootGetters.getEnrichedSubscriptions })

    return result.data.subscription
  },
}

const getters = {
  getActiveUserSubscriptions (state) {
    if (!state.user.subscriptions) return []
    return state.user.subscriptions.filter(
      s => s.status.code === subscriptionStatusConstants.active.code ||
      s.status.code === subscriptionStatusConstants.canceled.code)
  },
  getInactiveUserSubscriptions (state) {
    if (!state.user.subscriptions) return []
    const result = state.user.subscriptions.filter(
      s => s.status.code !== subscriptionStatusConstants.active.code &&
        s.status.code !== subscriptionStatusConstants.canceled.code &&
        s.status.code !== subscriptionStatusConstants.abandoned.code)
    return result
  },
  getNameActiveSubscriptionPlanName (state) {
    if (!state.user.subscriptions) return null
    return state.user.subscriptions?.filter(
      s => s.plan.type.code === subscriptionTypeConstants.flyjetsPlus.code &&
        (s.status.code === subscriptionStatusConstants.active.code ||
          s.status.code === subscriptionStatusConstants.canceled.code))[0]?.plan.name
  },
  getSubscriptionById: (state) => (id) => {
    if (!state.user.subscriptions) return null
    return state.user.subscriptions.find(s => s.id === id)
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
