const state = () => ({
  flights: {
    current: [
      {
        id: 'flights1',
        flightNumber: 'FJ007543',
        bookingDate: '11.06.2018 at 07.30.00EST',
        departureDate: '11.06.2018 at 07.30.00EST',
        bookingNumber: '566HG456',
        takeOff: 'San Jose international airport (San Jose, California)',
        arrivalDate: '11.06.2018 at 07.30.00EST',
        bookingName: 'John Doe',
        land: 'San Jose international airport (San Jose, California)',
      },
      {
        id: 'flights2',
        flightNumber: 'FJ007543',
        bookingDate: '11.06.2018 at 07.30.00EST',
        departureDate: '11.06.2018 at 07.30.00EST',
        bookingNumber: '566HG456',
        takeOff: 'San Jose international airport (San Jose, California)',
        arrivalDate: '11.06.2018 at 07.30.00EST',
        bookingName: 'John Doe',
        land: 'San Jose international airport (San Jose, California)',
      },
      {
        id: 'flights3',
        flightNumber: 'FJ007543',
        bookingDate: '11.06.2018 at 07.30.00EST',
        departureDate: '11.06.2018 at 07.30.00EST',
        bookingNumber: '566HG456',
        takeOff: 'San Jose international airport (San Jose, California)',
        arrivalDate: '11.06.2018 at 07.30.00EST',
        bookingName: 'John Doe',
        land: 'San Jose international airport (San Jose, California)',
      },
      {
        id: 'flights4',
        flightNumber: 'FJ007543',
        bookingDate: '11.06.2018 at 07.30.00EST',
        departureDate: '11.06.2018 at 07.30.00EST',
        bookingNumber: '566HG456',
        takeOff: 'San Jose international airport (San Jose, California)',
        arrivalDate: '11.06.2018 at 07.30.00EST',
        bookingName: 'John Doe',
        land: 'San Jose international airport (San Jose, California)',
      },
    ],
    historical: [
      {
        id: 'flights5',
        flightNumber: 'FJ007543',
        bookingDate: '21.06.2018 at 07.30.00EST',
        departureDate: '21.06.2018 at 07.30.00EST',
        bookingNumber: '566HG456',
        takeOff: 'San Jose international airport (San Jose, California)',
        arrivalDate: '21.06.2018 at 07.30.00EST',
        bookingName: 'John Doe',
        land: 'San Jose international airport (San Jose, California)',
      },
      {
        id: 'flights6',
        flightNumber: 'FJ007543',
        bookingDate: '21.06.2018 at 07.30.00EST',
        departureDate: '21.06.2018 at 07.30.00EST',
        bookingNumber: '566HG456',
        takeOff: 'San Jose international airport (San Jose, California)',
        arrivalDate: '21.06.2018 at 07.30.00EST',
        bookingName: 'John Doe',
        land: 'San Jose international airport (San Jose, California)',
      },
      {
        id: 'flights7',
        flightNumber: 'FJ007543',
        bookingDate: '21.06.2018 at 07.30.00EST',
        departureDate: '21.06.2018 at 07.30.00EST',
        bookingNumber: '566HG456',
        takeOff: 'San Jose international airport (San Jose, California)',
        arrivalDate: '21.06.2018 at 07.30.00EST',
        bookingName: 'John Doe',
        land: 'San Jose international airport (San Jose, California)',
      },
      {
        id: 'flights8',
        flightNumber: 'FJ007543',
        bookingDate: '21.06.2018 at 07.30.00EST',
        departureDate: '21.06.2018 at 07.30.00EST',
        bookingNumber: '566HG456',
        takeOff: 'San Jose international airport (San Jose, California)',
        arrivalDate: '21.06.2018 at 07.30.00EST',
        bookingName: 'John Doe',
        land: 'San Jose international airport (San Jose, California)',
      },
    ],
    upcoming: [
      {
        id: 'flights9',
        flightNumber: 'FJ007543',
        bookingDate: '31.06.2018 at 07.30.00EST',
        departureDate: '31.06.2018 at 07.30.00EST',
        bookingNumber: '566HG456',
        takeOff: 'San Jose international airport (San Jose, California)',
        arrivalDate: '31.06.2018 at 07.30.00EST',
        bookingName: 'John Doe',
        land: 'San Jose international airport (San Jose, California)',
      },
      {
        id: 'flights10',
        flightNumber: 'FJ007543',
        bookingDate: '31.06.2018 at 07.30.00EST',
        departureDate: '31.06.2018 at 07.30.00EST',
        bookingNumber: '566HG456',
        takeOff: 'San Jose international airport (San Jose, California)',
        arrivalDate: '31.06.2018 at 07.30.00EST',
        bookingName: 'John Doe',
        land: 'San Jose international airport (San Jose, California)',
      },
      {
        id: 'flights11',
        flightNumber: 'FJ007543',
        bookingDate: '31.06.2018 at 07.30.00EST',
        departureDate: '31.06.2018 at 07.30.00EST',
        bookingNumber: '566HG456',
        takeOff: 'San Jose international airport (San Jose, California)',
        arrivalDate: '31.06.2018 at 07.30.00EST',
        bookingName: 'John Doe',
        land: 'San Jose international airport (San Jose, California)',
      },
      {
        id: 'flights12',
        flightNumber: 'FJ007543',
        bookingDate: '31.06.2018 at 07.30.00EST',
        departureDate: '31.06.2018 at 07.30.00EST',
        bookingNumber: '566HG456',
        takeOff: 'San Jose international airport (San Jose, California)',
        arrivalDate: '31.06.2018 at 07.30.00EST',
        bookingName: 'John Doe',
        land: 'San Jose international airport (San Jose, California)',
      },
    ],
  },
  flightDetail: {
    flightsConfNumber: 'FJ007543',
    aircraftType: 'Boeing 707',
    name: 'Jessica Fisher',
    bookingNumber: '444455k',
    flyerNumber: 'FF123LJ',
    bookingDate: '03/03/19',
    origin: 'San Jose international airport (San Jose, California)',
    destination: 'McCarran international airport (Las Vegas, Nevada)',
    oneWay: true,
    flightDateTime: {
      date: '04/03/19',
      est: '09:00:00',
      gmt: '13:00:00',
    },
    depart: {
      title: 'San Jose international airport (San Jose, California)',
      date: '04/03/19',
      est: '09:00:00',
      gmt: '13:00:00',
    },
    land: {
      title: 'San Jose international airport (San Jose, California)',
      date: '04/03/19',
      est: '09:00:00',
      gmt: '13:00:00',
    },
    tripTime: {
      outbound: '5H 30MIN',
      inbound: '5H 30MIN',
    },
    operator: 'FlyJets',
    flyJetsPerson: [
      {
        id: 'person1',
        photo: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784189/FlyJets/avatar_zmfk8x.jpg',
        name: 'Mary Jane Watson',
        phone: '8-666-777-88-34',
      },
    ],
    passengers: [
      {
        id: 'person2',
        photo: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784189/FlyJets/avatar_zmfk8x.jpg',
        name: 'Anna Li',
        phone: '8-666-777-88-34',
      },
      {
        id: 'person3',
        photo: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602199687/FlyJets/avatar_s1o4ii.jpg',
        name: 'John Doe',
        phone: '8-666-777-88-34',
      },
      {
        id: 'person4',
        photo: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784186/FlyJets/avatar-2_wf9rvk.jpg',
        name: 'Mary Jane Watson',
        phone: '8-666-777-88-34',
      },
    ],
  },
})

export default {
  state,
}
