import { instance } from '@/api';

export const bidsAPI = {
  getAdminBidsPage (historical, pageNumber, rowsCount) {
    return instance.get(`auction/bids/actualPaginatedlist?historical=${historical}&page=${pageNumber}&size=${rowsCount}`);
  },
  getNonActualBidList (flightId) {
    return instance.get(`auction/bids/nonActualBidsByFlightId?flightId=${flightId}`);
  },
  getFlyersBidList (historical) {
    return instance.get(`auction/bids/getFlyersBids?historical=${historical}`);
  },
  getProviderBidList (accepted) {
    return instance.get(`auction/bids/getProvidersBids?accepted=${accepted}`);
  },
  cancelBid (id) {
    return instance.patch(`auction/bids/cancel?Id=${id}`)
  },
  unacceptBid (id) {
    return instance.patch(`auction/bids/unacceptBid?Id=${id}`)
  },
  providerAcceptBid (id) {
    return instance.patch(`auction/bids/acceptBid?Id=${id}`)
  },
  exportBidList (isHistorical) {
    return instance.get(`reports/auctionBids/${isHistorical ? 'historical' : ''}`)
  },
}
