const state = () => ({
  list: [
    {
      id: 'reward1',
      name: 'Jane Doe',
      flyRewards: 500,
      rewardsHistory: [
        {
          id: 1,
          value: 150,
          date: '04/05/19 06:30 PM',
          cancelled: false,
        }, {
          id: 2,
          value: 50,
          date: '12/03/19 11:30 PM',
          cancelled: true,
        }, {
          id: 3,
          value: 150,
          date: '04/05/19 06:30 PM',
          cancelled: true,
        },
      ],
    }, {
      id: 'reward2',
      name: 'Oxana Ivanova',
      flyRewards: 150,
      rewardsHistory: [
        {
          id: 1,
          value: 150,
          date: '04/05/19 06:30 PM',
          cancelled: false,
        }, {
          id: 2,
          value: 50,
          date: '12/03/19 11:30 PM',
          cancelled: true,
        }, {
          id: 3,
          value: 150,
          date: '04/05/19 06:30 PM',
          cancelled: true,
        },
      ],
    }, {
      id: 'reward3',
      name: 'Inna Raily',
      flyRewards: 400,
      rewardsHistory: [
        {
          id: 1,
          value: 150,
          date: '04/05/19 06:30 PM',
          cancelled: false,
        }, {
          id: 2,
          value: 50,
          date: '12/03/19 11:30 PM',
          cancelled: true,
        }, {
          id: 3,
          value: 150,
          date: '04/05/19 06:30 PM',
          cancelled: true,
        },
      ],
    }, {
      id: 'reward4',
      name: 'Ann Clarke',
      flyRewards: 800,
      rewardsHistory: [
        {
          id: 1,
          value: 150,
          date: '04/05/19 06:30 PM',
          cancelled: false,
        }, {
          id: 2,
          value: 50,
          date: '12/03/19 11:30 PM',
          cancelled: true,
        }, {
          id: 3,
          value: 150,
          date: '04/05/19 06:30 PM',
          cancelled: true,
        },
      ],
    },
  ],
  currentReward: {
    userId: null,
    historyId: null,
  },
})

const mutations = {
  insertCurrentReward (state, data) {
    state.currentReward = {
      userId: data.userId,
      historyId: data.historyId,
    }
  },
  cancelItemReward (state) {
    state.list = state.list.map(item => {
      if (item.id === state.currentReward.userId) {
        return {
          ...item,
          rewardsHistory: item.rewardsHistory.filter(elem => elem.id !== state.currentReward.historyId),
        }
      }
      return item
    })
  },
}

export default {
  state,
  mutations,
}
