import {bidsAPI} from '@/api/bids';

const state = () => ({
  bidList: null,
  historicalBids: [],
})

const mutations = {
  setFlyerBidList (state, {data, historical}) {
    const {value} = data.item

    if (historical) {
      state.historicalBids = value;
    } else {
      state.bidList = value;
    }
  },
}
const actions = {
  async getFlyerBidList ({commit}, data) {
    const {historical} = data;

    try {
      const result = await bidsAPI.getFlyersBidList(historical);
      commit('setFlyerBidList', {data: result.data, historical});
    } catch (e) {
      console.log('e', e)
    }
  },
}
export default {
  state,
  mutations,
  actions,
}
