import { usersAPI } from '@/api/accounts';

const state = () => ({
  paymethods1: null,
  paymethods2: null,
})

const mutations = {
  setPaymethods1 (state, data) {
    state.paymethods1 = data
  },
  setPaymethods2 (state, data) {
    state.paymethods2 = data
  },
}

const actions = {
  async getPaymethods ({state, commit}, forceFetch = false) {
    if (forceFetch || !state.paymethods1 || !state.paymethods2) {
      try {
        const result = await usersAPI.getPaymethods1()
        const result2 = await usersAPI.getPaymethods2()
        commit('setPaymethods1', result.data)
        commit('setPaymethods2', result2.data)
        return [...result.data.creditCards, ...result2.data.creditCards]
      } catch (e) {
        console.log('map not added')
      }
    }
  },
  async addCard ({commit}, data) {
    try {
      await usersAPI.createPaymethods(data)
      return true
    } catch (e) {
      console.log('card not added')
      return false
    }
  },
  async removeCard ({commit}, id) {
    try {
      await usersAPI.removePaymethod(id)
      return true
    } catch (e) {
      console.log('card not removed')
      return false
    }
  },
  async addBank ({commit}, data) {
    try {
      await usersAPI.createBankAcc(data)
      return true
    } catch (e) {
      console.log('bank not added')
      return false
    }
  },
}

const getters = {
  getPaymentMethodById: (state) => (id) => {
    if (!state.paymethods1 && !state.paymethods2) return null
    return [...state.paymethods1.creditCards,
      ...state.paymethods1.bankAccounts,
      ...state.paymethods2.creditCards,
      ...state.paymethods2.bankAccounts].find(p => p.id === id)
  },
  getPaymentMethods: (state) => {
    return [...state.paymethods1.creditCards,
    ...state.paymethods1.bankAccounts,
    ...state.paymethods2.creditCards,
    ...state.paymethods2.bankAccounts]
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
