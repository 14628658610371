import { bookingAPI } from '@/api/booking';

const state = () => ({
  list: [
    // { name: 'John Doe', number: 'FR201920101', date: '02/03/2019'},
    // { name: 'John Doe', number: 'FR201920101', date: '02/03/2019'},
    // { name: 'John Doe', number: 'FR201920101', date: '02/03/2019'},
    // { name: 'John Doe', number: 'FR201920101', date: '02/03/2019'},
    // { name: 'John Doe', number: 'FR201920101', date: '02/03/2019'},
    // { name: 'John Doe', number: 'FR201920101', date: '02/03/2019'},
    // { name: 'John Doe', number: 'FR201920101', date: '02/03/2019'},
    // { name: 'John Doe', number: 'FR201920101', date: '02/03/2019'},
    // { name: 'John Doe', number: 'FR201920101', date: '02/03/2019'},
    // { name: 'John Doe', number: 'FR201920101', date: '02/03/2019'},
    // { name: 'John Doe', number: 'FR201920101', date: '02/03/2019'},
    // { name: 'John Doe', number: 'FR201920101', date: '02/03/2019'},
    // { name: 'John Doe', number: 'FR201920101', date: '02/03/2019'},
    // { name: 'John Doe', number: 'FR201920101', date: '02/03/2019'},
    // { name: 'John Doe', number: 'FR201920101', date: '02/03/2019'}
  ],
  offsetPurchases: [],
  bookingConfirmation: [
    {
      id: 'booking1',
      from: 'New York City',
      to: 'San Francisco',
      startDate: 'December 31, 2018',
      endDate: 'January 7, 2019',
      confirmation: false,
    },
    {
      id: 'booking2',
      from: 'Republic Airport (KFRG)',
      to: 'Orlando International Airport (KMCO)',
      startDate: 'January 11, 2019',
      endDate: 'January 17, 2019',
      confirmation: false,
    },
  ],
  currentBooking: null,
  manualRequests: [],
  historicalManualRequests: [],
  reservations: [],
  confirmedReservations: [],
  bookings: [],
  confirmedBookings: [],
  bookingsList: [],
  bookingDetails: {},
})

const mutations = {
  addConfirmation (state, id) {
    state.bookingConfirmation = state.bookingConfirmation.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          confirmation: true,
        }
      }
      return item
    })
  },
  insertCurrentBooking (state, id) {
    state.currentBooking = id
  },
  removeItemBooking (state) {
    state.bookingConfirmation = state.bookingConfirmation.filter(item => item.id !== state.currentBooking)
  },
  async setManualRequests (state) {
    const result = await bookingAPI.getManualRequests()
    state.manualRequests = result.data
  },
  async setHistoricalManualRequests (state) {
    const result = await bookingAPI.getHistoricalManualRequests()
    state.historicalManualRequests = result.data
  },
  async setOffsetPurchases (state) {
    const result = await bookingAPI.getOffsetPurchases()
    state.offsetPurchases = result.data
  },
  async setReservations (state) {
    const result = await bookingAPI.getReservations()
    state.bookingsList = result.data
  },
  async setConfirmedReservations (state) {
    const result = await bookingAPI.getConfirmedReservations()
    state.bookingsList = result.data
  },
  async setBookings (state) {
    const result = await bookingAPI.getBookings()
    state.bookingsList = result.data
  },
  async setConfirmedBookings (state) {
    const result = await bookingAPI.getConfirmedBookings()
    state.bookingsList = result.data
  },
  setBookingDetails (state, data) {
    state.bookingDetails = data
  },
  setManualRequestsList (state, data) {
    state.manualRequests = data.requestsView
    state.manualRequestsPageCount = data.pageCount
    state.manualRequestsListCount = data.requestsCount
  },
  setHistoricalManualRequestsList (state, data) {
    state.historicalManualRequests = data.requestsView
    state.historicalManualRequestsPageCount = data.pageCount
    state.historicalManualRequestsListCount = data.requestsCount
  },
}

const actions = {
  async getManualRequests ({commit}) {
    try {
      const result = await bookingAPI.getManualRequests()
      commit('setManualRequests', result.data)
    } catch (e) {
      console.log('no requests founded')
    }
  },
  async getHistoricalManualRequests ({commit}) {
    try {
     const result = await bookingAPI.getHistoricalManualRequests()
     commit('setHistoricalManualRequests', result.data)
    } catch (e) {
      console.log('no historical requests founded')
    }
  },
  async getBookingDetails ({commit}, id) {
    try {
      const result = await bookingAPI.getBookingDetails(id)
      commit('setBookingDetails', result.data)
    } catch (e) {
      console.log('no bookingg')
    }
  },
  async getManualRequestsList ({commit}, data) {
    try {
      const result = await bookingAPI.getManualRequestsPage(true, data.pageNumber, data.pageSize);
      commit('setManualRequestsList', result.data);
    } catch (e) {
      console.log('e', e)
    }
  },
  async getHistoricalManualRequestsList ({commit}, data) {
    try {
      const result = await bookingAPI.getManualRequestsPage(false, data.pageNumber, data.pageSize);
      commit('setHistoricalManualRequestsList', result.data);
    } catch (e) {
      console.log('e', e)
    }
  },
}

export default {
  state,
  mutations,
  actions,
}
