<template>
  <div class="theme__wrapper">
    <span class="theme__icon" v-if="iconLeft"><SvgIcon :name="iconLeft"/></span>
    <label :class="{'switch': true, [`switch--${size}`]: true}">
      <input type="checkbox" :checked="isChecked" @click="changeMode">
      <span class="slider"></span>
    </label>
    <span class="theme__icon" v-if="iconRight"><SvgIcon :name="iconRight"/></span>
  </div>
</template>

<script>
  import './Toggle.styl'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

  export default {
    name: 'Toggle',
    props: ['iconLeft', 'iconRight', 'size'],
    computed: {
      isChecked () {
        return this.$store.state.view.theme === 'dark'
      },
    },
    methods: {
      changeMode (value) {
        this.$store.dispatch('setDarkMode', value.target.checked);
      },
    },
    components: {
      SvgIcon,
    },
  }
</script>
