<template>
  <modal name="SignUpPopup" transition="pop-out" @before-open="beforeOpen" @opened="clickCountryCode" @closed="$v.$reset()">
    <div class="formPopup formPopup--signUp" :class="{'removeBackgroundImg': removeBackground }" id="signUpPopup">
      <div class="formPopup__inner formPopup__innerSingUp">
        <div class="formPopup__left">
          <div class="formPopup__decor"></div>
        </div>
        <div class="formPopup__right js-tabBox">
          <div class="formPopup__header">
            <div class="formPopup__title-wr">
              <div class="formPopup__subtitle">Hello!</div>
              <div class="formPopup__title">Sign up</div>
            </div>
            <div class="formPopup__tabNav">
              <div class="formPopup__tabNav-item js-tabSignItem" @click="tabActivate">Flyer</div>
              <div class="formPopup__tabNav-item js-tabSignItem" @click="tabActivate">Aircraft provider</div>
            </div>
          </div>
          <div class="formPopup__tabContent">
            <div class="formPopup__tabContent-item js-tabSignContent active" data-id="flyer">
              <form
                class="form form--signUpForm form--signUpForm-mob js-ajaxForm"
                :class="{'loading' : loading}"
                data-href="#"
                data-action="signUpFlyer"
                id="signUpFlyerForm"
                @submit.prevent="submitHandlerFlyer">
                <Spinner/>
                <div class="form__labelGroup">
                  <label class="form__label form__label--text">
                    <div class="form__caption">Name</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="text"
                        v-model="firstname"
                        autocomplete="off"
                        placeholder="First"
                        :disabled="loading"
                      />
                    </div>
                    <span
                      v-if="$v.firstname.$dirty && !$v.firstname.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="text"
                        v-model="middlename"
                        autocomplete="off"
                        placeholder="Middle"
                        :disabled="loading"
                      />
                    </div>
<!--                    <span-->
<!--                      v-if="$v.middlename.$dirty && !$v.middlename.required"-->
<!--                      class="form__field-invalid form__field-invalid&#45;&#45;right-side"-->
<!--                    >-->
<!--                      This field is required-->
<!--                    </span>-->
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="text"
                        v-model="lastname"
                        autocomplete="off"
                        placeholder="Last"
                        :disabled="loading"
                      />
                    </div>
                    <span
                      v-if="$v.lastname.$dirty && !$v.lastname.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__caption">Email</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="email"
                        v-model="email"
                        autocomplete="off"
                        placeholder="Email address"
                        :disabled="loading"
                      />
                    </div>
                    <span
                      v-if="$v.email.$dirty && !$v.email.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                    <span
                      v-if="$v.email.$dirty && !$v.email.email"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      Enter a valid Email
                    </span>
                  </label>
                  <label class="form__label form__label--text phone">
                    <div class="form__caption">Phone</div>
                    <div class="form__field-wrapper">
                      <VuePhoneNumberInput v-model="phone" clearable @update="updatePhone" :dark="true" :disabled="loading" default-country-code="US" />
<!--                      <input-->
<!--                        class="form__field"-->
<!--                        type="text"-->
<!--                        v-model="phone"-->
<!--                        autocomplete="off"-->
<!--                        v-mask="'+1 (###) ###-##-##'"-->
<!--                        placeholder="+1 (___) ___-__-__"-->
<!--                        :disabled="loading"-->
<!--                      />-->
                    </div>
                    <span
                      v-if="$v.phone.$dirty && !$v.phone.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__caption">Password</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="password"
                        v-model="password"
                        autocomplete="new-password"
                        placeholder="Enter your password"
                        :disabled="loading"
                      />
                    </div>
                    <span
                      v-if="$v.password.$dirty && !$v.password.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__caption">Confirm password</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="password"
                        v-model="confirmPassword"
                        autocomplete="new-password"
                        placeholder="Enter your password"
                        :disabled="loading"
                      />
                    </div>
                    <span
                      v-if="$v.confirmPassword.$dirty && !$v.confirmPassword.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                </div>
                <div class="form__label form__label--button">
                  <button class="form__button form__button" :disabled="loading"><span class="text">Complete</span>
                  </button>
                </div>
              </form>
            </div>

            <div class="formPopup__tabContent-item js-tabSignContent" data-id="aircraftProvider">
              <form
                class="form form--signUpForm form--signUpForm-mob js-ajaxForm"
                :class="{'loading' : loading}"
                data-href="#"
                data-action="signUpAircraftProvider"
                id="signUpAircraftProviderForm"
                @submit.prevent="submitHandlerProvider">
                <Spinner/>
                <div class="form__labelGroup">
                  <label class="form__label form__label--text">
                    <div class="form__caption">Name</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="text"
                        v-model="firstnameProvider"
                        autocomplete="off"
                        placeholder="First"
                        :disabled="loading"
                      />
                    </div>
                    <span
                      v-if="$v.firstnameProvider.$dirty && !$v.firstnameProvider.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="text"
                        v-model="middlenameProvider"
                        autocomplete="off"
                        placeholder="Middle"
                        :disabled="loading"
                      />
                    </div>
<!--                    <span-->
<!--                      v-if="$v.middlename.$dirty && !$v.middlename.required"-->
<!--                      class="form__field-invalid form__field-invalid&#45;&#45;right-side"-->
<!--                    >-->
<!--                      This field is required-->
<!--                    </span>-->
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="text"
                        v-model="lastnameProvider"
                        autocomplete="off"
                        placeholder="Last"
                        :disabled="loading"
                      />
                    </div>
                    <span
                      v-if="$v.lastnameProvider.$dirty && !$v.lastnameProvider.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__caption">Company</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="text"
                        v-model="companyProvider"
                        autocomplete="off"
                        placeholder="Company name"
                        :disabled="loading"
                      />
                    </div>
                    <span
                      v-if="$v.companyProvider.$dirty && !$v.companyProvider.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="form__label form__label--text phone">
                    <div class="form__caption">Phone</div>
                    <div class="form__field-wrapper">
                      <VuePhoneNumberInput v-model="phoneProvider" aria-placeholder="" clearable @update="updateProviderPhone"  :dark="true" :disabled="loading" default-country-code="US" />
<!--                      <input-->
<!--                        class="form__field"-->
<!--                        type="text"-->
<!--                        v-model="phoneProvider"-->
<!--                        autocomplete="off"-->
<!--                        placeholder="+1 (___) ___-__-__"-->
<!--                        :disabled="loading"-->
<!--                      />-->
                    </div>
                    <span
                      v-if="$v.phoneProvider.$dirty && !$v.phoneProvider.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__caption">Email</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="email"
                        v-model="emailProvider"
                        autocomplete="off"
                        placeholder="Email address"
                        :disabled="loading"
                      />
                    </div>
                    <span
                      v-if="$v.emailProvider.$dirty && !$v.emailProvider.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                    <span
                      v-if="$v.emailProvider.$dirty && !$v.emailProvider.email"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      Enter a valid Email
                    </span>
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__caption">Confirm password</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="password"
                        v-model="confirmPasswordProvider"
                        autocomplete="new-password"
                        placeholder="Enter your password"
                        :disabled="loading"
                      />
                    </div>
                    <span
                      v-if="$v.confirmPasswordProvider.$dirty && !$v.confirmPasswordProvider.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__caption">Password</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="password"
                        v-model="passwordProvider"
                        autocomplete="new-password"
                        placeholder="Enter your password"
                        :disabled="loading"
                      />
                    </div>
                    <span
                      v-if="$v.passwordProvider.$dirty && !$v.passwordProvider.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
<!--                  <label class="form__label form__label&#45;&#45;text">-->
<!--                    &lt;!&ndash; <div class="form__info"> Message that FlyJets are checking providers. Here you can specify how many-->
<!--                      days it will take-->
<!--                    </div> &ndash;&gt;-->
<!--                  </label>-->
                </div>
                <div class="form__label form__label--button">
                  <button class="form__button" :disabled="loading"><span class="text">Complete</span></button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="modal__close" @click="$modal.hide('SignUpPopup')">
        <SvgIcon name="close-white"/>
      </button>
    </div>
  </modal>
</template>

<script>
  import Spinner from '@/components/common/Spinner/Spinner'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import { Tabs } from '@/js/plugins/tabs'
  import { email, required } from 'vuelidate/lib/validators'
  import VuePhoneNumberInput from 'vue-phone-number-input';
  import 'vue-phone-number-input/dist/vue-phone-number-input.css';
  import './SingUpPopup.styl'

  export default {
    name: 'SignUpPopup',
    data: () => ({
      tabs: new Tabs('.js-tabSignItem', '.js-tabSignContent'),
      firstname: '',
      middlename: '',
      lastname: '',
      phone: '',
      formattedPhone: '',
      email: '',
      password: '',
      confirmPassword: '',
      firstnameProvider: '',
      middlenameProvider: '',
      lastnameProvider: '',
      companyProvider: '',
      phoneProvider: '',
      formattedPhoneProvider: '',
      emailProvider: '',
      passwordProvider: '',
      confirmPasswordProvider: '',
      loading: false,
      removeBackground: false,
    }),
    validations: {
      firstname: { required },
      lastname: { required },
      phone: { required },
      email: {
        email,
        required,
      },
      password: { required },
      confirmPassword: { required },
      firstnameProvider: { required },
      lastnameProvider: { required },
      companyProvider: { required },
      phoneProvider: { required },
      emailProvider: {
        email,
        required,
      },
      passwordProvider: { required },
      confirmPasswordProvider: { required },
    },
    methods: {
      beforeOpen () {
        window.location.href.includes('/theflyreport') || window.location.href.includes('/article') ? this.removeBackground = true : this.removeBackground = false
        setTimeout(() => {
          this.tabs.addActiveForMouted()
           document.querySelector('.country-selector__list').style.opacity = 0
          document.querySelectorAll('.country-selector__input').forEach(input => input.click())
        }, 0)
      },
      clickCountryCode () {
        setTimeout(() => {
          document.querySelectorAll('.country-selector__input').forEach(input => input.click())
          document.querySelectorAll('.form__label--text')[0].click()
        }, 0)
        setTimeout(() => {
          document.querySelector('.country-selector__list').style.opacity = 1
        }, 1000)
      },
      tabActivate (event) {
        // eslint-disable-next-line no-return-assign
        document.querySelectorAll('.country-selector__list').forEach(list => list.style.opacity = 0)
        this.tabs.addActiveForClick(event.target)
        this.firstname = ''
        this.middlename = ''
        this.lastname = ''
        this.phone = ''
        this.email = ''
        this.password = ''
        this.confirmPassword = ''
        this.firstnameProvider = ''
        this.middlenameProvider = ''
        this.lastnameProvider = ''
        this.companyProvider = ''
        this.phoneProvider = ''
        this.emailProvider = ''
        this.passwordProvider = ''
        this.confirmPasswordProvider = ''
        this.$v.$reset()
        setTimeout(() => {
          document.querySelectorAll('.country-selector__input').forEach(input => input.click())
        }, 0)
        setTimeout(() => {
          // eslint-disable-next-line no-return-assign
          document.querySelectorAll('.country-selector__list').forEach(list => list.style.opacity = 1)
        }, 1000)
      },
      async submitHandlerFlyer () {
        if (this.$v.firstname.$invalid ||
          this.$v.phone.$invalid ||
          this.$v.email.$invalid ||
          this.$v.password.$invalid ||
          this.$v.confirmPassword.$invalid ||
          this.password !== this.confirmPassword
        ) {
          this.$v.$touch()
          return false
        }

        const data = {
          accountType: 1,
          firstName: this.firstname,
          middleName: this.middlename,
          lastName: this.lastname,
          companyName: null,
          mobile: this.formattedPhone,
          email: this.email,
          password: this.password,
        }

        this.loading = true

        const result = await this.$store.dispatch('signUp', data)
        if (result) {
          console.log('sign up: true');
          this.$modal.hide('SignUpPopup')
          const message = {
            title: 'Thanks for signing up!',
            text: "We've sent you a verification code to the provided email.",
          }
          this.$modal.show('ThanksPopup', {message})
        }

        this.loading = false
        this.firstname = ''
        this.middlename = ''
        this.lastname = ''
        this.phone = ''
        this.formattedPhone = ''
        this.email = ''
        this.password = ''
      },
      updatePhone (payload) {
          this.formattedPhone = payload.formattedNumber
      },
      updateProviderPhone (payload) {
          this.formattedPhoneProvider = payload.formattedNumber
      },
      async submitHandlerProvider () {
        if (this.$v.firstnameProvider.$invalid ||
          this.$v.companyProvider.$invalid ||
          this.$v.phoneProvider.$invalid ||
          this.$v.emailProvider.$invalid ||
          this.$v.passwordProvider.$invalid ||
          this.$v.confirmPasswordProvider.$invalid ||
          this.passwordProvider !== this.confirmPasswordProvider) {
          this.$v.$touch()
          return false
        }

        const data = {
          accountType: 2,
          firstName: this.firstnameProvider,
          middleName: this.middlenameProvider,
          lastName: this.lastnameProvider,
          companyName: this.companyProvider,
          mobile: this.formattedPhoneProvider,
          email: this.emailProvider,
          password: this.passwordProvider,
        }

        this.loading = true

        const result = await this.$store.dispatch('signUp', data)

        if (result) {
          console.log('sign up: true', result);
          this.$modal.hide('SignUpPopup')
          const message = {
            title: 'Thanks for registration!',
            text: 'We will review your request and get back to you.',
          }
          this.$modal.show('ThanksPopup', {message})
        }

        this.loading = false
        this.firstnameProvider = ''
        this.middlenameProvider = ''
        this.lastnameProvider = ''
        this.companyProvider = ''
        this.phoneProvider = ''
        this.formattedPhoneProvider = ''
        this.emailProvider = ''
        this.passwordProvider = ''
      },
    },
    components: {
      Spinner,
      SvgIcon,
      VuePhoneNumberInput,
    },
  }
</script>
