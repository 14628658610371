import {instance} from '../index';

export const requestEmptyAPI = {
  getApRequests (pageNumber, rowsCount, isMatchUp) {
    return instance.get(`requestEmpties/getPaginatedList?matchUp=${isMatchUp}&page=${pageNumber}&size=${rowsCount}`);
  },
  cancelRequestEmpty (id) {
    return instance.patch(`requestEmpties/cancel?Id=${id}`);
  },
  exportRequestEmpty (isMatchUp) {
    return instance.get(`/reports/requestEmpties?matchUp=${isMatchUp}`);
  },
}
