import {personnelAPI} from '../../../api/dashboard/personnel';

const state = () => ({
  list: [
    {
      id: 'person1',
      photo: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784189/FlyJets/avatar_zmfk8x.jpg',
      name: 'Anna Li',
      description: 'FlyJet member',
      phone: '+ 1 888 88-99-77',
      email: 'annali@gmail.com',
    }, {
      id: 'person2',
      photo: null,
      name: 'Lina Mary Rone',
      description: 'Invited by email',
      phone: '+ 1 343 43-43-67',
      email: 'linamary@gmail.com',
    },
  ],
})

const mutations = {
  setTeamMembers (state, data) {
    state.list = data
  },
  removeItemPerson (state, data) {
    state.list = state.list.filter(item => item.id !== data)
  },
}

const actions = {
  async getTeamMembers ({commit}) {
    try {
      const result = await personnelAPI.getTeamMembers()
      commit('setTeamMembers', result.data)
    } catch (e) {
      console.log('no team members founded')
    }
  },
  async addTeamMember ({commit}, data) {
    try {
      await personnelAPI.addTeamMember(data)
      const result = await personnelAPI.getTeamMembers()
      commit('setTeamMembers', result.data)
      return true
    } catch (e) {
      console.log('team member not added')
      return false
    }
  },
  async deleteTeamMember ({commit}, data) {
    try {
      await personnelAPI.deleteTeamMember(data)
      commit('removeItemPerson', data)
    } catch (e) {
      console.log('removing failed')
    }
  },
}

export default {
  state,
  mutations,
  actions,
}
