import { adminBlogsAPI } from '@/api/admin/blog'
import router from '@/router'

const state = () => ({
  token: null,
  isFetching: false,
  posts: [],
  pagination: {},
  article: {},
  search: '',
  subcategories: [],
  categories: [],
});

const mutations = {
  setAdminArticles (state, data) {
    if (data.meta.pagination.page === 1) {
      state.posts = data.data;
    } else {
      state.posts = [...state.posts, ...data.data];
    }
    state.pagination = data.meta.pagination;
  },
  setUser (state, {user}) {
    state.token = user.data.account.strapiToken || process.env.VUE_APP_ADMIN_BLOG_TOKEN
  },
  setSearch (state, data) {
    state.search = data
  },
  setAdminSubcategories (state, data) {
    state.subcategories = data.data
  },
  setAdminCategories (state, data) {
    state.categories = data.data
  },
  setIsFetching (state, isFetching) {
    state.isFetching = isFetching;
  },
}

const actions = {
  async getAdminArticles ({commit, state}, filter) {
    try {
      commit('setIsFetching', true)
      const { data } = await adminBlogsAPI.getArticles(filter, state.token);

      commit('setAdminArticles', data);
    } finally {
      commit('setIsFetching', false)
    }
  },
  async deleteArticle ({dispatch, state}, id) {
    await adminBlogsAPI.deleteArticle(id, state.token);

    dispatch('getAdminArticles');
    dispatch('getAdminDraftArticles');
  },
  async createArticle ({dispatch, commit, state}) {
    try {
      commit('setIsFetching', true)
      const { data } = await adminBlogsAPI.createArticle(state.token);
      router.push(`/fly-report/articles/${data.data.id}`)

      dispatch('getAdminDraftArticles');
    } finally {
      commit('setIsFetching', false)
    }
  },
  setSearch ({commit}, data) {
    commit('setSearch', data)
  },
  async getAdminSubcategories ({ commit, state }) {
    const { data } = await adminBlogsAPI.getSubcategories(state.token);

    commit('setAdminSubcategories', data)
  },
  async getAdminCategories ({ commit, state }) {
    const { data } = await adminBlogsAPI.getCategories(state.token);

    commit('setAdminCategories', data)
  },
  async getAdminArticle ({commit, state}, id) {
    try {
      commit('setIsFetching', true)
      const { data } = await adminBlogsAPI.getArticle(id, state.token);

      return data;
    } finally {
      commit('setIsFetching', false)
    }
  },
  async updateAdminArticle ({commit, state}, { id, article }) {
     try {
      commit('setIsFetching', true)
      const { data } = await adminBlogsAPI.updateArticle(id, article, state.token);

      return data;
     } finally {
      commit('setIsFetching', false)
     }
  },
  async uploadImage ({state, commit}, file) {
    try {
      commit('setIsFetching', true)
      const { data } = await adminBlogsAPI.uploadFile(file, state.token);

      return data;
    } finally {
      commit('setIsFetching', false)
    }
  },
}

export default {
  state,
  actions,
  mutations,
};
