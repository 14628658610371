import * as axios from 'axios';
import qs from 'qs'

const instance = axios.create({
  crossDomain: true,
  baseURL: process.env.VUE_APP_BLOG_URI + '/api',
  withCredentials: true,
  contentType: 'application/json',
  headers: {
    Authorization: 'Bearer ' + process.env.VUE_APP_BLOG_TOKEN,
  },
});

const populateFields = 'populate[0]=tags&populate[1]=cover&populate[2]=categories'

export const blogsAPI = {
  getArticles ({ filters, populate = ['author', 'cover', 'categories', 'subcategories'], sort = ['publishedAt:desc'], pagination} = {}) {
    const params = qs.stringify({
      populate,
      filters,
      sort,
      pagination,
    }, {
      encodeValuesOnly: true,
    })

    return instance.get(`articles?${params}`);
  },
  searchArticles (searchRequest) {
    const filters = { searchRequest: searchRequest };
    const params = qs.stringify(filters, {
      encodeValuesOnly: true,
    })
    return instance.get(`search-article?${params}`);
  },
  getGrid ({ subcategory, category, pagination } = {}) {
    const params = {}
    if (subcategory) {
      params.subcategory = subcategory
    }

    if (category) {
      params.category = category
    }

    if (pagination) {
      params.pagination = pagination
    }

    const stringifiedParams = qs.stringify(params, {
      encodeValuesOnly: true,
    });

    return instance.get(`grid?${stringifiedParams}`);
  },
  getArticle (articlePath) {
    return instance.get(`articles?filters[article_path][$eq]=${articlePath}&${populateFields}`);
  },
  getFeed () {
    return instance.get('grid/feed');
  },
  getAllFeed () {
    return instance.get('grid/allFeed');
  },
  getSubcategories () {
    return instance.get(`subcategories?${qs.stringify({
      sort: ['order:asc'],
    }, {
      encodeValuesOnly: true,
    })}`)
  },
  getCategories () {
    return instance.get(`categories?${qs.stringify({
        sort: ['order:asc'],
      }, {
        encodeValuesOnly: true,
      })}`)
  },
  getSubcategoryList (params) {
    const stringifiedParams = qs.stringify(params, {
      encodeValuesOnly: true,
    })
    return instance.get(`subcategory-article?${stringifiedParams}`);
  },
};
