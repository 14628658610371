import Vue from 'vue'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import './registerServiceWorker'
import router from './router'
import moment from 'moment'
import '@/js/plugins/device.min.js'
import store from './store'
import vuescroll from 'vuescroll'
import VueMeta from 'vue-meta'
import {Animations} from '@/js/plugins/animations'
import {FieldsActions} from '@/js/plugins/fields';
import VCalendar from 'v-calendar';
// import 'ant-design-vue/dist/antd.css'; webpack handling
import { Select, DatePicker, Slider, Upload } from 'ant-design-vue'
import 'swiper/swiper-bundle.css'
import VModal from 'vue-js-modal'
import '@livelybone/vue-datepicker/lib/css/index.scss'
import VueMask from 'v-mask'
import VueCookies from 'vue-cookies'
import * as VueGoogleMaps from 'vue2-google-maps'
//
import OtpInput from '@bachdgvn/vue-otp-input'

Vue.use(Select)
Vue.use(DatePicker)
Vue.use(Slider)
Vue.use(Upload)
Vue.use(VCalendar)
Vue.use(Vuelidate)
Vue.use(VModal)
Vue.use(VueMask)
Vue.use(VueCookies)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDTtMUY96FwdCihu1z3Px4rEZQ6uKabQRU',
    language: 'en',
    libraries: 'places',
  },
})
Vue.use(VueMeta, {
  // optional pluginOptions
  // refreshOnceOnNavigation: true,
})
Vue.component('v-otp-input', OtpInput)

Vue.$cookies.config('7d')

Vue.config.productionTip = true

Vue.filter('formatBlogDate', (value) => {
  if (value) {
    return moment(String(value)).format('dddd, MMMM D, YYYY')
  }
})

Vue.filter('formatTime', (value) => {
  if (value) {
    return moment(String(value)).format('h:mm:ss a')
  }
})

Vue.use(vuescroll, {
  ops: {
    vuescroll: {
      wheelScrollDuration: 700,
      detectResize: true,
    },
    scrollPanel: {

    },
    rail: {
      gutterOfEnds: '8px',
      gutterOfSide: '5px',
    },
    bar: {
      onlyShowBarOnScroll: false,
    },
  },
})

new Vue({
  router,
  data: () => ({
    animations: new Animations(),
    fields: new FieldsActions(),
  }),
  store,
  render: h => h(App),
}).$mount('#app')
