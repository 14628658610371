export class Tabs {
  constructor (tab, tabContent) {
    this.tab = tab
    this.tabContent = tabContent
  }

  getTabs () {
    return document.querySelectorAll(`${this.tab}`)
  }

  getTabsContent () {
    return document.querySelectorAll(`${this.tabContent}`)
  }

  addActiveForMouted (activeTab = 0) {
    this.getTabs().forEach((item, index) => {
      if (index === activeTab) item.classList.add('active')
    })

    this.getTabsContent().forEach((item, index) => {
      if (index === activeTab) {
        item.classList.add('active')
        item.style.display = 'block'
        setTimeout(() => {
          item.style.opacity = 1
          item.style.visibility = 'visible'
        }, 100)
      }
    })
  }

  addActiveForClick (elem) {
    this.getTabs().forEach((item, index) => {
      if (item === elem) {
        item.classList.add('active')
        this.getTabsContent().forEach((itemContent, indexContent) => {
          itemContent.classList.remove('active')
          itemContent.style.display = 'none'
          itemContent.style.opacity = 0
          itemContent.style.visibility = 'hidden'

          if (index === indexContent) {
            itemContent.style.display = 'block'

            setTimeout(() => {
              itemContent.classList.add('active')
              itemContent.style.opacity = 1
              itemContent.style.visibility = 'visible'
            }, 100)
          }
        })
      } else {
        item.classList.remove('active')
      }
    })
  }

  removeTabs () {
    this.getTabs().forEach((item, index) => {
      item.classList.remove('active')
    })

    this.getTabsContent().forEach((itemContent, indexContent) => {
      itemContent.classList.remove('active')
      itemContent.style.opacity = 0
      itemContent.style.visibility = 'hidden'
      setTimeout(() => {
        itemContent.style.display = 'none'
      }, 200)
    })
  }
}
