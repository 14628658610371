const state = () => ({
  team1: [{
    avatar: '@/assets/img/infoBlock/about/Jessica.jpg',
    name: 'Jessica Fisher',
    position: 'Founder and Chief Executive Officer',
    wherefrom: 'New York, USA',
    bio: '<article><p>Jessica Fisher is the Founder and Chief Executive Officer of FLYJETS. She is a lifetime aviation enthusiast and began training as a student pilot in 2012.</p><p>Fisher is also a principal at Monroe Capital, where she focuses on impact investments. Previously, Jessica worked as an associate producer in CNBC’s Strategic Programming and Development division, as an analyst at MBF Asset Management, as an analyst at Goldman Sachs in the U.S. Equities Sales division and as an M.B.A summer intern at the Robin Hood Foundation.</p><p>Jessica holds an M.B.A. from Columbia Business School, where the idea for FLYJETS was born as her Introduction to Venturing course project in the summer of 2012. She graduated cum laude with a B.A. in Economics from the University of Pennsylvania in 2008.</p><p>Jessica participated as an Engineering Fellow in the Web Development Immersive program at General Assembly in the spring of 2017.</p><p>Jessica began her private pilot studies and flight lessons at Danny Waizman Flight School in the spring of 2012, and completed her first solo flight in the spring of 2014.</p><p>Jessica is also an avid runner, a tennis player, and an information and space enthusiast.</p></article>',
    id: 'JessicaFisherBio',
  }, {
    avatar: '@/assets/img/infoBlock/about/Yana.jpg',
    name: 'Yana Uglunts',
    position: 'Art and Creative Director',
    wherefrom: 'Global Traveler',
    bio: '<article><p>Yana Uglunts is the Art and Creative Director at FLYJETS.  Yana also collaborates as an independent contractor with a variety of web studios, helping to develop unique and beautiful user interfaces and user experiences.</p><p>Previously, Uglunts held the position of senior user interface and user experience designer at SAMEDIA web design studio.  She has also worked as a web designer in various studios including WEBANT and FIRECODE.</p><p>In 2017, Yana received her Bachelor\'s degree and a Diploma with Distinction from Southern Federal University with a direction of innovative technologies. In 2013, she graduated from Lyceum 14 in Stavropol with a Diploma with Distinction and a direction in physics and mathematics.</p><p>Yana has spent time studying web design independently, performing extensive research of user and consumer marketplaces and identifying problems and solutions associated with websites and mobile applications. Her goal is to continue to translate business language into design language.</p><p>Yana speaks both Russian and English. She is also fluent in Figma, Adobe Photoshop, Adobe After Effects and Adobe Illustrator.</p><p>Yana\'s additional interests include sports, nutrition, psychology and marketing.</p></article>',
    id: 'YanaUgluntsBio',
  }],
  team2: [{
      avatar: '@/assets/img/infoBlock/about/Jaclyn.jpg',
      name: 'Jaclyn Kolsby',
      position: 'Director of Sales',
      wherefrom: 'New York, USA',
      bio: '<article><p>Jaclyn Kolsby is the Director of Sales at FLYJETS.</p><p>As a corporate flight attendant and experienced jet broker, Jaclyn leads sales, customer service and sourcing efforts.</p><p>Jaclyn has worked as a FlightSafety certified corporate flight attendant since 2013; prior to aviation, she worked as a restaurant owner and manager.  Jaclyn graduated from Monmouth University with a BA in Marketing in 2004.</p><p>Jaclyn is an expert in travel, loves to ski and spends her free time with friends and family.</p></article>',
      id: 'JaclynKolsbyBio',
    }, {
      avatar: '@/assets/img/infoBlock/about/Dani.jpg',
      name: 'Daniel Ariza',
      position: 'Marketing and Distribution Advisor',
      wherefrom: 'New York, USA',
      bio: '<article><p>Daniel Ariza is the Marketing and Distribution Advisor at FLYJETS.</p><p>Daniel is an eCommerce and online marketing expert who works in Global Marketing Solutions at Facebook, with a focus on the Travel industry. Prior to Facebook, Daniel worked at Amazon and in consulting.</p><p>Daniel is passionate about travel. In 2019 alone, he took 94 flights to more than 20 countries. Daniel was born and raised in the South of Spain and lived in Madrid and Dublin before moving to the United States.</p></article>',
      id: 'DanielArizaBio',
    }, {
      avatar: '@/assets/img/infoBlock/about/Andrei.jpg',
      name: 'Andrei Shemshura',
      position: 'Lead Software Engineer',
      wherefrom: 'Lisbon, Portugal',
      bio: '<article><p>Andrei Shemshura is the Lead Software Engineer at FLYJETS.</p><p>Andrei previously worked as an engineer in the financial services industry.  Prior to this, he completed his undergraduate studies at The South Federal University as an information economist.</p><p>Andrei\'s preferred technology stack includes .NET, Javascript, MSSQL, Oracle, Azure and Creatio by Terrasoft.</p><p>Andrei is passionate about friends and family - his wife and two sons, specifically - green and eco-friendly living, yoga and life in Portugal.</p></article>',
      id: 'AndreiShemshuraBio',
    }],
})

export default {
  state,
}
