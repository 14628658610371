export class FieldsActions {
  getFields (el) {
    return document.querySelectorAll(`${el}`)
  }

  changeChecked (field, disableForm) {
    this.getFields(field).forEach((item) => {
      if (item.checked) {
        item.closest('.form__label--checkbox').classList.add('checked')
      } else {
      }

      item.addEventListener('change', () => {
        if (item.checked) {
          item.closest('.form__label--checkbox').classList.add('checked')
          disableForm && item.closest('form').classList.remove('disabled')
        } else {
          item.closest('.form__label--checkbox').classList.remove('checked')
          disableForm && item.closest('form').classList.add('disabled')
        }
      })
    })
  }

  changeRadio (field) {
    this.getFields(field).forEach((item) => {
      if (item.checked) {
        item.closest('.form__label--radio').classList.add('checked')
      }

      item.addEventListener('change', () => {
        item.closest('form').querySelectorAll('input[type="radio"]').forEach((elem) => {
          if (elem.checked) {
            elem.closest('.form__label--radio').classList.add('checked')
          } else {
            elem.closest('.form__label--radio').classList.remove('checked')
          }
        })
      })
    })
  }

  changeRadioWithText (field) {
    this.getFields(field).forEach((item) => {
      item.closest('.form__labelGroup').querySelector('input[type="text"]').setAttribute('disabled', 'disabled')
      if (item.checked) {
        item.closest('.form__label--radio').classList.add('checked')
        item.closest('.form__labelGroup').querySelector('input[type="text"]').removeAttribute('disabled')
      }

      item.addEventListener('change', () => {
        item.closest('form').querySelectorAll('input[type="radio"]').forEach((elem) => {
          if (elem.checked) {
            elem.closest('.form__label--radio').classList.add('checked')
            elem.closest('.form__labelGroup').querySelector('input[type="text"]').removeAttribute('disabled')
            item.closest('.form__labelGroup').querySelector('input[type="text"]').select()
          } else {
            elem.closest('.form__label--radio').classList.remove('checked')
            elem.closest('.form__labelGroup').querySelector('input[type="text"]').setAttribute('disabled', 'disabled')
            elem.closest('.form__labelGroup').querySelector('input').value = ''
          }
        })
      })
    })
  }
}
