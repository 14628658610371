import {argusAPI} from '@/api/argusStatistics';

const state = () => ({
  firstTableData: [],
  secondTableData: [],
})

const mutations = {
  setArgusStatistic (state, data) {
    state.firstTableData = data.firstTableData;
    state.secondTableData = data.secondTableData;
  },
}

const actions = {
    async addArgusStatistic ({commit}, data) {
        try {
            await argusAPI.addArgusStatistic(data);
            return true
          } catch (e) {
            console.log('Argus statistic not created');
            return false
          }
    },
    async getArgusStatistic ({commit}) {
      const results = await argusAPI.getArgusStatistic();
      try {
        if (results.data !== null) {
          commit('setArgusStatistic', results.data)
          return true
        } else {
          return false
        }
      } catch (e) {
        console.log('Argus Statistic not found');
      }
    },
}

export default {
    state,
    mutations,
    actions,
};