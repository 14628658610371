import { blogsAPI } from '@/api/blog'

const state = () => ({
  isFetching: false,
  posts: [],
  pagination: {},
  feed: [],
  list: [
    {
      id: 1,
      day: 'Monday, May 3, 2021',
      posts: [
        {
          image: 'https://res.cloudinary.com/flyjetsdigital/image/upload/v1621261127/pictures/aircraft_4_fqqrkb.jpg',
          title: 'Transcend Air announces',
          author: 'Jessica Fisher',
          text: 'Transcend Air announces merger with xyzzy lorem ipsum dolor sit\n' +
            'amet, consectetur adipisicing elit, sed do eiusmod tempor\n' +
            ' incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex\n' +
            'ea commodo consequat. Duis aute irure dolor in reprehenderit in\n' +
            'voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        }, {
          image: 'https://res.cloudinary.com/flyjetsdigital/image/upload/v1621261128/pictures/aircraft_3_sftmv7.jpg',
          title: 'BlogArticle 2',
          author: 'Adam Smith',
          text: 'Transcend Air announces merger with xyzzy lorem ipsum dolor sit\n' +
            'amet, consectetur adipisicing elit, sed do eiusmod tempor\n' +
            ' incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex\n' +
            'ea commodo consequat. Duis aute irure dolor in reprehenderit in\n' +
            'voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        },
      ],
    },
    {
      id: 2,
      day: 'Friday, April 30, 2021',
      posts: [
        {
          image: 'https://res.cloudinary.com/flyjetsdigital/image/upload/v1621261127/pictures/aircraft_4_fqqrkb.jpg',
          title: 'Transcend Air announces',
          author: 'Jessica Fisher',
          text: 'Transcend Air announces merger with xyzzy lorem ipsum dolor sit\n' +
            'amet, consectetur adipisicing elit, sed do eiusmod tempor\n' +
            ' incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex\n' +
            'ea commodo consequat. Duis aute irure dolor in reprehenderit in\n' +
            'voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        }, {
          image: 'https://res.cloudinary.com/flyjetsdigital/image/upload/v1621261128/pictures/aircraft_3_sftmv7.jpg',
          title: 'BlogArticle 2',
          author: 'Adam Smith',
          text: 'Transcend Air announces merger with xyzzy lorem ipsum dolor sit\n' +
            'amet, consectetur adipisicing elit, sed do eiusmod tempor\n' +
            ' incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex\n' +
            'ea commodo consequat. Duis aute irure dolor in reprehenderit in\n' +
            'voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        },
      ],
    },
  ],
  article: {},
  subcategories: [],
  categories: [],
  grid: [],
  searchResults: [],
  searchResultsPagination: [],
  blogMobileMenuOpen: false,
  blogSubcategoryList: [],
  blogSubcategoryListPagination: [],
});

const mutations = {
  setArticles (state, data) {
    state.posts = data.data;
    state.pagination = data.pagination;
  },
  setArticle (state, data) {
    state.article = data.data[0];
  },
  setSearchResults (state, data) {
    state.searchResults = data.results;
    state.searchResultsPagination = data.pagination;
  },
  setFeed (state, data) {
    state.feed = data;
  },
  setSubcategories (state, data) {
    state.subcategories = data.data
  },
  setCategories (state, data) {
    state.categories = data.data
  },
  setGrid (state, data) {
    state.grid = data
  },
  setShowInHeader (state, data) {
    state.subcategories = state.subcategories.map(item => {
      if (item.attributes.name === data.subcategoryName) {
        item.attributes.showInHeader = data.value;
      }
      return item;
    });
  },
  addToGrid (state, data) {
    state.grid = state.grid.map((grid) => {
      if (grid.id === data.id) {
        return {
          ...grid,
          params: {...grid.params, pagination: data.posts.pagination },
          posts: {
            results: [
              ...grid.posts.results,
              ...data.posts.results,
            ],
            pagination: data.posts.pagination,
          },
        }
      }

      return grid
    })
  },
  toggleMobileMenuOpen (state) {
    state.blogMobileMenuOpen = !state.blogMobileMenuOpen
  },
  setSubcategoryList (state, data) {
    state.blogSubcategoryList = data.results;
    state.blogSubcategoryListPagination = data.pagination;
  },
  addSubcategoryList (state, data) {
    state.blogSubcategoryList = [...state.blogSubcategoryList, ...data.results];
    state.blogSubcategoryListPagination = data.pagination;
  },
}

const actions = {
  async getArticles ({commit}, params) {
    const { data } = await blogsAPI.getArticles(params);

    commit('setArticles', data);
  },
  async searchArticles ({commit}, params) {
    const { data } = await blogsAPI.searchArticles(params);

    commit('setSearchResults', data);
  },
  clearSearchResults ({commit}) {
    commit('setSearchResults', { results: [], pagination: {} })
  },
  async getArticle ({commit}, articlePath) {
    const { data } = await blogsAPI.getArticle(articlePath);

    commit('setArticle', data);
  },
  async getGrid ({commit}, params) {
    let { data } = await blogsAPI.getGrid(params);
    const topArticles = data.find(({title}) => title === 'Top').posts.results.map(article => article.id);
    let needLoadMore = [];
    do {
      if (needLoadMore.length > 0) {
        for (let i = 0; i < needLoadMore.length; i++) {
          let additionalArticles = await blogsAPI.getGrid({ ...params, category: needLoadMore[i].categoryId, pagination: {...needLoadMore[i].pagination, page: needLoadMore[i].pagination.page + 1}});
          additionalArticles = additionalArticles.data[0];
          needLoadMore[i].overloadedArticlesCount = additionalArticles.posts.results.length - needLoadMore[i].articlesCount;
          data = data.map((gridItem) => {
            if (gridItem.id === needLoadMore[i].categoryId) {
              return {
                ...gridItem,
                params: {...gridItem.params, pagination: additionalArticles.posts.pagination },
                posts: {
                  results: [
                    ...gridItem.posts.results,
                    ...additionalArticles.posts.results,
                  ],
                  pagination: additionalArticles.posts.pagination,
                },
              }
            }
            return gridItem
          });
          if (needLoadMore[i].overloadedArticlesCount === 0) {
            console.log('clear needLoadMore');
            needLoadMore.splice(i, 1);
            i--;
          }
        }
      }
      data = data.map(gridItem => {
        if (gridItem.title !== 'Top') {
          let extraArticleCount = 0;
          gridItem.posts.results = gridItem.posts.results.filter(article => {
            const isTopArticle = topArticles.includes(article.id);
            if (isTopArticle) {
              extraArticleCount++;
            }
            return !isTopArticle;
          });
          const needLoadMoreItem = needLoadMore.find(({categoryId}) => categoryId === gridItem.id) || { overloadedArticlesCount: 0 };
          const diffBetweenExtraAndOver = extraArticleCount - needLoadMoreItem.overloadedArticlesCount;
          if (diffBetweenExtraAndOver < 0) {
            gridItem.posts.results = gridItem.posts.results.slice(0, gridItem.posts.results.length + diffBetweenExtraAndOver); // because  diffBetweenOverAndExtra < 0
            gridItem.posts.pagination.page = gridItem.posts.pagination.page - 1;
          }
          const needLoadMoreItemIndex = needLoadMore.indexOf(needLoadMoreItem);
          console.log(needLoadMoreItemIndex);
          if (needLoadMoreItemIndex > -1) {
            needLoadMore.splice(needLoadMoreItemIndex, 1);
          }
          if (diffBetweenExtraAndOver > 0 && gridItem.posts.pagination.page < gridItem.posts.pagination.pageCount) {
            needLoadMore = [...needLoadMore, {categoryId: gridItem.id, articlesCount: diffBetweenExtraAndOver, pagination: gridItem.posts.pagination}];
          }
        }
        return gridItem;
      });
      console.log('before while');
      console.log(needLoadMore);
    } while (needLoadMore.length > 0);
    commit('setGrid', data);
  },

  async loadMoreGrid ({commit, state}, params) {
    const articlesForCheck = state.grid.reduce((result, item) => {
      return result.concat(item.posts.results.map(r => r.id));
    }, []);
    const paginationParameters = state.grid.find(({id}) => id === params.category).params.pagination;
    let { data } = await blogsAPI.getGrid({...params, pagination: {...paginationParameters, page: paginationParameters.page + 1}});
    data = data[0];
    let needLoadMore = null;
    do {
      let overloadedArticlesCount = 0;
      if (needLoadMore) {
        let additionalArticles = await blogsAPI.getGrid({ ...params, pagination: {...needLoadMore.pagination, page: needLoadMore.pagination.page + 1}});
        additionalArticles = additionalArticles.data[0];
        overloadedArticlesCount = additionalArticles.posts.results.length - needLoadMore.articlesCount;
        data = {
          ...data,
          params: {...data.params, pagination: additionalArticles.posts.pagination },
          posts: {
            results: [
            ...data.posts.results,
            ...additionalArticles.posts.results,
            ],
          pagination: additionalArticles.posts.pagination,
          },
        }
      }
      needLoadMore = null;
      let extraArticleCount = 0;
      data.posts.results = data.posts.results.filter(article => {
        const isExtraArticle = articlesForCheck.includes(article.id);
        if (isExtraArticle) {
          extraArticleCount++;
        }
         return !isExtraArticle;
      });
      const diffBetweenExtraAndOver = extraArticleCount - overloadedArticlesCount;
      if (diffBetweenExtraAndOver < 0) {
        data.posts.results = data.posts.results.slice(0, data.posts.results.length + diffBetweenExtraAndOver); // because  diffBetweenOverAndExtra < 0
        data.posts.pagination.page = data.posts.pagination.page - 1;
      }
      if (diffBetweenExtraAndOver > 0 && data.posts.pagination.page < data.posts.pagination.pageCount) {
        needLoadMore = { articlesCount: diffBetweenExtraAndOver, pagination: data.posts.pagination };
      }
      console.log('before while load more');
      console.log(needLoadMore);
    } while (needLoadMore);
    console.log('before filter');
    console.log(data);
    commit('addToGrid', data);
  },
  // async loadMoreGrid ({commit, state}, categoryId) {
  //   const {params} = state.grid.find(({id}) => id === categoryId)
  //   console.log(params)
  //   const { data } = await blogsAPI.getArticles({...params, pagination: { ...params.pagination, page: params.pagination.page + 1 }})

  //   commit('addToGrid', { id: categoryId, posts: { results: data.data, pagination: data.meta.pagination } })
  // },
  clearArticle ({commit}) {
    commit('setArticle', {})
  },
  async getFeed ({commit}) {
    const { data } = await blogsAPI.getFeed();

    commit('setFeed', data);
  },
  async getSubcategories ({commit}) {
    const { data } = await blogsAPI.getSubcategories();

    commit('setSubcategories', data)
  },
  async getCategories ({commit}) {
    const { data } = await blogsAPI.getCategories();

    commit('setCategories', data)
  },
  async showSubcategory ({commit}, subcategoryName) {
    commit('setShowInHeader', { subcategoryName: subcategoryName, value: true });
  },
  async hideSubcategory ({commit}, subcategoryName) {
    commit('setShowInHeader', { subcategoryName: subcategoryName, value: false });
  },
  toggleBlogMobileMenu ({commit}) {
    const html = document.querySelector('html')
    if (html.classList.contains('d-mobile')) {
      commit('toggleMobileMenuOpen')
    }
  },
  async getSubcategoryList ({commit}, params) {
    const { data } = await blogsAPI.getSubcategoryList(params);

    commit('setSubcategoryList', data);
  },
  async loadMoreSubcategory ({commit}, params) {
    const { data } = await blogsAPI.getSubcategoryList(params);

    commit('addSubcategoryList', data);
  },
}

export default {
  state,
  actions,
  mutations,
};
