import { availabilitiesAPI } from '@/api/provider/availabilities';

const state = () => ({
charterAvailabilities: [],
flightAvailabilities: [],
})

const mutations = {
    setCharterAvailabilities (state, data) {
        state.charterAvailabilities = data
    },
    setFlightAvailabilities (state, data) {
        state.flightAvailabilities = data
    },
}

const actions = {
  async getCharterAvailabilities ({commit}) {
    const data = await availabilitiesAPI.getCharterAvailabilities();
    commit('setCharterAvailabilities', data.data)
  },
  async getFlightAvailabilities ({commit}) {
    const data = await availabilitiesAPI.getFlightAvailabilities();
    commit('setFlightAvailabilities', data.data)
  },
}

export default {
  state,
  mutations,
  actions,
}
