import {requestEmptyAPI} from '@/api/admin/requestEmpty';

const state = () => ({
  requestEmptyList: [],
  requestEmptyCount: 0,
  requestEmptyPageCount: 0,
  oppositeBookingList: [],
  oppositeBookingCount: 0,
  oppositeBookingPageCount: 0,
  flyerInfo: {},
})

const mutations = {
  setRequestEmpty (state, data) {
    const {requestEmptiesCount, pageCount, requestEmptiesView } = data.item.value
    state.requestEmptyList = requestEmptiesView;
    state.requestEmptyCount = requestEmptiesCount;
    state.requestEmptyPageCount = pageCount;
  },
  setOppositeBooking (state, data) {
    const {requestEmptiesCount, pageCount, requestEmptiesView } = data.item.value

    state.oppositeBookingList = requestEmptiesView;
    state.oppositeBookingCount = requestEmptiesCount;
    state.oppositeBookingPageCount = pageCount;
  },
}
const actions = {
  async getRequestEmpty ({commit}, data) {
    try {
      const {pageNumber, pageSize} = data
      const result = await requestEmptyAPI.getApRequests(pageNumber, pageSize, false);

      commit('setRequestEmpty', result.data);
    } catch (e) {
      console.log('e', e)
    }
  },
  async getOppositeBooking ({commit}, data) {
    try {
      const {pageNumber, pageSize} = data
      const result = await requestEmptyAPI.getApRequests(pageNumber, pageSize, true);

      commit('setOppositeBooking', result.data);
    } catch (e) {
      console.log('e', e)
    }
  },
  async cancelRequestEmpty ({commit}, id) {
    try {
      await requestEmptyAPI.cancelRequestEmpty(id);
    } catch (e) {
      console.log('e', e)
    }
  },
  async exportRequestEmpty ({commit}, isMatch) {
    try {
      return await requestEmptyAPI.exportRequestEmpty(isMatch);
    } catch (e) {
      console.log('e', e)
    }
  },
}
export default {
  state,
  mutations,
  actions,
}
