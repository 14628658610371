const state = () => ({
  bookingConfirmation: {
    flyJetsPerson: [
      {
        id: 'person1',
        photo: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784189/FlyJets/avatar_zmfk8x.jpg',
        name: 'Mary Jane Watson',
        phone: '8-666-777-88-34',
      },
    ],
    passengers: [
      {
        id: 'person2',
        photo: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784189/FlyJets/avatar_zmfk8x.jpg',
        name: 'Anna Li',
        phone: '8-666-777-88-34',
      },
      {
        id: 'person3',
        photo: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602199687/FlyJets/avatar_s1o4ii.jpg',
        name: 'John Doe',
        phone: '8-666-777-88-34',
      },
      {
        id: 'person4',
        photo: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784186/FlyJets/avatar-2_wf9rvk.jpg',
        name: 'Mary Jane Watson',
        phone: '8-666-777-88-34',
      },
    ],
  },
})

export default {
  state,
}
