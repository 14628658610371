<template>
  <div class="messageCenter js-message-center">
    <Stub/>
    <div class="messageCenter__bg js-message-center-close" @click="closeMenu"></div>
    <div class="messageCenter__header">
      <span class="text">Message Center</span>
      <span class="icon js-message-center-close" @click="closeMenu">
        <SvgIcon name="close"/>
      </span>
    </div>

    <div class="messageCenter__inner">
      <div class="messageCenter__chat">
        <vue-scroll :ops="ops">
          <div
            v-for="(item, index) of messageCenter"
            class="messageCenter__chat-item"
            :class="[{'messageCenter__chat-item--inbox' : item.mod === 'inbox'}, {'messageCenter__chat-item--sent' : item.mod === 'sent'}]"
            :key="index">
            <div class="messageCenter__chat-header">
              <div class="messageCenter__chat-avatar" :style="{backgroundImage: `url(${item.ava})`}"></div>
              <div class="messageCenter__chat-caption">
                <div class="messageCenter__chat-name">{{item.name}}</div>
                <div class="messageCenter__chat-time">{{item.time}}</div>
              </div>
            </div>
            <div class="messageCenter__chat-content">
              <div
                v-for="(message, id) of item.text"
                class="messageCenter__chat-text"
                :key="id"
              >
                {{message}}
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>

      <div class="messageCenter__form">
        <form class="form form--messageCenter" id="messageCenterForm">
          <label class="form__label form__label--text">
            <span class="form__field-wrapper">
              <textarea
                class="form__field"
                name="message"
                placeholder="Write a message..."
                autoComplete="off"
                required="required">
              </textarea>
            </span>
          </label>
          <div class="form__label form__label--button">
            <button class="form__button" type="submit">
              <SvgIcon name="send-button"/>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Stub from '@/components/common/Stub/Stub'
  import './MessageCenter.styl'

  import image1 from '@/assets/img/dashboard/world.png'
  import avatar from '@/assets/img/content/avatar.jpg'

  export default {
    name: 'MessageCenter',
    data: () => ({
      messageCenter: [{
        name: 'Message center',
        ava: image1,
        time: '3:30 PM',
        text: ['Hi Mary, here we will answer all your questions'],
        mod: 'inbox',
      }, {
        name: 'You',
        ava: avatar,
        time: '3:50 PM',
        text: ['Hello!', 'Flight exactly take place?'],
        mod: 'sent',
      }, {
        name: 'Message center',
        ava: image1,
        time: '4:00 PM',
        text: ['Yes, of course!', 'Flight exactly take place?'],
        mod: 'inbox',
      }, {
        name: 'You',
        ava: avatar,
        time: '3:50 PM',
        text: ['Flight exactly take place?'],
        mod: 'sent',
      }, {
        name: 'Message center',
        ava: image1,
        time: '4:00 PM',
        text: ['Yes, of course!'],
        mod: 'inbox',
      }],
      ops: {
        rail: {
          size: '5px',
          gutterOfEnds: '0px',
          gutterOfSide: '0px',
        },
        bar: {
          size: '5px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    methods: {
      closeMenu () {
        if (document.querySelector('html').classList.contains('d-mobile')) {
          document.querySelector('.content').style.cssText = 'overflow:auto !important'
        }
        this.$store.commit('toggleMenu', false)
      },
    },
    components: {
      SvgIcon,
      Stub,
    },
  }
</script>
