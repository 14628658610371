import { settingsAPI } from '@/api/flyer/settings';

const actions = {
  async getNotChannel () {
    try {
      let result = 0;
      await settingsAPI.getNotChannel().then((res) => {
        result = res.data
      })
      return result;
    } catch (e) {
      console.log('no channel')
      return 0
    }
  },
  async changePassword ({commit}, data) {
    try {
      await settingsAPI.changePassword(data)
      return true
    } catch (e) {
      console.log('password not changed')
      return false
    }
  },
  async setAlerts ({commit}, data) {
    try {
      await settingsAPI.setAlerts(data)
    } catch (e) {
      console.log('no alerts set');
    }
  },
}

export default {
  actions,
}
