<template>
  <div
    class="page page--main js-scrollToMap openMap--half openMap--full"
    :class="[{'messageCenterOpen' : this.$store.state.messageCenter.status}]">
    <div class="content">
      <Header
        :data="true"
        :login="true"
        :title="this.$route.matched[0].props.default.header"
        :search="this.$route.matched[0].props.default.headerSearch"
        :subtitle="this.$route.matched[1] ? this.$route.matched[1].props.default.subtitle : null"
        :type="this.$route.matched[0].components.default.name"
      />
      <div class="content__inner">
        <router-view/>
      </div>
      <MessageCenter/>
      <Footer :type="null"/>
    </div>
    <RequestSuccess/>
    <RequestPayment/>
    <AircraftSearchPopup/>
    <ThanksPopup/>
  </div>
</template>

<script>
  import Header from '@/components/Header/Header'
  import Footer from '@/components/Footer/Footer'
  import MessageCenter from '@/components/common/MessageCenter/MessageCenter'
  import RequestSuccess from '@/components/common/modals/RequestSuccess'
  import RequestPayment from '@/components/common/modals/RequestPayment'
  import AircraftSearchPopup from '@/components/common/modals/AircraftSearchPopup'
  import ThanksPopup from '@/components/common/modals/ThanksPopup'
  import '@/views/info/info.styl'

  export default {
    name: 'common-layout',
    components: {
      Header,
      Footer,
      MessageCenter,
      RequestSuccess,
      RequestPayment,
      AircraftSearchPopup,
      ThanksPopup,
    },
  }
</script>
