import {instance} from './index';

export const locationsAPI = {
  searchLocations (data) {
    return instance.get(`locations/search/tree/126/true/${data.query}`)
  },
  searchAllLocations (data) {
    return instance.get(`locations/search/tree/126/false/${data.query}`)
  },
}
