import { flyCalAPI } from '@/api/flyer/flycal';

const state = () => ({
  reqData: {},
})

const mutations = {
  setFlycalPopupState (state, data) {
    state.reqData = data;
    console.log(state.reqData)
  },
}

const actions = {

  async createFlyCal ({commit}, reqData) {
    try {
      await flyCalAPI.createFlyCal(reqData)
      return true
    } catch (e) {
      console.log('flycal error', e)
      return false
    }
  },
  async showFlyCals () {
    try {
      const returnVal = await flyCalAPI.showFlyCals()
      return returnVal
    } catch (e) {
      console.log('flycal error', e)
      return false
    }
  },
  async checkFlyCalStatus () {
    const returnVal = await flyCalAPI.checkFlyCalStatus()
    return returnVal;
  },
  async submitFlyCalPayment ({commit}, reqData) {
    try {
      const response = await flyCalAPI.submitFlyCalPayment(reqData)
      if (response.data.isSuccessfull === true) {
        return true
      } else {
        return false
      }
    } catch (e) {
      console.log('flycal payment error', e)
      return false
    }
  },
}

export default {
  actions,
  state,
  mutations,
}
