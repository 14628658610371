import {instance} from '../index';

export const flyGreenAPI = {
    getFlyGreenData () {
        return instance.get('bookings/offsets/info')
    },
    submitFlyGreen (bookingNo, offsetPercentage, offsetPrice) {
        return instance.post('bookings/offsets/create/' + bookingNo, {
            offsetPercentage,
            offsetPrice,
        })
    },
}