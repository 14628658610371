import {instance} from '../index';

export const personnelAPI = {
  getTeamMembers () {
    return instance.get('team/list')
  },
  addTeamMember (data) {
    return instance.post('team/add', {
      email: data.email,
    });
  },
  deleteTeamMember (data) {
    return instance.get(`team/deleteMember/${data}`)
  },
};
