import {subscriptionsTypesApi} from '@/api/lookups/subscriptionsTypes';

const state = () => ({
  subscriptionsTypes: null,
  lastFetch: new Date().getTime(),
})

const mutations = {
  setSubscriptionsTypes (state, subscriptionsTypes) {
    state.subscriptionsTypes = subscriptionsTypes
  },
  setLastFetch (lastFetch) {
    state.lastFetch = lastFetch
  },
}

const actions = {
  async fetchSubscriptionsTypes ({state, commit}, forceFetch = false) {
    const now = new Date().getTime()
    const refreshInterval = process.env.VUE_APP_TIME_TO_UPDATE_REFERENCES * 60 * 60 * 1000
    const needUpdateAfterPeriod = state.lastFetch && (now - state.lastFetch > refreshInterval)

    if (!state.subscriptionsTypes || forceFetch || needUpdateAfterPeriod) {
      const result = await subscriptionsTypesApi.getSubscriptionsTypes()

      commit('setSubscriptionsTypes', result.data.types)
      commit('setLastFetch', now)
    }
  },
}

const getters = {
  getSubscriptionsTypeById: (state) => (id) => {
    if (!state.subscriptionsTypes) return null
    return state.subscriptionsTypes.filter(c => c.id === id)[0]
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
