const state = () => ({
  billingMethods: [{
    value: 'BankAccount1',
    caption: 'Bank account ending in 4343',
    detail: [{
      title: 'Bank name:',
      value: 'Sberbank of Russia',
    }, {
      title: 'Account Number:',
      value: '**** **** **** 4343',
    }, {
      title: 'The account holders name:',
      value: 'INNA OLEGOVA',
    }, {
      title: 'Bank address:',
      value: '19 Vavilova St., 117997 Moscow, Russia',
    }],
  }, {
    value: 'BankAccount2',
    caption: 'Bank account ending in 7658',
    detail: [{
      title: 'Bank name:',
      value: 'Deutsche Bank Trust Company Americas',
    }, {
      title: 'Account Number:',
      value: '**** **** **** 7658',
    }, {
      title: 'The account holders name:',
      value: 'INNA OLEGOVA',
    }, {
      title: 'Bank address:',
      value: '35, Myasnitskaya Str., Moscow, Russia',
    }],
  }, {
    value: 'CreditCard1',
    caption: 'Credit card ending in 3211',
    detail: [{
      title: 'Card type:',
      value: 'MasterCard',
    }, {
      title: 'Card number:',
      value: '**** **** **** 3211',
    }, {
      title: 'Card holder:',
      value: 'INNA OLEGOVA',
    }, {
      title: 'Billing address:',
      value: '678 Broadway, 9th floor New York, NY 10003, US',
    }],
  }],
  currentBilling: 'CreditCard1',
  billingToRemove: null,
})

const mutations = {
  selectBilling (state, value) {
    state.currentBilling = value
  },
  insertBillingToRemove (state, value) {
    state.billingToRemove = value
  },
  removeItemBilling (state) {
    state.billingMethods = state.billingMethods.filter(item => item.value !== state.billingToRemove)
  },
}

const getters = {
  getCurrentBilling (state) {
    return state.billingMethods.find(item => item.value === state.currentBilling)
  },
}

export default {
  state,
  mutations,
  getters,
}
