import {locationsAPI} from '@/api/locations';
import {bookingAPI} from '../../../api/booking';

const state = () => ({
  routeAlerts: [],
})

const mutations = {
  setRouteAlerts (state, data) {
    state.routeAlerts = data
  },
  removeItem (state, id) {
    state.routeAlerts = state.routeAlerts.filter((item) => id !== item.id)
  },
}

const actions = {
  async searchLocationsRoutes ({commit}, data) {
    try {
      const result = await locationsAPI.searchLocations(data)
      return result
    } catch (e) {
      return null
    }
  },
  async addRouteAlert ({commit}, data) {
    try {
      await bookingAPI.addRouteAlert(data)
      const result = await bookingAPI.getRouteAlerts()
      commit('setRouteAlerts', result.data)
    } catch (e) {
      console.log('adding failed')
    }
  },
  async getRouteAlerts ({commit}) {
    try {
      const result = await bookingAPI.getRouteAlerts()
      commit('setRouteAlerts', result.data)
    } catch (e) {
      console.log('no alerts founded')
    }
  },
  async getRouteAlertsAdmin ({commit}) {
    try {
      const result = await bookingAPI.getRouteAlertsAdmin()
      commit('setRouteAlerts', result.data)
    } catch (e) {
      console.log('no alerts founded')
    }
  },
  async deleteRouteAlert ({commit}, data) {
    try {
      await bookingAPI.deleteRouteAlert(data)
      commit('removeItem', data)
    } catch (e) {
      console.log('deleting failed')
    }
  },
  async toggleRouteAlert ({commit}, data) {
    try {
      await bookingAPI.toggleRouteAlert(data)
      const result = await bookingAPI.getRouteAlerts()
      commit('setRouteAlerts', result.data)
    } catch (e) {
      console.log('deleting failed')
    }
  },
}

export default {
  state,
  mutations,
  actions,
};
