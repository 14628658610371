<template>
  <div class="stub">
    <span class="stub__icon">
      <SvgIcon name="rotate-phone"/>
    </span>
    <span class="stub__text">Please rotate your phone to be vertical</span>
  </div>
</template>

<script>
  import './Stub.styl'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

  export default {
    name: 'Stub',
    components: {
      SvgIcon,
    },
  }
</script>
