<template>
  <svg :class="className" xmlns="http://www.w3.org/2000/svg">
    <use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink"/>
  </svg>
</template>

<script>
  export default {
    name: 'svg-icon',

    props: {
      name: {
        type: String,
        required: true,
      },
    },

    computed: {
      iconPath () {
        return require(`@/assets/svg/${this.name}.svg`).default.url
      },

      className () {
        return 'icon__' + this.name
      },
    },
  }
</script>
