import {instance} from '../index';

export const subscriptionApi = {
  getSubscriptions () {
    return instance.get('accounts/subscriptions')
  },
  setSubscriptionsStatus (id, statusId) {
    return instance.patch(`accounts/subscriptions/${id}`, {
      subscription: {
        statusId,
      },
    })
  },
  setSubscriptionsPlan (id, planId) {
    return instance.patch(`accounts/subscriptions/${id}`, {
      subscription: {
        planId,
      },
    })
  },
  setSubscriptionsPaymentMethod (id, paymentMethodId) {
    return instance.patch(`accounts/subscriptions/${id}`, {
      subscription: {
        activationMethod: {
          paymentMethodId,
        },
      },
    })
  },
  activateSubscription (id) {
    return instance.post(`accounts/subscriptions/${id}/activate`)
  },
  upgradeSubscription (id, planId) {
    return instance.post(`accounts/subscriptions/${id}/upgrade`, {
      subscription: {
        planId,
      },
    })
  },
  createSubscription (planId) {
    return instance.post('accounts/subscriptions', {
      subscription: {
        planId,
      },
    })
  },
  cancelSubscription (subscriptionId) {
    return instance.post(`accounts/subscriptions/${subscriptionId}/cancel`)
  },
  renewSubscription (subscriptionId) {
    return instance.post(`accounts/subscriptions/${subscriptionId}/renew`)
  },
}
