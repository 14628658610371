import {adminRequestsAPI} from '@/api/dashboard/adminRequests'

const state = () => ({
  currentFlightRequest: null,
  providerRequests: [],
  providerRequestsCount: 0,
  providerRequestsPageCount: 0,
  currentRequest: null,
  currentCompany: null,
  exportBlob: '',
})

const mutations = {
  addConfirmationRequest (state, id) {
    state.providerRequests = state.providerRequests.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          confirmation: true,
        }
      }
      return item
    })
  },
  insertCurrentRequest (state, id) {
    state.currentRequest = id
  },
  removeItemRequest (state) {
    state.providerRequests = state.providerRequests.filter(item => item.id !== state.currentRequest)
  },
  insertCurrentFlightRequest (state, id) {
    state.currentFlightRequest = id
  },
  removeItemFlightRequest (state) {
    state.list = state.list.filter(item => item.id !== state.currentFlightRequest)
  },
  setProviderRequests (state, data) {
    state.providerRequests = data.accountsView;
    state.providerRequestsCount = data.accountsCount;
    state.providerRequestsPageCount = data.pageCount;
  },
  setCurrentCompany (state, company) {
    state.currentCompany = company;
  },
  async exportToExcel (state, type) {
    const result = await adminRequestsAPI.exportToExcel(type)
    const file = window.URL.createObjectURL(new Blob([result.data]))
    // state.exportBlob = file;
    const fileUrl = document.createElement('downloadlink');
    fileUrl.href = file;
    fileUrl.setAttribute('download', type + '_report_' + new Date().toLocaleDateString().replaceAll('/', '-') + '.csv')
    document.body.appendChild(fileUrl);
    fileUrl.click();
    document.body.removeChild(fileUrl);
  },
}
const actions = {
  async getProviderRequests ({commit}, data) {
    try {
      const result = await adminRequestsAPI.getApRequests(data.pageNumber, data.pageSize);
      commit('setProviderRequests', result.data);
    } catch (e) {
      console.log('e', e)
    }
  },
}
export default {
  state,
  mutations,
  actions,
}
