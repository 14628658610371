<template>
  <div class="spinner">
    <div class="spinner__item" v-for="item in 8" :key="item"></div>
  </div>
</template>

<script>
  export default {
    name: 'Spinner',
  }
</script>
