import {bidsAPI} from '@/api/bids';

const state = () => ({
  bidList: [],
  bidListCount: 0,
  bidListPageCount: 0,
  bidListBefore: [],
  bidListCountBefore: 0,
  bidListPageCountBefore: 0,
  nonActualBidList: [],
  isNonActualBidListLoading: false,
})

const mutations = {
  setBidList (state, {data, historical}) {
    const {pageCount, bidsCount, bidsView} = data.isSuccessfull ? data.item.value : {};

    if (historical) {
      state.bidListBefore = bidsView;
      state.bidListCountBefore = bidsCount;
      state.bidListPageCountBefore = pageCount;
    } else {
      state.bidList = bidsView;
      state.bidListCount = bidsCount;
      state.bidListPageCount = pageCount;
    }
  },
  setNonActualBidList (state, data) {
    state.nonActualBidList = data?.item?.value || [];
    state.isNonActualBidListLoading = false
  },
  setNonActualBidListLoading (state) {
    state.isNonActualBidListLoading = true
  },
}
const actions = {
  async getBidList ({commit}, data) {
    const {historical, pageNumber, pageSize} = data;

    try {
      const result = await bidsAPI.getAdminBidsPage(historical, pageNumber, pageSize);
      commit('setBidList', {data: result.data, historical});
    } catch (e) {
      console.log('e', e)
    }
  },
  async getNonActualBidList ({commit}, flightId) {
    commit('setNonActualBidListLoading');

    try {
      const result = await bidsAPI.getNonActualBidList(flightId);
      commit('setNonActualBidList', result.data);
    } catch (e) {
      console.log('e', e)
    }
  },
  async cancelBid ({commit, state}, id) {
    try {
      await bidsAPI.cancelBid(id);
    } catch (e) {
      console.log('e', e)
    }
  },
  async unacceptBid ({commit, state}, id) {
    try {
      await bidsAPI.unacceptBid(id);
    } catch (e) {
      console.log('e', e)
    }
  },
  async exportBidList ({commit, state}, isHistorical) {
    try {
     return await bidsAPI.exportBidList(isHistorical);
    } catch (e) {
      console.log('e', e)
    }
  },
}
export default {
  state,
  mutations,
  actions,
}
