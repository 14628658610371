import {instance} from '../index';

export const flyCalAPI = {
  createFlyCal (data) {
    return instance.post('bookings/flycal/create', {
     direction: data.direction,
     bookingCategory: data.bookingCategory,
     firstDepartureId: data.firstDepartureId,
     secondDepartureId: data.secondDepartureId,
     thirdDepartureId: data.thirdDepartureId,
     firstArrivalId: data.firstArrivalId,
     secondArrivalId: data.secondArrivalId,
     thirdArrivalId: data.thirdArrivalId,
     departureDate: data.departureDate,
     returnDate: data.returnDate,
     passengersNumber: data.passengersNumber,
     budgetGoal: data.budgetGoal,
     departureReroutingRadius: data.departureReroutingRadius,
     arrivalReroutingRadius: data.arrivalReroutingRadius,
     notes: '',
     isMobileRequest: false,
    })
  },
  showFlyCals () {
    return instance.get('bookings/flycal/get/flyer');
  },
  getFlyCals () {
    return instance.get('reports/admin/flycal/list');
  },
  async checkFlyCalStatus () {
    try {
      const returnVal = await instance.get('accounts/checkFlyCalStatus');
      // console.log(returnVal);
      if (returnVal.status === 401) {
        return false;
      } else {
        return true;
      }
    } catch (e) {
      return false;
    }
  },
  submitFlyCalPayment (data) {
    return instance.post('accounts/paymethods/flyCalPayment', {
        name: data.name,
        address: data.address,
        cardNumber: data.cardNumber,
        expMonth: data.expMonth,
        expYear: data.expYear,
        cvc: data.cvc,
    });
  },
}
