import {subscriptionApi} from '@/api/admin/subscriptions';

const state = () => ({
  subscriptionAnalyticsList: [],
  subscriptionAnalyticsListPageCount: 0,
  subscriptionAnalyticsListCount: 0,
})

const mutations = {
  setSubscriptionAnalyticsList (state, data) {
    state.subscriptionAnalyticsList = data.subscriptionAnalytics;
    state.subscriptionAnalyticsListPageCount = data.pageCount;
    state.subscriptionAnalyticsListCount = data.subscriptionsCount;
  },
}

const actions = {
  async getSubscriptionAnalyticsList ({commit}, data) {
    try {
      const result = await subscriptionApi.getSubscriptionAnalyticsPaginatedList(data.pageNumber, data.pageSize);
      commit('setSubscriptionAnalyticsList', result.data);
    } catch (e) {
      console.log('e', e)
    }
  },
}

export default {
  state,
  mutations,
  actions,
}