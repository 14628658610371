import { flyerRequestsAPI } from '@/api/dashboard/requests';

const state = () => ({
  requests: [],
})

const mutations = {
  setRequests (state, data) {
    state.requests = data.item.value
  },
  removeRequests (state, id) {
    state.requests = state.requests.filter((item) => id !== item.id)
  },
}

const actions = {
  async getRequests ({commit}) {
    try {
      const result = await flyerRequestsAPI.getRequests()
      commit('setRequests', result.data)
    } catch (e) {
      console.log('no requests founded')
    }
  },
  async deleteRequest ({commit}, data) {
    try {
      await flyerRequestsAPI.deleteRequest(data)
      commit('removeRequests', data)
    } catch (e) {
      console.log('removing failed')
    }
  },
  async submitRequest ({commit}, data) {
    try {
      await flyerRequestsAPI.submitRequest(data)
      const result = await flyerRequestsAPI.getRequests()
      commit('setRequests', result.data)
    } catch (e) {
      console.log('submitting failed')
    }
  },
}

export default {
  state,
  mutations,
  actions,
}
