<template>
  <modal class="RequestPopupWrapper" name="RequestPayment" transition="pop-out" :adaptive="true" @opened="opened">
    <div class="tingle-modal" id="request-payment">
      <div class="request-popup request-popup--payment">
        <form
          class="request-popup__container whitePicker"
          @submit.prevent="submitHandler"
        >
          <div class="request-popup__top">
            <div class="request-popup__title request-popup__title--white">Payment Details</div>
            <div class="request-popup__title request-popup__title--cost">$250</div>
            <div class="request-popup__subtitle">Manual Request Reservation Fee</div>
            <div class="request-popup__top-form">
              <label class="request-popup__label">
                <div class="request-popup__caption">name:</div>
                <div class="request-popup__field-wrapper">
                  <input
                    class="request-popup__field"
                    type="text"
                    v-model="name"
                    autocomplete="off"
                  />
                </div>
                <span
                    v-if="$v.name.$dirty && !$v.name.required"
                    class="form__field-invalid"
                  >
                  This field is required
                </span>
              </label>
<!--              <label class="request-popup__label">-->
<!--                <div class="request-popup__caption">address:</div>-->
<!--                <div class="request-popup__field-wrapper">-->
<!--                  <input-->
<!--                    class="request-popup__field"-->
<!--                    type="text"-->
<!--                    v-model="address"-->
<!--                    autocomplete="off"-->
<!--                  />-->
<!--                </div>-->
<!--              </label>-->
              <div class="request-popup__radioGroup">
                <label class="form__label--radio request-popup__label-radio">
                  <input
                    class="request-popup__field-radio"
                    type="radio"
                    v-model="payment"
                    data-idpayment="1"
                    value="paymentCredit"
                  />
                  <i class="request-popup__radio-icon"></i>
                  <div class="request-popup__caption request-popup__caption--radio">Credit Card</div>
                </label>
<!--                <label class="form__label&#45;&#45;radio request-popup__label-radio">-->
<!--                  <input-->
<!--                    class="request-popup__field-radio"-->
<!--                    type="radio"-->
<!--                    v-model="payment"-->
<!--                    data-idpayment="2"-->
<!--                    value="paymentACH"-->
<!--                  />-->
<!--                  <i class="request-popup__radio-icon"></i>-->
<!--                  <div class="request-popup__caption request-popup__caption&#45;&#45;radio">Ach</div>-->
<!--                </label>-->
              </div>
            </div>
          </div>
          <div class="request-popup__bottom">
            <div
              class="request-popup__bottom-tab"
              data-idpayment="1"
              :style="{display: payment === 'paymentCredit' ? 'flex' : 'none'}"
            >
              <label class="request-popup__label request-popup__label--alt">
                <div class="request-popup__caption request-popup__caption--alt">card number:</div>
                <div class="request-popup__field-wrapper">
                  <input
                    class="request-popup__field request-popup__field--alt js-cardnumber-mask"
                    type="text"
                    v-model="cardNumber"
                    v-mask="'####-####-####-####'"
                    autocomplete="off"
                  />
                </div>
                <span
                    v-if="$v.cardNumber.$dirty && !$v.cardNumber.required"
                    class="form__field-invalid"
                  >
                  This field is required
                </span>
              </label>
              <label class="request-popup__label request-popup__label--alt">
                <div class="request-popup__caption request-popup__caption--alt">Expiration:
                  <span style="font-size:.5em;">(mm/yyyy)</span>
                </div>
                <div class="request-popup__field-wrapper">
                  <input
                    class="request-popup__field request-popup__field--alt"
                    type="text"
                    v-model="date"
                    v-mask="'##/####'"
                    autocomplete="off"
                  />
                </div>
                <span
                    v-if="$v.date.$dirty && !$v.date.required"
                    class="form__field-invalid"
                  >
                  This field is required
                </span>
              </label>
              <label class="request-popup__label request-popup__label--alt">
                <div class="request-popup__caption request-popup__caption--alt">cvv:</div>
                <div class="request-popup__field-wrapper">
                  <input
                    class="request-popup__field request-popup__field--alt js-cvv-mask"
                    type="text"
                    v-model="cvv"
                    v-mask="'####'"
                    autocomplete="off"
                  />
                </div>
                <span
                    v-if="$v.cvv.$dirty && !$v.cvv.required"
                    class="form__field-invalid"
                  >
                  This field is required
                </span>
              </label>
<!--              <label class="request-popup__label request-popup__label&#45;&#45;alt">-->
<!--                <div class="request-popup__caption request-popup__caption&#45;&#45;alt">billing address:</div>-->
<!--                <div class="request-popup__field-wrapper">-->
<!--                  <input-->
<!--                    class="request-popup__field request-popup__field&#45;&#45;alt"-->
<!--                    type="text"-->
<!--                    v-model="billingAddress"-->
<!--                    autocomplete="off"-->
<!--                  />-->
<!--                </div>-->
<!--              </label>-->
            </div>
<!--            <div-->
<!--              class="request-popup__bottom-tab"-->
<!--              data-idpayment="2"-->
<!--              :style="{display: payment === 'paymentACH' ? 'flex' : 'none'}"-->
<!--            >-->
<!--              <label class="request-popup__label request-popup__label&#45;&#45;alt">-->
<!--                <div class="request-popup__caption request-popup__caption&#45;&#45;alt">Bank name:</div>-->
<!--                <div class="request-popup__field-wrapper">-->
<!--                  <input-->
<!--                    class="request-popup__field request-popup__field&#45;&#45;alt"-->
<!--                    type="text"-->
<!--                    v-model="bankName"-->
<!--                    autocomplete="off"-->
<!--                  />-->
<!--                </div>-->
<!--              </label>-->
<!--              <label class="request-popup__label request-popup__label&#45;&#45;alt">-->
<!--                <div class="request-popup__caption request-popup__caption&#45;&#45;alt">ABA/Routing Number:</div>-->
<!--                <div class="request-popup__field-wrapper">-->
<!--                  <input-->
<!--                    class="request-popup__field request-popup__field&#45;&#45;alt"-->
<!--                    type="text"-->
<!--                    v-model="aba"-->
<!--                    autocomplete="off"-->
<!--                  />-->
<!--                </div>-->
<!--              </label>-->
<!--              <label class="request-popup__label request-popup__label&#45;&#45;alt">-->
<!--                <div class="request-popup__caption request-popup__caption&#45;&#45;alt">Bank address:</div>-->
<!--                <div class="request-popup__field-wrapper">-->
<!--                  <input-->
<!--                    class="request-popup__field request-popup__field&#45;&#45;alt"-->
<!--                    type="text"-->
<!--                    v-model="bankAddress"-->
<!--                    autocomplete="off"-->
<!--                  />-->
<!--                </div>-->
<!--              </label>-->
<!--              <label class="request-popup__label request-popup__label&#45;&#45;alt">-->
<!--                <div class="request-popup__caption request-popup__caption&#45;&#45;alt">Account name:</div>-->
<!--                <div class="request-popup__field-wrapper">-->
<!--                  <input-->
<!--                    class="request-popup__field request-popup__field&#45;&#45;alt"-->
<!--                    type="text"-->
<!--                    v-model="accountName"-->
<!--                    autocomplete="off"-->
<!--                  />-->
<!--                </div>-->
<!--              </label>-->
<!--            </div>-->
            <button class="buttonGreen request-popup__button">SEND PAYMENT</button>
          </div>
        </form>
        <div class="request-popup__close js-request-popup-close" @click="$modal.hide('RequestPayment')">
          <SvgIcon name="close-white-2"/>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
  import '@/components/common/modals/RequestModals.styl';
  import {required} from 'vuelidate/lib/validators';

  export default {
    name: 'RequestPayment',
    data: () => ({
      name: '',
      // address: '',
      payment: 'paymentCredit',
      cardNumber: '',
      date: '',
      cvv: '',
      // billingAddress: '',
      // bankName: '',
      // aba: '',
      // bankAddress: '',
      // accountName: ''
    }),
    validations: {
      name: { required },
      cardNumber: { required },
      date: { required },
      cvv: { required },
    },
    computed: {
      manuallyReqId () {
        return this.$store.state.search.manuallyReqId
      },
    },
    methods: {
      opened () {
        this.$root.fields.changeRadio('.request-popup__field-radio');
      },
      async submitHandler () {
        const date = this.date.split('/')

        if (this.$v.$invalid) {
          this.$v.$touch()
          return false
        }

        const data = {
          address: 'n/a',
          cardNumber: this.cardNumber,
          cvc: this.cvv,
          expMonth: +date[0],
          expYear: +date[1],
          name: this.name,
          reqGuid: this.manuallyReqId,
        }

        const result = await this.$store.dispatch('sendPaymentDetails', data)
        if (result) {
          this.$modal.hide('RequestPayment')

          const message = {
            title: null,
            text: null,
          }

          if (Object.keys(result)[0] === 'Error') {
            message.title = 'Error'
            message.text = result.Error
          } else {
            message.title = 'Payment Received'
            message.text = 'We will be in touch shortly with your requested availabilities.'
          }

          this.$modal.show('ThanksPopup', {message})
        }
      },
    },
    components: {
      SvgIcon,
    },
  };
</script>
