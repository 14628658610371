var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
      'box box--footer': true,
      'box--footer-report': _vm.type === 'report',
      'box--footer-blog': _vm.type === 'blog'
    }},[_c('div',{staticClass:"box__inner"},[_c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer__social"},[_c('div',{staticClass:"social"},[_c('a',{staticClass:"social__item",attrs:{"href":"https://instagram.com/flyjetsflying/","target":"_blank"}},[_c('SvgIcon',{attrs:{"name":"inst"}})],1),_c('a',{staticClass:"social__item",attrs:{"href":"https://twitter.com/flyjetsflying","target":"_blank"}},[_c('SvgIcon',{attrs:{"name":"tw"}})],1),_c('a',{staticClass:"social__item",attrs:{"href":"https://facebook.com/flyjetsflying/","target":"_blank"}},[_c('SvgIcon',{attrs:{"name":"fb"}})],1)]),_c('router-link',{staticClass:"footer__link",attrs:{"to":"/"}},[_vm._v("FlyJets")])],1),_c('div',{staticClass:"footer__nav"},[_c('div',{staticClass:"nav"},_vm._l((_vm.nav),function(link){return _c('div',{key:link.text,class:{
              'nav__item': true,
              'nav__item-fly-report': link.class === 'fly-report' && _vm.type !== 'blog'
              }},[(link.class !== 'fly-report')?_c('router-link',{staticClass:"nav__link",attrs:{"to":link.href}},[_vm._v(_vm._s(link.text))]):_vm._e(),(link.class === 'fly-report')?_c('a',{staticClass:"nav__link nav__link-fly-report",attrs:{"href":link.href}},[_vm._v(_vm._s(link.text))]):_vm._e()],1)}),0)]),_c('div',{staticClass:"footer__message"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }