import {instance} from './index';

export const historyAPI = {
  setSearchHistory (data) {
    return instance.post('history/create', {
      arrivalDate: data.arrivalDate,
      arrivalId: data.arrivalId,
      bookingType: data.bookingType,
      departureDate: data.departureDate,
      departureId: data.departureId,
      passengers: data.passengers,
    });
  },
};
