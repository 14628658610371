export const subscriptionStatusConstants = {
  new: {
    name: 'New',
    code: 'NEW',
  },
  waitingForActivation: {
    name: 'Waiting for Activation',
    code: 'WAITING_FOR_ACTIVATION',
  },
  activationFailed: {
    name: 'Activation Failed',
    code: 'ACTIVATION_FAILED',
  },
  activation: {
    name: 'Activation',
    code: 'ACTIVATION',
  },
  active: {
    name: 'Active',
    code: 'ACTIVE',
  },
  expired: {
    name: 'Expired',
    code: 'EXPIRED',
  },
  canceled: {
    name: 'Canceled',
    code: 'CANCELED',
  },
  abandoned: {
    name: 'Abandoned',
    code: 'ABANDONED',
  },
}
