import {instance} from './index';

export const searchAPI = {
  setSearchRequest (data) {
    return instance.post('bookings/searchCharterAircrafts', {
      arrivalId: data.arrivalId,
      arrivalName: data.arrivalName,
      bookingType: data.bookingType,
      departureDate: data.departureDate,
      departureId: data.departureId,
      departureName: data.departureName,
      direction: data.direction,
      pax: data.pax,
      returnDate: data.returnDate,
      selectedBookingTypes: data.selectedBookingTypes,
    });
  },
  setSearchRequestCharterFlights (data) {
    return instance.post('bookings/searchCharterFlights', {
      arrivalId: data.arrivalId,
      arrivalName: data.arrivalName,
      bookingType: data.bookingType,
      departureDate: data.departureDate,
      departureId: data.departureId,
      departureName: data.departureName,
      direction: data.direction,
      pax: data.pax,
      returnDate: data.returnDate,
      selectedBookingTypes: data.selectedBookingTypes,
    });
  },
  setSearchResults (data) {
    return instance.post('mobile/search', data);
  },
  createManualRequest (data) {
    return instance.post('bookings/flightsrequests/create', {
      aircraftType: data.aircraftType,
      direction: data.direction,
      bookingType: data.bookingType,
      departureId: data.departureId,
      arrivalId: data.arrivalId,
      departureDate: data.departureDate,
      returnDate: data.returnDate,
      pax: data.pax,
      maxPrice: data.maxPrice,
      notes: data.notes,
    })
  },
  getFeaturedFlights () {
    return instance.get('bookings/flights/featured')
  },
  getAircraftSearchGallery (id) {
    return instance.get(`bookings/popup/${id}`)
  },
  sendPaymentDetails (data) {
    return instance.post('accounts/paymethods/manualRequestDeposit', {
      address: data.address,
      cardNumber: data.cardNumber,
      cvc: data.cvc,
      expMonth: data.expMonth,
      expYear: data.expYear,
      name: data.name,
      reqGuid: data.reqGuid,
    })
  },
}
