const state = () => ({
  travelers: [
    {
      name: 'Jessica Leigh Fisher',
      info: 'Was invited 03/04/19',
      edit: true,
      ticket: 'ERER31',
      seat: 1,
      email: 'User@gmail.com',
      image: 'https://scontent-arn2-1.cdninstagram.com/v/t51.2885-15/e35/c0.180.1440.1440a/s150x150/97154150_2687424718158067_6673380791962285278_n.jpg?_nc_ht=scontent-arn2-1.cdninstagram.com&_nc_cat=107&_nc_ohc=OPBnCTGRKykAX_o3YHe&oh=726324b17d810d2a9c75d70b4f8f2118&oe=5F5910C8',
    },
    {
      name: 'Amanda Shtern',
      info: 'Was invited 03/04/19',
      edit: true,
      ticket: 'GHHJ66D',
      seat: 2,
      email: 'User@gmail.com',
      image: 'https://imgs.heart.co.uk/images/148280?crop=16_9&width=660&relax=1&signature=YshtgmY6miMlwt_mDgWwBGHF19k=',
    },
    {
      name: 'John Rem',
      info: 'Was invited 03/04/19',
      edit: true,
      ticket: 'RTRGFG45',
      seat: 1,
      email: 'User@gmail.com',
      image: 'https://i.pinimg.com/originals/9d/cc/8e/9dcc8e5fe30be44b47963721a4011c9a.jpg',
    },
    {
      name: 'Kate Mary Braun',
      info: 'Was invited 03/04/19',
      edit: true,
      ticket: 'DFDFEF455',
      seat: 3,
      email: 'User@gmail.com',
      image: 'https://www.hellomagazine.com/imagenes/film/2020042788802/normal-people-bbc-drama-who-is-in-the-cast/0-425-827/lorraine-normal-people-z.jpg',
    },
  ],
  currentTraveler: null,
})

const mutations = {
  insertCurrentTraveler (state, id) {
    state.currentTraveler = id
  },
  removeItem (state) {
    state.travelers = state.travelers.filter((item, index) => index !== state.currentTraveler)
  },
}

export default {
  state,
  mutations,
}
