import {subscriptionApi} from '@/api/flyer/subscriptions';

const state = () => ({
  enrichedSubscriptions: null,
})

const mutations = {
  setEnrichedSubscriptions (state, subscriptions) {
    state.enrichedSubscriptions = subscriptions
  },
}

const actions = {
  async fetchEnrichedSubscriptions ({state, commit, dispatch, rootGetters}, forceFetch = false) {
    if (!state.enrichedSubscriptions || forceFetch) {
      const result = await subscriptionApi.getSubscriptions()

      await Promise.all([
        dispatch('fetchSubscriptionsStatuses'),
        dispatch('fetchEnrichedSubscriptionsPlans'),
        dispatch('getPaymethods'),
      ])

      const subscriptions = result.data.subscriptions;

      for (const subscription of subscriptions) {
        subscription.status = rootGetters.getSubscriptionsStatusById(subscription.statusId)
        subscription.plan = rootGetters.getEnrichedSubscriptionsPlanById(subscription.planId)

        if (subscription.paymentMethodId) {
          subscription.paymentMethod = rootGetters.getPaymentMethodById(subscription.paymentMethodId)
        }
      }

      commit('setEnrichedSubscriptions', subscriptions)
    }
  },
}

const getters = {
  getEnrichedSubscriptions (state) {
    if (!state.enrichedSubscriptions) return []
    return state.enrichedSubscriptions
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
