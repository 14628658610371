import { usersAPI } from '@/api/accounts'

const state = () => ({
  personalInfo: {
    firstName: null,
    middleName: null,
    lastName: null,
    dateOfBirth: null,
    email: null,
    mobile: null,
    address: null,
    companyName: null,
    companyAddress: null,
    companyEmail: null,
    companyPhone: null,
  },
  familyMembers: [],
  additionalInfo: [{
    title: 'Aircraft provider type:',
    list: [{
      title: 'Aircraft broker',
      img: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1601236934/FlyJets/team/Jessica_xle8fa.jpg',
      name: 'Jessica Leigh Fisher',
    }, {
      title: 'Aircraft manager',
      img: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784189/FlyJets/avatar_zmfk8x.jpg',
      name: 'Amanda Shtern',
    }, {
      title: 'Aircraft operator',
      img: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602199687/FlyJets/avatar_s1o4ii.jpg',
      name: 'John Rem',
    }, {
      title: 'Aircraft owner',
      img: 'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602784189/FlyJets/avatar_zmfk8x.jpg',
      name: 'Kate Mary Braun',
    }],
  }, {
    title: 'Argus aircraft operator safety rating:',
    list: [{
      title: 'Argus platinum rating',
      text: 'Argus Platinum; Wyvern Wingman',
    }, {
      title: 'Argus gold plus rating',
      text: 'Argus Platinum; Wyvern Wingman',
    }, {
      title: 'Argus gold rating',
      text: 'Argus Platinum; Wyvern Wingman',
    }, {
      title: 'Not argus-rated',
      text: 'Argus Platinum; Wyvern Wingman',
    }],
  }, {
    title: 'Wyvern aircraft operator certification:',
    list: [{
      title: 'Wyvern wingman certified operator',
      text: 'Argus Platinum; Wyvern Wingman',
    }, {
      title: 'Not wyvern wingman certified operator',
      text: 'Argus Platinum; Wyvern Wingman',
    }],
  }, {
    title: 'Wyvern aircraft operator registration:',
    list: [{
      title: 'Wyvern registered aircraft operator',
      text: 'Argus Platinum; Wyvern Wingman',
    }, {
      title: 'Not wyvern registered aircraft operator',
      text: 'Argus Platinum; Wyvern Wingman',
    }],
  }, {
    title: 'Wyvern aircraft broker registration:',
    list: [{
      title: 'Wyvern registered aircraft broker',
      text: 'Argus Platinum; Wyvern Wingman',
    }, {
      title: 'Not wyvern registed aircraft broker',
      text: 'Argus Platinum; Wyvern Wingman',
    }],
  }],
})

const mutations = {
  removeItem (state, id) {
    state.familyMembers = state.familyMembers.filter((item) => id !== item.id)
  },
  setProfileData (state, data) {
    state.personalInfo.firstName = data.firstName
    state.personalInfo.middleName = data.middleName
    state.personalInfo.lastName = data.lastName
    state.personalInfo.dateOfBirth = data.dateOfBirth
    state.personalInfo.email = data.email
    state.personalInfo.mobile = data.mobile
    state.personalInfo.address = data.address
    state.personalInfo.companyName = data.companyName
    state.personalInfo.companyAddress = data.companyAddress
    state.personalInfo.companyEmail = data.companyEmail
    state.personalInfo.companyPhone = data.companyPhone
  },
  setProfileDataProvider (state, data) {
    state.personalInfo.firstName = data.firstName
    state.personalInfo.middleName = data.middleName
    state.personalInfo.lastName = data.lastName
    state.personalInfo.dateOfBirth = data.dateOfBirth
    state.personalInfo.email = data.email
    state.personalInfo.mobile = data.mobile
    state.personalInfo.companyName = data.companyName
    state.personalInfo.companyAddress = data.companyAddress
    state.personalInfo.companyEmail = data.companyEmail
    state.personalInfo.companyPhone = data.companyPhone
  },
  setFamilyMembers (state, data) {
    state.familyMembers = data
  },
}

const actions = {
  async getProfile ({commit}) {
    try {
      const result = await usersAPI.getAccounts()
      commit('setProfileData', result.data)
      commit('setFamilyMembers', result.data.familyMembers)
    } catch (e) {
      console.log('no accounts founded')
    }
  },
  async updatePersonalInfo ({commit}, data) {
    try {
      await usersAPI.updatePersonalInfo(data)
      commit('setProfileData', data)
    } catch (e) {
      console.log('updating failed')
    }
  },
  async updatePersonalInfoProvider ({commit}, data) {
    try {
      await usersAPI.updatePersonalInfoProvider(data)
      commit('setProfileDataProvider', data)
    } catch (e) {
      console.log('updating failed')
    }
  },
  async addFamilyMember ({commit}, data) {
    try {
      await usersAPI.addFamilyMember(data)
      const result = await usersAPI.getAccounts()
      commit('setFamilyMembers', result.data.familyMembers)
    } catch (e) {
      console.log('adding failed')
    }
  },
  async deleteFamilyMember ({commit}, data) {
    try {
      await usersAPI.deleteFamilyMember(data)
      commit('removeItem', data)
    } catch (e) {
      console.log('removing failed')
    }
  },
}

export default {
  state,
  mutations,
  actions,
}
