<template>
  <modal class="white-popup" name="ComingSoonPopup" transition="pop-out" :adaptive="true">

<div class="comingSoonPopup" id="comingSoonPopup">
     <!-- <div class="comingSoonPopup__decor" style="background-image:url('/img/assets/common/comingSoon/planet.png')"></div> -->
        <div class="comingSoonPopup__inner">
            <div class="comingSoonPopup__icon">
                <img src="@/assets/img/comingSoonMail.png">
            </div>
            <div class="comingSoonPopup__title">Coming Soon</div>
            <div class="comingSoonPopup__text">
                <article>
                    <p>Work in progress; this feature will be available as soon as it’s complete. Please check back for updates, and let us know how we can help in the interim.</p>
                        <p><b>We look forward to hearing from you</b> by phone at <br/> <a href="tel:+12128455137">+1&nbsp;(212)&nbsp;845&#8209;5137</a> and by email at <a href="mailto:FLY@flyjets.com">FLY@flyjets.com</a>
                    </p>
                </article>
            </div>
        </div>
        <button class="fancybox-button fancybox-close-small" @click="$modal.hide('ComingSoonPopup')">
        <SvgIcon name="close-white"/>
        </button>
    </div>
  </modal>
</template>
<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import './ComingSoonPopup.styl'

  export default {
    name: 'ComingSoonPopup',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    components: {
      SvgIcon,
    },
  }
</script>
