<template>
  <div id="app" class="rootBox">
    <component :is="layout">
      <router-view/>
    </component>
    <LogInPopup/>
    <SignUpPopup/>
    <ForgotPasswordPopup/>
    <MainFlyGreenPopup/>
    <OptInToFlyGreen/>
    <TransactionPopup/>
    <PaymentPopup/>
    <!-- <FlyDatesPopup/> -->
    <!-- <TransactionBigPopup/> -->
    <ComingSoonPopup/>
    <FlyCalFrontPaymentProcessedPopup/>
  </div>
</template>

<script>
  import './App.styl'
  import '@/assets/stylus/form.styl'
  import '@/views/profile/Profile.styl'
  import '@/views/main/mapBlock/mapBlock.styl'
  import LogInPopup from '@/components/common/modals/LogInPopup'
  import SignUpPopup from '@/components/common/modals/SignUpPopup'
  // import FlyCalLoginPopup from '@/components/common/modals/FlyCalLoginPopup'
  // import FlyCalSignUpPopup from '@/components/common/modals/FlyCalSignupPopup'
  // import LoginOrSignupPopup from '@/components/common/modals/LoginOrSignupPopup'
  import FlyCalFrontPaymentProcessedPopup from '@/components/common/modals/FlyCalFrontPaymentProcessedPopup'
  import MainFlyGreenPopup from '@/components/common/modals/MainFlyGreenPopup'
  import ComingSoonPopup from '@/components/common/modals/ComingSoonPopup'
  import CommonLayout from '@/layouts/CommonLayout'
  import EmptyLayout from '@/layouts/EmptyLayout'
  import ForgotPasswordPopup from '@/components/common/modals/ForgotPasswordPopup';
  // const ForgotPasswordPopup = () => ('@/components/common/modals/ForgotPasswordPopup')
  const OptInToFlyGreen = () => import('@/components/common/modals/OptInToFlyGreen')
  const BookingLayout = () => import('@/layouts/BookingLayout')
  const DashboardLayout = () => import('@/layouts/DashboardLayout')
  const ReportLayout = () => import('@/layouts/ReportLayout')
  const BlogLayout = () => import('@/layouts/BlogLayout')
  const TransactionPopup = () => import('@/components/common/modals/TransactionPopup')
  const PaymentPopup = () => import('@/components/common/modals/PaymentPopup')
  // const FlyDatesPopup = () => import('@/components/common/modals/FlyDatesPopup')
 // const TransactionBigPopup = () => import('@/components/common/modals/TransactionBigPopup')

  export default {
    // checking authorization of the user
    beforeCreate () {
      if (this.$cookies.get('user') || this.$cookies.get('token')) {
        const info = this.$cookies.get('user')
        const token = this.$cookies.get('token')
        this.$store.commit('setUser', {
          info,
          token,
        })
      }
    },
    mounted () {
      document.documentElement.setAttribute('data-theme', this.$store.state.view.theme);
    },
    computed: {
      layout () {
        return (this.$route.meta.layout || 'empty') + '-layout'
      },
    },
    metaInfo: {
      title: 'FLYJETS',
    },
    components: {
      TransactionPopup,
      LogInPopup,
      // LoginOrSignupPopup,
      // FlyCalLoginPopup,
      // FlyCalSignUpPopup,
      FlyCalFrontPaymentProcessedPopup,
      SignUpPopup,
      ForgotPasswordPopup,
      // TransactionBigPopup,
      MainFlyGreenPopup,
      OptInToFlyGreen,
      EmptyLayout,
      // FlyDatesPopup,
      CommonLayout,
      PaymentPopup,
      BookingLayout,
      DashboardLayout,
      BlogLayout,
      ComingSoonPopup,
      ReportLayout,
    },
  }
</script>
