<template>
  <modal name="ThanksPopup" transition="pop-out" @before-open="beforeOpen" :adaptive="true">
    <div class="thanksPopup" id="successMessage">
      <div class="thanksPopup__decor"></div>
      <div class="thanksPopup__inner">
        <div class="thanksPopup__title">{{message.title}}</div>
        <div class="thanksPopup__text">{{message.text}}</div>
        <div class="thanksPopup__button thanksPopup__button--close" @click="closeModal()">
          <span class="text">Ok</span>
        </div>
      </div>
      <button type="button" class="modal__close" @click="closeModal()">
        <SvgIcon name="close-white"/>
      </button>
    </div>
  </modal>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon';

  export default {
    name: 'ThanksPopup',
    data: () => ({
      message: {
        title: null,
        text: null,
      },
    }),
    methods: {
      beforeOpen (event) {
        this.message = event.params.message
      },
      closeModal () {
        if (this.message.redirectHome) {
          this.$modal.hide('ThanksPopup')
          this.$router.push('/map-search')
        } else {
          this.$modal.hide('ThanksPopup')
        }
      },
    },
    components: {
      SvgIcon,
    },
  };
</script>
