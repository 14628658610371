const state = () => ({
  status: false,
});

const mutations = {
  toggleMenu (state, status) {
    state.status = status
  },
};

export default {
  state,
  mutations,
};
