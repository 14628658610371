<template>
  <div :style="{width: '100%'}">
    <div class="aircraftDetail__slider js-aircraftSlider aircraftDetail__slider--nav" v-if="type === 'aircraft'" style="position: relative; width: 100%;">
      <div class="aircraftDetail__slider-main" style="width: 100%;">
        <div class="swiper-container swiper-top" ref="swiperTop" style="position: initial;">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) of aircraftImages" :key="index">
            <span
              class="aircraftDetail__slider-main-item"
              :style="{backgroundImage: `url(${item.url})`}"
              style="position: relative; display: flex; align-items: center; justify-content: center; background-repeat: no-repeat; background-position: center center; background-size: cover; width: 100%; height: 321px; cursor: pointer;"
              @click="openLightboxOnSlide(activeSlide)"
            >
              <div class="aircraftDetail__slider-main-item-back" style="will-change: opacity; opacity: 0; visibility: hidden; transition: 0.3s; position: absolute; z-index: 2; top: 0; left: 0; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: rgba(0,0,0,0.5);">
                <span class="aircraftDetail__slider-main-item-icon" style="will-change: transform; transition: transform 0.266666666666667s cubic-bezier(0.18, 0.89, 0.32, 1.28); display: flex; align-items: center; justify-content: center;">
                  <svg width="488.85" height="488.85" viewBox="0 0 488.85 488.85">
                    <defs><linearGradient id="eyeG" x1="244.425" y1="0" x2="244.425" y2="488.85"
                                          gradient-units="userSpaceOnUse"><stop
                      stop-color="#41FB96"/><stop offset="1" stop-color="#12A053"/></linearGradient></defs><path
                    d="M244.425,98.725c-93.4,0-178.1,51.1-240.6,134.1c-5.1,6.8-5.1,16.3,0,23.1c62.5,83.1,147.2,134.2,240.6,134.2s178.1-51.1,240.6-134.1c5.1-6.8,5.1-16.3,0-23.1C422.525,149.825,337.825,98.725,244.425,98.725z M251.125,347.025c-62,3.9-113.2-47.2-109.3-109.3c3.2-51.2,44.7-92.7,95.9-95.9c62-3.9,113.2,47.2,109.3,109.3C343.725,302.225,302.225,343.725,251.125,347.025z M248.025,299.625c-33.4,2.1-61-25.4-58.8-58.8c1.7-27.6,24.1-49.9,51.7-51.7c33.4-2.1,61,25.4,58.8,58.8C297.925,275.625,275.525,297.925,248.025,299.625z"
                    fill="url(#eyeG)"/>
                  </svg>
                </span>
              </div>
            </span>
            </div>
          </div>
          <div class="swiper-button-prev" v-if="!dMobile" style="left: 0; z-index: 2; right: auto; position: absolute; top: 50%; width: calc(var(--swiper-navigation-size) / 44 * 27); height: var(--swiper-navigation-size); margin-top: calc(0px - (var(--swiper-navigation-size) / 2)); cursor: pointer; display: flex; align-items: center; justify-content: center; color: var(--swiper-navigation-color, var(--swiper-theme-color)); font-size: 24px; color: #111115;"></div>
          <div class="swiper-button-next" v-if="!dMobile" style="right: 0; z-index: 2; left: auto; position: absolute; top: 50%; width: calc(var(--swiper-navigation-size) / 44 * 27); height: var(--swiper-navigation-size); margin-top: calc(0px - (var(--swiper-navigation-size) / 2)); cursor: pointer; display: flex; align-items: center; justify-content: center; color: var(--swiper-navigation-color, var(--swiper-theme-color)); font-size: 24px; color: #111115;"></div>
        </div>
      </div>

      <div class="aircraftDetail__slider-thumbs" style="width: 100%; margin-top: 5px;">
        <div class="swiper-container swiper-thumbs" ref="swiperThumbs">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) of aircraftImages" :key="index">
              <div class="aircraftDetail__slider-thumbs-item" :style="{backgroundImage: `url(${item.url})`}" style="width: 100%; height: 64px; opacity: 0.6; transition: 0.3s; cursor: pointer; overflow: hidden; background-repeat: no-repeat; background-position: center center; background-size: cover; pointer-events: none;">
                <div class="aircraftDetail__slider-main-item-back" style="will-change: opacity; opacity: 0; visibility: hidden; transition: 0.3s; position: absolute; z-index: 2; top: 0; left: 0; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: rgba(0,0,0,0.5);"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="confirmationBooking__aircraft-slider" v-if="type === 'confirmation'">
      <div class="confirmationBooking__slider-main">
        <div class="swiper-container swiper-top" ref="swiperTop">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) of aircraftImages" :key="index">
              <span
                class="aircraftDetail__slider-main-item"
                :style="{backgroundImage: `url(${item.url})`}"
                @click="openLightboxOnSlide(activeSlide)"
              >
                <div class="aircraftDetail__slider-main-item-back">
                  <span class="aircraftDetail__slider-main-item-icon">
                    <svg width="488.85" height="488.85" viewBox="0 0 488.85 488.85">
                      <defs><linearGradient id="eyeG" x1="244.425" y1="0" x2="244.425" y2="488.85"
                                            gradient-units="userSpaceOnUse"><stop
                        stop-color="#41FB96"/><stop offset="1" stop-color="#12A053"/></linearGradient></defs><path
                      d="M244.425,98.725c-93.4,0-178.1,51.1-240.6,134.1c-5.1,6.8-5.1,16.3,0,23.1c62.5,83.1,147.2,134.2,240.6,134.2s178.1-51.1,240.6-134.1c5.1-6.8,5.1-16.3,0-23.1C422.525,149.825,337.825,98.725,244.425,98.725z M251.125,347.025c-62,3.9-113.2-47.2-109.3-109.3c3.2-51.2,44.7-92.7,95.9-95.9c62-3.9,113.2,47.2,109.3,109.3C343.725,302.225,302.225,343.725,251.125,347.025z M248.025,299.625c-33.4,2.1-61-25.4-58.8-58.8c1.7-27.6,24.1-49.9,51.7-51.7c33.4-2.1,61,25.4,58.8,58.8C297.925,275.625,275.525,297.925,248.025,299.625z"
                      fill="url(#eyeG)"/>
                    </svg>
                  </span>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div class="swiper-nav">
          <div class="swiper-pagination"></div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
        <div class="swiper-text">Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque
          laudantium, totam
        </div>
      </div>

      <div class="confirmationBooking__slider-thumbs">
        <div class="swiper-container swiper-thumbs" ref="swiperThumbs">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) of aircraftImages" :key="index">
              <div class="aircraftDetail__slider-thumbs-item" :style="{backgroundImage: `url(${item.url})`}">
                <div class="aircraftDetail__slider-main-item-back"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FsLightbox
      :toggler="toggler"
      :slide="activeSlide"
      :sources="gallery"
      :onOpen="opened"
      :onClose="close"
      :type="'image'"
      :types="['image']"
    />
  </div>
</template>

<script>
  import Swiper, { Navigation, Pagination, Thumbs } from 'swiper'
  import FsLightbox from 'fslightbox-vue'

  Swiper.use([Navigation, Pagination, Thumbs])

  export default {
    name: 'AircraftDetailSliderPopup',
    props: ['type', 'aircraftImages'],
    data: () => ({
      activeSlide: 1,
      toggler: true,
      topSwiper: null,
      thumbSwiper: null,
      dMobile: undefined,
    }),
    computed: {
      gallery () {
        const gallery = this.aircraftImages.map((item) => {
          return item.url
        })
        return gallery
      },
    },
    methods: {
      openLightboxOnSlide () {
        this.toggler = !this.toggler
      },
      opened () {
        if (document.querySelector('html').classList.contains('d-mobile')) {
          const container = document.querySelector('.page')
          const lightbox = container.querySelector('.fslightbox-container')
          container.insertAdjacentElement('afterbegin', lightbox)
        }
      },
      close (fsLightbox) {
      },
    },
    mounted () {
      this.dMobile = document.querySelector('html').classList.contains('d-mobile');
      setTimeout(() => {
        const that = this

        this.thumbSwiper = new Swiper('.swiper-thumbs', {
          slidesPerView: 4,
          spaceBetween: 5,
          freeMode: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
        })

        this.topSwiper = new Swiper('.swiper-top', {
          slidesPerView: 1,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          },
          thumbs: {
            swiper: this.thumbSwiper,
          },
        })

        this.topSwiper.on('slideChange', () => {
          that.activeSlide = this.topSwiper.activeIndex + 1
        })
      }, 0)
    },
    destroyed () {
      this.topSwiper.destroy()
    },
    components: {
      FsLightbox,
    },
  }
</script>