import {locationsAPI} from '@/api/locations';
import {aircraftsAPI} from '@/api/aircrafts';

const state = () => ({
  list: [],
  searchResults: {
    aircraftType: [],
    aircraftModel: [],
    homeBase: [],
  },
  currentFleet: null,
  detailAircraft: {
    title: 'n123jfl',
    model: 'Gulfstream G650',
    tailNumber: 'RGH01',
    homeBase: 'New York, NY',
    argusSafety: 'Argus Safety Rating name',
    wyvernSafety: 'Wyvern Safety Rating name',
    year: '2018',
    interiorRefurbish: '2018',
    exteriorRefurbish: '2018',
    maxPass: '45',
    allowedMaxPass: '40',
    hours: '200',
    speed: '1032',
    cargoCapability: '98',
    wifi: 'yes',
    tvCount: '10',
    tv: 'yes',
    maxSeats: '670',
    minSeats: '120',
    price: 'all-price',
    allPrice: '100000',
    pricePerSeat: '',
    pricePerHour: '3250000',
    sell: ['charter aircraft'],
    gallery: [
      'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1602196321/FlyJets/plane_oadvwk.jpg',
      'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1600460712/FlyJets/5_fcv6rh.jpg',
      'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1600460711/FlyJets/6_wnzg9i.jpg',
      'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1600460710/FlyJets/3_ol9kmv.jpg',
      'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1600460710/FlyJets/2_o6yxqt.jpg',
      'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1600460709/FlyJets/1_cftcns.jpg',
      'https://res.cloudinary.com/dyyrf9gqp/image/upload/v1600460708/FlyJets/4_teg9lr.jpg',
    ],
    galleryDescr: [
      {
        count: 'Aircraft Photo One:',
        descr: 'Exterior - in air',
      }, {
        count: 'Aircraft Photo Two:',
        descr: 'Exterior - ground',
      }, {
        count: 'Aircraft Photo Three:',
        descr: 'Interior - Passenger Cabin - wide',
      }, {
        count: 'Aircraft Photo Four:',
        descr: 'Interior - Cockpit',
      }, {
        count: 'Aircraft Photo Five:',
        descr: 'Interior Floorplan',
      }, {
        count: 'Aircraft Photo Six(optional):',
        descr: 'Interior Three',
      }, {
        count: 'Aircraft Photo Seven(optional):',
        descr: 'Exterior Three',
      },
    ],
  },
})

const mutations = {
  getFleetList (state, data) {
    state.list = data.data
  },
  insertCurrentFleet (state, id) {
    state.currentFleet = id
  },
  removeItemFleet (state) {
    state.list = state.list.filter(item => item.id !== state.currentFleet)
  },
  setSearchResultsFleet (state, {result, data}) {
    if (data.type === 'aircraftType') {
      state.searchResults.aircraftType = result.data
    } else if (data.type === 'aircraftModel') {
      state.searchResults.aircraftModel = result.data
    } else {
      state.searchResults.homeBase = result.data
    }
  },
  deleteSearchResultsFleet (state, data) {
    if (data.type === 'aircraftType') {
      state.searchResults.aircraftType = []
    } else if (data.type === 'aircraftModel') {
      state.searchResults.aircraftModel = []
    } else {
      state.searchResults.homeBase = []
    }
  },
}

const getters = {
  shortListAircraftType: state => count => {
    return state.searchResults.aircraftType.filter((elem, id) => {
      if (id <= count) {
        return elem;
      }
    });
  },
  shortListAircraftModel: state => count => {
    return state.searchResults.aircraftModel.filter((elem, id) => {
      if (id <= count) {
        return elem;
      }
    });
  },
  shortListHomeBase: state => count => {
    return state.searchResults.homeBase.filter((elem, id) => {
      if (id <= count) {
        return elem;
      }
    });
  },
};

const actions = {
  async getAircrafts ({commit}, data) {
    try {
      const result = await aircraftsAPI.getAircrafts()
      commit('getFleetList', result)
    } catch (e) {
      console.log('aircrafts not found');
    }
  },
  async getCurrentAircraft ({commit}, data) {
    try {
      await aircraftsAPI.getCurrentAircraft(data)
    } catch (e) {
      console.log('aircraft not found');
    }
  },
  async searchResultsFleet ({commit, dispatch}, data) {
    try {
      let result
      if (data.type === 'aircraftType') {
        result = await aircraftsAPI.searchAircraftTypes(data)
      } else if (data.type === 'aircraftModel') {
        result = await aircraftsAPI.searchAircraftModels(data)
      } else {
        result = await locationsAPI.searchLocations(data);
      }
      commit('setSearchResultsFleet', {result, data})
    } catch (e) {
      commit('deleteSearchResultsFleet', data);
    }
  },
  async createAircraft ({commit, dispatch}, data) {
    try {
      await aircraftsAPI.createAircraft(data)
      return true
    } catch (e) {
      console.log('creating failed');
      return false
    }
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
}
