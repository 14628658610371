import {aircraftsAPI} from '@/api/aircrafts';

const state = () => ({
  list: [],
})

const mutations = {
  getProviderCalendarList (state, data) {
    state.list = data.data;
  },
}

const getters = {

};

const actions = {
  async getCurrentProviderCalendar ({commit}, data) {
    try {
      const result = await aircraftsAPI.getCurrentProviderCalendar()
      commit('getProviderCalendarList', result)
    } catch (e) {
      console.log('calendar not found');
    }
  },
  async addAircraftAvailability ({commit}, data) {
    try {
      await aircraftsAPI.addAircraftAvailability(data);
      return true;
    } catch (e) {
      console.log('cant add aircraftAvailability');
    }
  },
  async changeAvailabilityInSchedulerUi ({commit}, data) {
    try {
      const result = await aircraftsAPI.changeAvailabilityInSchedulerUi(data);
      return result.data;
    } catch (e) {
      console.log('cant change aircraftAvailability');
    }
  },
  async editEmptyLeg ({commit}, data) {
    try {
      const result = await aircraftsAPI.changeEmptyLegInSchedulerUi(data);
      return result.data;
    } catch (e) {
      console.log('cant edit empty Leg');
    }
  },
  async removeAircraftAvailability ({commit}, data) {
    try {
      await aircraftsAPI.removeAircraftAvailability(data);
      return true;
    } catch (e) {
      console.log('cant remove aircraftAvailability');
    }
  },
  async removeEmptyLegs ({commit}, data) {
    try {
      await aircraftsAPI.removeEmptyLegs(data);
      return true;
    } catch (e) {
      console.log('cant remove empty Legs');
    }
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
}
