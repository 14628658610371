const state = () => ({
  theme: localStorage.getItem('theme') || 'light',
})

const mutations = {
  changeTheme (state, theme) {
    state.theme = theme;
    localStorage.setItem('theme', theme);
    document.documentElement.setAttribute('data-theme', theme);
  },
}

const actions = {
  async setDarkMode ({ commit }, isEnabled) {
    commit('changeTheme', isEnabled ? 'dark' : 'light');
  },
}

export default {
  state,
  mutations,
  actions,
}
