import {subscriptionsPlansApi} from '@/api/lookups/subscriptionsPlans';

const state = () => ({
  subscriptionsPlans: null,
  enrichedSubscriptionsPlans: null,
})

const mutations = {
  setSubscriptionsPlans (state, subscriptionsPlans) {
    state.subscriptionsPlans = subscriptionsPlans
  },
  setEnrichedSubscriptionsPlans (state, enrichedSubscriptionsPlans) {
    state.enrichedSubscriptionsPlans = enrichedSubscriptionsPlans
  },
}

const actions = {
  async fetchSubscriptionsPlans ({state, commit}, forceFetch = false) {
    if (!state.subscriptionsPlans || forceFetch) {
      const result = await subscriptionsPlansApi.getSubscriptionsPlans()

      commit('setSubscriptionsPlans', result.data.plans)
    }
  },

  async fetchEnrichedSubscriptionsPlans ({state, commit, dispatch, rootGetters}, forceFetch = false) {
    if (!state.enrichedSubscriptionsPlans || forceFetch) {
      const result = await subscriptionsPlansApi.getSubscriptionsPlans()

      await Promise.all([
        dispatch('fetchCurrencies'),
        dispatch('fetchSubscriptionsTypes'),
        dispatch('fetchPeriods'),
      ])

      const plans = result.data.plans

      for (const plan of plans) {
        plan.type = rootGetters.getSubscriptionsTypeById(plan.typeId)
        plan.period = rootGetters.getPeriodById(plan.periodId)
        plan.currency = rootGetters.getCurrencyById(plan.currencyId)
      }

      commit('setEnrichedSubscriptionsPlans', plans)
    }
  },
}

const getters = {
  getSubscriptionsPlanById: (state) => (id) => {
    if (!state.subscriptionsPlans) return null
    return state.subscriptionsPlans.find(c => c.id === id)
  },
  getEnrichedSubscriptionsPlanById: (state) => (id) => {
    if (!state.enrichedSubscriptionsPlans) return null
    return state.enrichedSubscriptionsPlans.find(c => c.id === id)
  },
  getEnrichedSubscriptionsPlans (state) {
    if (!state.enrichedSubscriptionsPlans) return null
    return state.enrichedSubscriptionsPlans
  },
  getActiveEnrichedSubscriptionsPlans (state) {
    if (!state.enrichedSubscriptionsPlans) return null
    return state.enrichedSubscriptionsPlans.filter(c => c.isActive)
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
