import {instance} from '../index';

export const networkActivityAPI = {
    getAccountsPage (accountType, pageNumber, rowsCount) {
      return instance.get(`accounts/paginatedlist/${accountType}?page=${pageNumber}&size=${rowsCount}`);
    },
    createNewProvider (data) {
        return instance.post('accounts/create', {
            accountType: 2,
            firstName: data.firstName,
            middleName: data.middleName,
            lastName: data.lastName,
            companyName: data.company,
            mobile: data.phone,
            email: data.email,
            password: 'temppass223!@',
            timeZone: 0,

        })
    },
  }