import {bidsAPI} from '@/api/bids';

const state = () => ({
  pendingAcceptanceBidList: [],
  acceptedBidList: [],
})

const mutations = {
  setProviderBidList (state, {data, accepted}) {
    if (accepted) {
      state.acceptedBidList = data.item.value;
    } else {
      state.pendingAcceptanceBidList = data.item.value;
    }
  },
}
const actions = {
  async getProviderBidList ({commit}, data) {
    const {accepted} = data;

    try {
      const result = await bidsAPI.getProviderBidList(accepted);
      commit('setProviderBidList', {data: result.data, accepted});
    } catch (e) {
      console.log('e', e)
    }
  },
  async providerAcceptBid ({commit, state}, id) {
    try {
      await bidsAPI.providerAcceptBid(id);
    } catch (e) {
      console.log('e', e)
    }
  },
}
export default {
  state,
  mutations,
  actions,
}
