import {instance} from '../index';

export const flyerRequestsAPI = {
  getRequests () {
    return instance.get('requestEmpties/getFlyersRequestEmpties?historical=false')
  },
  deleteRequest (data) {
    return instance.get(`accounts/deleteRequest/${data}`)
  },
  submitRequest (data) {
    return instance.post('accounts/submitRequest', {
      departure: data.departure,
      arrival: data.arrival,
      departureDate: data.departureDate,
      arrivalDate: data.arrivalDate,
      options: data.options,
    })
  },
}
