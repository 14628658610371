import {bookingAPI} from '@/api/booking';
import { flyGreenAPI } from '@/api/flyer/flyGreen';

const state = () => ({
  bookingNo: null,
  aircraft: {
    aircraftId: null,
    argusSafetyRating: null,
    bookableDemo: null,
    cargoCapability: null,
    homebaseId: null,
    homeBase: null,
    hoursFlown: null,
    aircraftImages: [],
    lastExteriorRefurbish: null,
    lastInteriorRefurbish: null,
    yearOfManufacture: null,
    maxPassengers: null,
    modelId: null,
    aircraftModel: null,
    numberOfTelevision: null,
    pricePerHour: null,
    aircraftRange: null,
    aircraftSpeed: null,
    tailNumber: null,
    typeId: null,
    aircraftType: null,
    wifiOnboard: null,
    wyvernSafetyRating: null,
  },
  travelers: [],
  currentTraveler: null,
  terms: false,
  fullBookingItem: null,
  flyRewardsList: [],
  historicalRewardsList: [],
  flyerFlyRewardsPoint: 0,
})

const mutations = {
  setBookingNo (state, data) {
    state.bookingNo = data.bookingNo
  },
  setAircraft (state, data) {
    state.aircraft.aircraftId = data.aircraftId
    state.aircraft.argusSafetyRating = data.argusSafetyRating
    state.aircraft.bookableDemo = data.bookableDemo
    state.aircraft.isFloatingFleet = data.isFloatingFleet
    state.aircraft.cargoCapability = data.cargoCapability
    state.aircraft.homebaseId = data.homebaseId
    state.aircraft.homeBase = data.homebaseName
    state.aircraft.hoursFlown = data.hoursFlown
    state.aircraft.aircraftImages = data.images
    state.aircraft.lastExteriorRefurbish = data.lastExtRefurbish
    state.aircraft.lastInteriorRefurbish = data.lastIntRefurbish
    state.aircraft.yearOfManufacture = data.manufactureYear
    state.aircraft.maxPassengers = data.maxPassengers
    state.aircraft.modelId = data.modelId
    state.aircraft.aircraftModel = data.modelName
    state.aircraft.numberOfTelevision = data.numberOfTelevision
    state.aircraft.pricePerHour = data.pricePerHour
    state.aircraft.aircraftRange = data.range
    state.aircraft.aircraftSpeed = data.speed
    state.aircraft.tailNumber = data.tailNumber
    state.aircraft.typeId = data.typeId
    state.aircraft.aircraftType = data.typeName
    state.aircraft.wifiOnboard = data.wifi
    state.aircraft.wyvernSafetyRating = data.wyvernSafetyRating
  },
  setTravelers (state, data) {
    const arr = state.travelers.find(item => item.id === data.id)
    if (!arr) {
      state.travelers.push(data)
    }
  },
  currentTraveler (state, id) {
    state.currentTraveler = id
  },
  removeTraveler (state) {
    state.travelers = state.travelers.filter((item) => item.id !== state.currentTraveler)
  },
  clearTravelers (state) {
    state.travelers.length = 0
  },
  changeTerms (state, data) {
    state.terms = data
  },
  setFullBookingItem (state, data) {
    state.fullBookingItem = data
  },
  setFlyRewardsList (state, data) {
    state.flyRewardsList = data
  },
  setHistoricalRewardsList (state, data) {
    state.historicalRewardsList = data
  },
  setFlyerFlyRewardsList (state, data) {
    state.flyerFlyRewardsPoint = data
  },
}

const actions = {
  async createBooking ({commit}, data) {
    // console.log(data);
    commit('setFullBookingItem', data)

    const dataToSend = {
      direction: data.direction,
      bookingType: data.bookingType,
      departureId: data.departureId,
      arrivalId: data.arrivalId,
      departureDate: data.departureDate,
      returnDate: data.returnDate,
      pax: data.pax,
      aircraftAvailabilityId: data.aircraftAvailabilityId,
      paymentMethodId: null,
      bookingPax: data.pax,
      travelers: [],
    }

    try {
      const results = await bookingAPI.createBooking(dataToSend);
      commit('setBookingNo', results.data);
      const resultAircraft = await bookingAPI.getAircraft(data.aircraftId)
      commit('setAircraft', resultAircraft.data)
      return true
    } catch (e) {
      console.log('booking not created');
      return false
    }
  },
  async getMember ({state, commit}, data) {
    const results = await bookingAPI.getMember(data.memberEmail);
    function checkDoubleMember (data) {
      const arr = state.travelers.find(item => item.id === data.id)
      if (arr) {
        return 'Traveler Exists'
      }
      commit('setTravelers', data)
      return data
    }
    try {
      if (data.newMember) {
        const newMember = {
          dateOfBirth: null,
          email: data.memberEmail,
          firstName: data.firstName,
          id: data.id,
          lastName: data.lastName,
        }
        if (results.data !== null) {
          return checkDoubleMember(results.data)
        } else {
          const arr = state.travelers.find(item => item.email === data.memberEmail)
          if (arr) {
            return 'Traveler Exists'
          }
          commit('setTravelers', newMember)
          return newMember
        }
      } else {
        if (results.data !== null) {
          return checkDoubleMember(results.data)
        } else {
          return false
        }
      }
    } catch (e) {
      console.log('traveler not found');
    }
  },
  async getFlyerFlyRewards ({commit}) {
    const results = await bookingAPI.getFlyerFlyRewards();
    try {
      if (results.data !== null) {
        commit('setFlyerFlyRewardsList', results.data)
        return true
      } else {
        return false
      }
    } catch (e) {
      console.log('FlyRewards points not found');
    }
  },
  async getHistoricalFlyRewards ({commit}) {
    const results = await bookingAPI.getHistoricalFlyRewards();
    try {
      if (results.data !== null) {
        commit('setHistoricalRewardsList', results.data.requests)
        return true
      } else {
        return false
      }
    } catch (e) {
      console.log('FlyRewards points not found');
    }
  },
  async getFlyRewards ({commit}, id) {
    const results = await bookingAPI.getBookingForAddingFlyRewardsReady(id);
    try {
      if (results.data !== null) {
        commit('setFlyRewardsList', results.data.requests)
        return true
      } else {
        return false
      }
    } catch (e) {
      console.log('FlyRewards not found');
    }
  },
  async updateFlyRewards ({commit}, data) {
    const results = await bookingAPI.updateBookingForAddingFlyRewardsReady(data);
    try {
      if (results.data !== null) {
        // commit('setFlyRewardsList', results.data.requests)
        return true
      } else {
        return false
      }
    } catch (e) {
      console.log('FlyRewards not found');
    }
  },
  async confirmBooking ({state}, flyGreenData) {
    try {
      const data = {
        bookingNo: state.bookingNo,
        travelers: state.travelers,
      }
      const results = await bookingAPI.confirmBooking(data);
      if (results.data && flyGreenData.offsetPrice > 0) {
        flyGreenAPI.submitFlyGreen(state.bookingNo, flyGreenData.offsetPercentage, flyGreenData.offsetPrice);
        return true;
      }
    } catch (e) {
      console.log('error');
    }
  },
  async getBookingInfoByNumber ({commit}, bookingNumber) {
    const results = await bookingAPI.getBookingInfoByNumber(bookingNumber);
    try {
      if (results.data.isSuccessfull) {
        commit('setBookingNo', {bookingNo: bookingNumber})
        commit('setFullBookingItem', results.data.item.value)
        const resultAircraft = await bookingAPI.getAircraft(results.data.item.value.aircraftId)
        commit('setAircraft', resultAircraft.data)
      } else {
        console.log(results.data.message)
      }
    } catch (e) {
      console.log('FlyRewards points not found');
    }
  },
  async addNewBid ({commit}, data) {
    try {
      const res = await bookingAPI.addNewBid(data);
      return res;
    } catch (e) {
      console.log('BID not added');
      return false
    }
  },
  async updateBid ({commit}, data) {
    try {
      const res = await bookingAPI.updateBid(data);
      return res;
    } catch (e) {
      console.log('BID not updated');
      return false
    }
  },
  async getBidValues ({commit}, data) {
    try {
      const res = await bookingAPI.getBidValues(data);
      return res?.data?.item?.value ?? [];
    } catch (e) {
      console.log('Failed to receive bid values');
      return false
    }
  },
  async confirmRequestEmpty ({commit}, data) {
    try {
      return await bookingAPI.confirmRequestEmpty(data);
    } catch (e) {
      console.log('BID not added');
      return false
    }
  },
}

export default {
  state,
  mutations,
  actions,
};
