import { instance } from './index';

export const bookingAPI = {
  createBooking (data) {
    return instance.post('bookings/create', {
      direction: data.direction,
      bookingType: data.bookingType,
      departureId: data.departureId,
      arrivalId: data.arrivalId,
      departureDate: data.departureDate,
      returnDate: data.returnDate,
      pax: data.pax,
      aircraftAvailabilityId: data.aircraftAvailabilityId,
      paymentMethodId: data.paymentMethodId,
      bookingPax: data.bookingPax,
      travelers: data.travelers,
    });
  },
  addNewBid (data) {
    return instance.post('auction/bids', data);
  },
  updateBid (data) {
    return instance.patch('auction/bids', data);
  },
  getBidValues (flightIds = []) {
    return instance.post('auction/bids/getBidValues', flightIds);
  },
  getAircraft (id) {
    return instance.get(`aircrafts/${id}`);
  },
  getBookingForAddingFlyRewardsReady (id) {
    return instance.get(`bookings/getBookingForAddingFlyRewards/${id}`);
  },
  updateBookingForAddingFlyRewardsReady (data) {
    return instance.post(`bookings/updateFlyRewardSatatus/${data.id}/${data.flyRewards}`);
  },
  getFlyerFlyRewards () {
    return instance.get('bookings/getFlyerFlyRewards');
  },
  getHistoricalFlyRewards () {
    return instance.get('bookings/getHistoricalFlightsFlyRewardTab');
  },
  getMember (email) {
    return instance.get(`bookings/travelers/getmember/${email}`);
  },
  getBookingConfirmationInfoByNumber (bookingNumber) {
    return instance.get(`bookings/getBookingConfirmationInfoByNumber/${bookingNumber}`)
  },
  confirmBooking (data) {
    return instance.patch('bookings/edit', {
      bookingNo: data.bookingNo,
      travelers: data.travelers,
    });
  },
  getManualRequest (flightRequestId) {
    return instance.get(`bookings/flightsrequests/${flightRequestId}`);
  },
  getManualRequests () {
    return instance.get('bookings/flightsrequests/list/true');
  },
  getManualRequestsPage (getCurrent, pageNumber, rowsCount) {
    return instance.get(`bookings/flightsrequests/paginatedlist/${getCurrent}?page=${pageNumber}&size=${rowsCount}`);
  },
  createManualRequests () {
    return instance.post('bookings/flightsrequests/create');
  },
  getHistoricalManualRequests () {
    return instance.get('bookings/flightsrequests/list/false');
  },
  getOffsetPurchases () {
    return instance.get('bookings/offsets/show');
  },
  addRouteAlert (data) {
    return instance.post('bookings/routealerts/create', {
      departureId: data.departureId,
      arrivalId: data.arrivalId,
      maxPrice: data.maxPrice,
      listUntil: data.listUntil,
      bookingType: data.bookingType,
      specificDates: data.specificDates,
      dates: data.dates,
      direction: data.direction,
      departureDays: data.departureDays,
      returnsDays: data.returnsDays,
    });
  },
  getRouteAlerts () {
    return instance.get('bookings/routealerts/list');
  },
  getRouteAlertsAdmin () {
    return instance.get('bookings/routealerts/admin');
  },
  deleteRouteAlert (data) {
    return instance.post('bookings/routealerts/delete', {
      routeAlertId: data,
    });
  },
  toggleRouteAlert (data) {
    return instance.post('bookings/routealerts/toggle', {
      routeAlertId: data,
    });
  },
  getReservations () {
    return instance.get('bookings/list/4/32');
  },
  getConfirmedReservations () {
    return instance.get('bookings/list/confirmed/4/32');
  },
  getBookings () {
    return instance.get('bookings/list/2/16');
  },
  getConfirmedBookings () {
    return instance.get('bookings/list/confirmed/2/16');
  },
  getBookingDetails (id) {
    return instance.get('bookings/final-booking-info/' + id);
  },
  getFlightsFeaturedBookingInfo (emptyLegId) {
    return instance.get(`bookings/flights/featured/bookinginfo/${emptyLegId}`);
  },
  getFlightsFeatured () {
    return instance.get('bookings/flights/featured');
  },
  flycalCreate (data) {
    return instance.post('bookings/flycal/create', data);
  },
  getBookingPaymentInfo () {
    return instance.get('bookings/payments/booking');
  },
  getManualPaymentInfo () {
    return instance.get('bookings/payments/manual');
  },
  getFlycalPaymentInfo () {
    return instance.get('bookings/payments/flycal');
  },
  getBookingInfoByNumber (bookingNumber) {
    return instance.get(`bookings/getBookingInfoByNumber/${bookingNumber}`);
  },
  confirmRequestEmpty (data) {
    return instance.post('/requestEmpties/create', data);
  },
  setBookingPaid (bookingNumber) {
    return instance.patch(`bookings/setBookingPaid?bookingNumber=${bookingNumber}`)
  },
};
