<template>
  <modal name="LogInPopup" transition="pop-out" @before-open="beforeOpen" :adaptive="true" @closed="$v.$reset()">
    <div class="formPopup" :class="{'removeBackgroundImg': removeBackground }" id="loginPopup">
      <div class="formPopup__inner">
        <div class="formPopup__left">
          <div class="formPopup__decor"></div>
        </div>
        <div class="formPopup__right">
          <div class="formPopup__subtitle">lets go!</div>
          <div class="formPopup__title">Log in</div>
          <div class="formPopup__form">
            <form
              class="form form--login js-ajaxForm"
              :class="{'loading' : loading}"
              data-href="/json/test.json"
              data-action="login"
              id="loginForm"
              @submit.prevent="submitHandler"
            >
              <Spinner/>
              <label class="form__label form__label--text">
                <div class="form__caption">Email</div>
                <div class="form__field-wrapper">
                  <input
                    class="form__field"
                    type="email"
                    v-model.trim="email"
                    placeholder="Email address"
                    :disabled="loading"
                  />
                </div>
                <span
                  v-if="$v.email.$dirty && !$v.email.required"
                  class="form__field-invalid form__field-invalid--right-side"
                >
                  This field is required
                </span>
                <span
                  v-if="$v.email.$dirty && !$v.email.email"
                  class="form__field-invalid form__field-invalid--right-side"
                >
                  Enter a valid Email
                </span>
              </label>
              <label
                class="form__label form__label--text">
                <div class="form__caption-wrapper">
                  <div class="form__caption">Password</div>
                  <a class="form__link form__link--desktop js-popup" @click="changeToForgot">Forgot?</a>
                </div>
                <span class="form__field-wrapper">
                  <input
                    class="form__field"
                    type="password"
                    v-model.trim="password"
                    placeholder="Enter your password"
                    :disabled="loading"
                  />
                </span>
                <span
                  v-if="$v.password.$dirty && !$v.password.required"
                  class="form__field-invalid form__field-invalid--right-side"
                >
                  This field is required
                </span>
                <a class="form__link form__link--mobile js-popup" @click="changeToForgot">Forgot?</a>
              </label>
              <div
                class="form__label form__label--button" style="margin-bottom: 35px">
                <button class="form__button" :disabled="loading"><span class="text">Log in</span></button>
                <div class="form__link js-popup" @click="changeToSign">Sign up</div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <button type="button" class="modal__close" @click="$modal.hide('LogInPopup')">
        <SvgIcon name="close-white"/>
      </button>
    </div>
  </modal>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Spinner from '@/components/common/Spinner/Spinner'
  import { email, required } from 'vuelidate/lib/validators'

  export default {
    name: 'LogIn',
    data: () => ({
      email: '',
      password: '',
      loading: false,
      removeBackground: false,
    }),
    validations: {
      email: {
        email,
        required,
      },
      password: { required },
    },
    created () {
      this.email = ''
      this.password = ''
    },
    methods: {
      beforeOpen () {
        window.location.href.includes('/theflyreport') || window.location.href.includes('/article') ? this.removeBackground = true : this.removeBackground = false
      },
      async submitHandler () {
        if (this.$v.$invalid) {
          this.$v.$touch()
          return false
        }

        const data = {
          email: this.email,
          password: this.password,
        }

        this.loading = true

        const result = await this.$store.dispatch('loginUser', data)
        if (result) {
          this.$modal.hide('LogInPopup')
          this.email = ''
          this.password = ''
          this.loading = false

          // animations
          const mainBlock = document.querySelector('.mainBlock')
          const logo = document.querySelector('.js-mainLogo')
          const helloUser = document.querySelector('.js-helloUser')
          const buttons = document.querySelector('.mainBlock__button-wr')

          if (mainBlock && logo && helloUser && buttons) {
            helloUser.classList.add('helloUser--show')
            setTimeout(() => {
              mainBlock.classList.add('mainBlock--hide')
            }, 2500)
            setTimeout(() => {
              helloUser.classList.remove('helloUser--show')

              const userType = this.$store.state.auth.user.type

              switch (userType) {
                case 1:
                  this.$router.push('/map-search')
                  break
                case 2:
                  this.$router.push('/provider-dashboard')
                  break
                case 4:
                  this.$router.push('/admin')
                  break
                default:
                  return false
              }
            }, 3200)
          }
        } else {
          this.loading = false
        }
      },
      changeToSign () {
        this.$modal.hide('LogInPopup')
        this.$modal.show('SignUpPopup')
        this.email = ''
        this.password = ''
      },
      changeToForgot () {
        this.$modal.hide('LogInPopup')
        this.$modal.show('ForgotPasswordPopup')
        this.email = ''
        this.password = ''
      },
    },
    components: {
      Spinner,
      SvgIcon,
    },
  }
</script>
