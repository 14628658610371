import { networkActivityAPI } from '@/api/admin/network';

const state = () => ({
  flyersList: [],
  flyersListPageCount: 0,
  flyersListCount: 0,
  providersList: [],
  providersListPageCount: 0,
  providersListCount: 0,
})

const mutations = {
  setProvidersList (state, data) {
    state.providersList = data.accountsView
    state.providersListPageCount = data.pageCount;
    state.providersListCount = data.accountsCount;
  },
  setFlyersList (state, data) {
    state.flyersList = data.accountsView;
    state.flyersListPageCount = data.pageCount;
    state.flyersListCount = data.accountsCount;
  },
}

const actions = {
  async getFlyersList ({commit}, data) {
    try {
      const result = await networkActivityAPI.getAccountsPage('1', data.pageNumber, data.pageSize);
      commit('setFlyersList', result.data);
    } catch (e) {
      console.log('e', e)
    }
  },
  async getProvidersList ({commit}, data) {
    try {
      const result = await networkActivityAPI.getAccountsPage('2', data.pageNumber, data.pageSize);
      commit('setProvidersList', result.data);
    } catch (e) {
      console.log('e', e)
    }
  },
  async createNewProvider (data) {
    try {
      return networkActivityAPI.createNewProvider(data)
    } catch (e) {
      console.log('e', e)
    }
  },
}

export default {
  state,
  mutations,
  actions,
}