import {subscriptionsStatusesApi} from '@/api/lookups/subscriptionsStatuses';

const state = () => ({
  subscriptionsStatuses: null,
  lastFetch: new Date().getTime(),
})

const mutations = {
  setSubscriptionsStatuses (state, subscriptionsStatuses) {
    state.subscriptionsStatuses = subscriptionsStatuses
  },
  setLastFetch (lastFetch) {
    state.lastFetch = lastFetch
  },
}

const actions = {
  async fetchSubscriptionsStatuses ({state, commit}, forceFetch = false) {
    const now = new Date().getTime();
    const refreshInterval = process.env.VUE_APP_TIME_TO_UPDATE_REFERENCES * 60 * 60 * 1000
    const needUpdateAfterPeriod = state.lastFetch && (now - state.lastFetch > refreshInterval)

    if (!state.subscriptionsStatuses || forceFetch || needUpdateAfterPeriod) {
      const result = await subscriptionsStatusesApi.getSubscriptionsStatuses()

      commit('setSubscriptionsStatuses', result.data.statuses)
      commit('setLastFetch', now)
    }
  },
}

const getters = {
  getSubscriptionsStatusById: (state) => (id) => {
    if (!state.subscriptionsStatuses) return null
    return state.subscriptionsStatuses.filter(c => c.id === id)[0]
  },
  getSubscriptionsStatusByCode: (state) => (code) => {
    if (!state.subscriptionsStatuses) return null
    return state.subscriptionsStatuses.filter(c => c.code === code)[0]
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
