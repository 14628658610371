import * as axios from 'axios';
import qs from 'qs'

const instance = axios.create({
  crossDomain: true,
  baseURL: process.env.VUE_APP_BLOG_URI + '/api',
  withCredentials: true,
  contentType: 'application/json',
});

export const adminBlogsAPI = {
  getArticles ({ publicationState = 'preview', filters, populate = ['author', 'cover', 'categories', 'subcategories'], sort = ['createdAt:desc'], pagination } = {}, token) {
    const params = qs.stringify({
      populate,
      publicationState,
      filters,
      sort,
      pagination,
    }, {
      encodeValuesOnly: true,
    })

    return instance.get(`articles?${params}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  deleteArticle (id, token) {
    return instance.delete(`articles/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  createArticle (token) {
    return instance.post('articles', { data: { title: 'Title of the article', content: '', publishedAt: null } }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getSubcategories (token) {
    return instance.get('subcategories', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  },
  getCategories (token) {
    return instance.get('categories', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  },
  getArticle (id, token) {
    const params = qs.stringify({
      populate: ['author', 'cover', 'categories', 'subcategories'],
      publicationState: 'preview',
    }, {
      encodeValuesOnly: true,
    })

    return instance.get(`articles/${id}?${params}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getFeed () {
    return instance.get('posts?sort=publishedAt:desc');
  },
  updateArticle (id, data, token) {
    return instance.put(`articles/${id}`, { data }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  },
  uploadFile (file, token) {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('fileInfo', JSON.stringify({name: file.name}))

    return instance.post(`${process.env.VUE_APP_BLOG_URI}/api/upload`, formData, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  },
};
