export class Animations {
  slideToggle (item, customHeight) {
    if (!item.style.height || item.style.height === '0px') {
      this.slideDown(item, customHeight)
      return true
    } else {
      this.slideUp(item)
      return false
    }
  }

  slideUp (item) {
    setTimeout(() => {
      item.style.height = '0px'
      item.addEventListener('transitionend', () => {
        if (item.closest('.js-accordion-item')) {
          item.closest('.js-accordion-item').classList.remove('open')
        }
      }, { once: true })
    }, 0)
  }

  slideDown (item, customHeight) {
    if (!item.classList.contains('active')) {
      item.style.display = 'block'
      item.style.height = 'auto'
      const height = item.clientHeight + 'px'
      item.style.height = '0px'
      setTimeout(() => {
        if (customHeight) {
          item.style.height = `${customHeight}px`
        } else {
          item.style.height = height
        }
        if (item.closest('.js-accordion-item')) {
          item.closest('.js-accordion-item').classList.add('open')
        }
      }, 0)
    }
  }

  fadeToggle (item) {
    item.style.transition = '0.4s'

    if (item.offsetHeight <= 0) {
      item.style.display = 'block'
      setTimeout(() => {
        item.style.visibility = 'visible'
        item.style.opacity = 1
      }, 400)
      return true
    } else {
      item.style.visibility = 'hidden'
      item.style.opacity = 0
      setTimeout(() => {
        item.style.display = 'none'
      }, 400)
      return false
    }
  }
}
