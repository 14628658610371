import {currenciesApi} from '@/api/lookups/currencies';

const state = () => ({
  currencies: null,
  lastFetch: new Date().getTime(),
})

const mutations = {
  setCurrencies (state, currencies) {
    state.currencies = currencies
  },
  setLastFetch (lastFetch) {
    state.lastFetch = lastFetch
  },
}

const actions = {
  async fetchCurrencies ({state, commit}, forceFetch = false) {
    const now = new Date().getTime();
    const refreshInterval = process.env.VUE_APP_TIME_TO_UPDATE_REFERENCES * 60 * 60 * 1000;
    const needUpdateAfterPeriod = state.lastFetch && (now - state.lastFetch > refreshInterval)

    if (!state.currencies || forceFetch || needUpdateAfterPeriod) {
      const result = await currenciesApi.getCurrencies()

      commit('setCurrencies', result.data.currencies)
      commit('setLastFetch', now)
    }
  },
}

const getters = {
  getCurrencyById: (state) => (id) => {
    if (!state.currencies) return null
    return state.currencies.filter(c => c.id === id)[0]
  },
  getCurrencies (state) {
    return state.currencies
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
